import styled from "styled-components";
import { Button } from "@gamesheet/ui";

export const StyledFloatingMenu = styled.div`
display:flex;
flex-direction:column;
row-gap: 5px;
align-items: flex-end;
z-index: 1000;

.button {
    display: ilnine-block;
}
`

export const RosterButton = styled(Button)`
`

export const TeamDisplay = styled.div`
    background: #f0f0f0;
    width: 100%;
    height: 90px;
    border-radius: 6px;
    margin-bottom: 15px;
    margin-top: 10px;
    text-align: left;
    z-index: 5200;
    display: flex important!;
    flex-direction: column important!;
    justify-content: center important!;
    align-items: center important!;
`

export const PopupBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5100;
`;

export const PopupBox = styled.div`
  background: #f0f0f0;
  position: fixed;
  width: 500px;
  left: 50%;
  transform: translate(-50%);
  max-width: 92%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  text-align: center;
  z-index: 5200;
  display: flex important!;
  flex-direction: column important!;
  justify-content: center important!;
  align-items: center important!;
`;

// export const PopupBox = styled.div`
//   background: #f0f0f0;
//   position: fixed;
//   width: 500px;
//   max-width: 96%;
//   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
//   border-radius: 6px;
//   text-align: center;
//   z-index: 5200;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `;


export const TeamCard = styled.div`
  background: #fff;
  width: 96%;
  height: 88px;
  cursor: pointer;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 10px;
  margin-left: 2%;
  text-align: left;
  z-index: 5200;
  display: flex important!;
  flex-direction: column important!;
  justify-content: center important!;
  align-items: center important!;
`;

export const LockedTeamCard = styled.div`
  background: #fff;
  width: 96%;
  height: 88px;
  cursor: pointer;
  font-style: italic;
  font-size: 16px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 2%;
  text-align: left;
  z-index: 5200;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
`;

export const CircleButton = styled.button`
  width: 25px;
  height: 25px;
  cursor: pointer;
  color: #FEC307;
  background-color: rgba(0, 0, 0, 0);
  font-family: "Courier New", monospace;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: right;
  cursor: pointer;
  font-weight: bold;
  font-size: 25px;
`

export const Overlay = styled.div<{
    $open?: boolean;
}>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
    z-index: -1;
    display: ${p => (p.$open ? "block" : "none")};
    pointer-events: ${p => (p.$open ? "auto" : "none")};
`

export const StyledBootstrapSelect = styled.select.attrs({
    className: 'form-select form-select-sm'
})`
position: relative;
border: 1px solid ${({ theme }) => theme.secondaryColor};
`;
;