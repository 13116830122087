import { atom } from "recoil";
import { InviteUserFlowStep as Step, InviteUserFlowType } from "@/flows/InviteUserFlow/types";

export const InviteUserFlowState = atom<InviteUserFlowType>({
    key: 'InviteUserFlowState',
    default: {
        loading: false,
        isOpen: false,
        error: "",
        userEmail: "",
        type: undefined,
        step: Step.SelectRole,
        paths: {
            inviteUser: [
                Step.SelectRole,
                Step.InviteFollower,
                Step.InviteStaff,
            ]
        }
    }
})