import styled from "styled-components";

import { useAppState } from "@/state/app/useAppState";
import { Outlet } from "react-router-dom";
import { UserBar } from "./UserBar";
import { MobileTabs } from "./MobileTabs";
import React from "react";
import { PageLoading } from "@/components/pure/PageLoading";
import { UiTrays } from "./Trays";
import { UpdateAvailableButton } from '@/components/utils/updateAvailable';
import { ErrorBoundary } from "react-error-boundary";
import { ErrorMessage } from "@/components/pure/ErrorMessage";

const StyledMobileLayout = styled.div.attrs({
    className: "mobile-layout-grid"
})<MainLayoutProps>`
min-height: calc(100vh - 188px) !important;
background-color: #fafafa;
width: 100%;
max-width: 1280px;
margin: 0 auto;
margin-top: ${(props)=>!!props.$isActionBarOpen?'100px':'60px'};
padding-bottom: ${(props)=>!!props.$onGamePage?'0px':'173px'};
z-index: 300;

.title-divider {
    font-family: "Rubik";
}
`

const StyledDesktopLayout = styled.div.attrs({
    className: "desktop-layout-grid"
})<MainLayoutProps>`
min-height: calc(100vh - 105px) !important;
background-color: #fafafa;
width: 100%;
max-width: 1280px;
margin: 0 auto;
margin-top: 105px;
padding-bottom: 45px;

.title-divider {
    font-family: "Rubik";
}

.main-layout-grid {
    padding-top: 60px;
}
`

const StyledMainLayoutGrid = styled.div.attrs({
    className: "main-layout-grid"
})<{ $dim?: boolean }>`
opacity: ${(props)=>props.$dim?'0.3':'1'};
transition: opacity 1s ease 0s;
display: grid;
grid-auto-columns: auto;
grid-auto-rows: auto;
gap: 15px 15px;
padding: 15px;
z-index: 10;
`

type MainLayoutProps = {
    $isActionBarOpen?: boolean,
    $onGamePage?: boolean
}

export function MainLayout(props:MainLayoutProps){

    const app = useAppState()
    const StyledMainLayout = app.device.isMobile ? StyledMobileLayout : StyledDesktopLayout

    const layoutProps = {
        ...props,
        $onGamePage: window.location.pathname === '/games' || window.location.pathname === '/chat',
    }

    return <ErrorBoundary fallback={<ErrorMessage message="There was an error."/>}>
        
        <UserBar />
        { app.layout.showMobile && <MobileTabs />}

        <UiTrays />

        <StyledMainLayout {...layoutProps}>
            <StyledMainLayoutGrid $dim={app.menu.isOpen}>
                <React.Suspense fallback={<PageLoading />}>
                    <Outlet />
                </React.Suspense>
                <UpdateAvailableButton show={app.updateAvailable}/>
            </StyledMainLayoutGrid>
        </StyledMainLayout>
        
    </ErrorBoundary>
}