import { useRecoilState, useResetRecoilState } from "recoil";
import { InviteUserFlowState } from "./atom.inviteUserFlowState";
import { InviteUserFlowPath } from "../types";
import { useAppState } from "../../../state/app/useAppState";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router";
import { useManagedFlow } from "@/hooks/useManagedFlow";
import { useUserState } from "@/state/user/useUserState";

export type LineupFlowHook = {
  path: InviteUserFlowPath;
  reset: () => void;
};

export function useInviteUserFlow(path: InviteUserFlowPath) {
  
  const app = useAppState();
  const user = useUserState();
  const navigate = useNavigate();
  const reset = useResetRecoilState(InviteUserFlowState);
  const { next, prev, to, state, setState } = useManagedFlow(InviteUserFlowState, path)

  return {

    ...state,

    get isLoading() { return state.loading },

    setPath(path: InviteUserFlowPath) {
      setState(state => ({ ...state, path: path }))
    },

    controls: {
        next,
        prev: useCallback(() => {
            setState(state => ({ ...state, error: "" }))
            prev()
        }, [prev, setState]),
        to
    },

    reset,

  }

}
