import { useEffect } from "react"
import { useAppState } from "@/state/app/useAppState"
import { Logo } from "@gamesheet/ui"

import { Outlet } from "react-router-dom";
import styled from "styled-components";

import { IpadApiKeys } from "@/windows/ipadApiKeys";
import { EditPrototeam } from "@/windows/editPrototeam";
import { EditEmailNotifications } from "@/windows/editEmailNotifications";
import { InviteUser } from "@/windows/inviteUser";
import { RepeatingEventModal } from "@/windows/repeatingEvent";
import { DeleteRepeatingEventModal } from "@/windows/deleteRepeatingEvent";
import ChevronDownIcon from '@mui/icons-material/ExpandMore';
import { MoreMenu } from "@/apps/teams/Menu";
import { LineupSuccess } from "@/windows/lineups/lineupSuccess";
import { useUserState } from "@/state/user/useUserState";
import { useReadChannelsState } from "@/state/messaging/useChannelsState";
import { InviteUserFlow } from "@/flows/InviteUserFlow/index";
import { LeavePrototeam } from "@/windows/leavePrototeam";
import { RemoveFromTeam } from "@/windows/removeFromTeam";

const DesktopIconBar = styled.div`
height: 32px;
background-color: ${props => props.theme.primaryColor};
padding: 4px;
position: fixed;
left: 0;
top: 0;
right: 0;
z-index: 400;

div {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    button {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: ${props => props.theme.textOnPrimaryColor};
        background-color: transparent;
        border: none;
    }
}
`

const StyledLogo = styled(Logo).attrs<{
    className?: string
}>({
    className: "logo"
})`
height: 24px;
margin: 5px;
padding: 5px;
`

export function HUDLayout() {

    const app = useAppState()
    const user = useUserState()
    const events = app.events

    const { lastUpdated: channelsUpdated } = useReadChannelsState();

    useEffect(() => {
        app.menu.close()
    }, [])

    useEffect(() => {
        if (!user.tokens.access) return;
        user.GetExtraDetails();
    }, [user.tokens.access])

    const activeMember = user.ActiveMember();
    useEffect(() => {
        if(activeMember && app.selectedTeam){
            app.chat.setUnreadMessages(activeMember.unreadByTeam(app.selectedTeam))
        }
    }, [ activeMember?.id, app.selectedTeam, channelsUpdated ])

    return <>

        { (app.layout.showTablet || app.layout.showDesktop) && <>
            <DesktopIconBar>
                <div>
                    <StyledLogo color="black" contrast="lightbg" logo="horizontal" size="h6" />
                    <button onClick={events.handler('toggle-more-menu')}>{user.extraDetails.firstName} {user.extraDetails.lastName}<ChevronDownIcon /></button>
                </div>
            </DesktopIconBar>
        </>}

        <IpadApiKeys />
        
        <EditPrototeam />

        <EditEmailNotifications/>
        <InviteUserFlow />
        <RepeatingEventModal />
        <DeleteRepeatingEventModal />
        <LineupSuccess />
        <LeavePrototeam />
        <RemoveFromTeam />
        
        <Outlet key={app.lastChange} />

        <MoreMenu />

    </>
}