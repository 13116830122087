import { FormWrapper } from "@gamesheet/ui";
import { TextInput } from "./components/TextInput";
import { FormEvent, FormEventHandler, forwardRef, useCallback, useEffect, MouseEvent } from "react";
import { FormFieldType, NewFormField, useCreateForm } from "@/forms/hooks/useCreateForm";
import { styled } from "styled-components";
import { useUserState } from "@/state/user/useUserState";
import { PageLoading } from "@/components/pure/PageLoading";

const StyledEditPasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

type InviteUserFormProps = {
  onSubmit: (inviteUserForm: InviteUserFormState) => void;
  setIsValid: (isValid: boolean) => void;
  setFormData: (formData: InviteUserFormState) => void;
  email?: string;
};

export type InviteUserFormState = {
  emails: FormFieldType<string>;
};

export const InviteUserForm = forwardRef(({ onSubmit, setIsValid, setFormData, email }: InviteUserFormProps, ref) => {
  const user = useUserState();

  const { state: formState, onChange } = useCreateForm("inviteUser", {
    emails: NewFormField(email ? email : "", /^\s*([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}\s*(,\s*[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}\s*)*)?$/, "Invalid email(s) entered"),
  });

  useEffect(() => {
    
    if(!formState){
      return;
    }
    
    setIsValid(formState.emails?.valid);
    if (formState.emails?.value === "") {
      setIsValid(false);
    }
    setFormData(formState);

  }, [ JSON.stringify(formState) ]);

  const handleSubmit = useCallback(
    (e: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>) => {
      e && e.preventDefault();

      for (const field of Object.values(formState)) {
        if (!field.value.match(field.test)) {
          return;
        }
      }

      onSubmit(formState);
    },
    [onSubmit, formState]
  );

  if(!formState){
    return <PageLoading />
  }

  return (
    <FormWrapper style={{ marginTop: "15px" }}>
      <StyledEditPasswordForm onSubmit={handleSubmit} style={{ marginTop: "15px" }}>
        <TextInput state={formState.emails} name="emails" label="Email" onChange={onChange} autocomplete={false} />
      </StyledEditPasswordForm>
    </FormWrapper>
  );
});
