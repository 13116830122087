import { useAppState } from "@/state/app/useAppState";
import { useUserState } from "@/state/user/useUserState";
import { useCallback, useState } from "react";
import { firestore } from "@/libs/firebase";
import { getFirestore, collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { RosterPlayer } from "@/state/data/useTeamRoster/types";
import { Prototeam, SeasonTeam } from "../types";

type InvitationCodeServiceState = {
    isFetching: boolean,
    hasServerError: boolean,
    serverError: string,
    hasValidationError: boolean,
    validationError: string,
    onChange: number
}

export function useInvitationCodeService() {

    // import app and user states
    const app = useAppState();
    const user = useUserState();

    // initial state
    const initialState = {
        isFetching: false,
        hasServerError: false,
        serverError: "",
        hasValidationError: false,
        validationError: "",
        onChange: 0
    }

    // import service state
    const [service, _setServiceState] = useState({ ...initialState });
    const setServiceState = (state: InvitationCodeServiceState) => _setServiceState({ ...state, onChange: Date.now() })

    const getTeam = async (teamId: string): Promise<Prototeam> => {
        const firestore = getFirestore();
        let players: RosterPlayer[] = [];
        let seasonTeams: SeasonTeam[] = [];
        let team: Prototeam = {} as Prototeam;
    
        try {
            // Create the references
            const playersRef = collection(firestore, "teams", teamId, "players");
            const seasonTeamsRef = collection(firestore, "teams", teamId, "seasonteams");
            const teamRef = doc(firestore, "teams", teamId);
    
            // Get players
            const playersSnapshot = await getDocs(playersRef);
            players = playersSnapshot.docs.map(doc => ({
                ...doc.data(),
                jersey: doc.data().number,
            } as RosterPlayer));
    
            // Get season teams
            const seasonTeamsSnapshot = await getDocs(seasonTeamsRef);
            seasonTeams = seasonTeamsSnapshot.docs.map(doc => ({
                ...doc.data(),
            } as SeasonTeam));
    
            // Get team data
            const teamDoc = await getDoc(teamRef);
            if (teamDoc.exists()) {
                team = {
                    ...teamDoc.data(),
                    players: players,
                    seasonteams: seasonTeams,
                } as Prototeam;
            } else {
                throw new Error(`Team with ID ${teamId} does not exist`);
            }
    
        } catch (error) {
            console.error("Error getting documents:", error);
            // Handle the error based on your needs
            throw new Error("Failed to retrieve team data");
        }
    
        return team;
    };

    // load code details
    const inviteAccepted = useCallback(async (teamId: string) => {

        setServiceState({ ...service, isFetching: true })

        // conditionally set auth header
        const headers = new Headers()
        user.isLoggedIn && headers.set("Authorization", `Bearer ${user.tokens.access}`)

        // send request
        const url = `${app.config.gateways.eventLog}/v5/users/invite-accepted`;
        const body = {
            attributes: {
                event: "invite-accepted",
                schema: "user-prototeam",
                version: "v1"
            },
            data: {
                userId: user.id,
                prototeamId: teamId
            }
        };
        let response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });

        if (!response.ok) {
            setServiceState({
                ...service,
                isFetching: false,
                hasServerError: true,
                serverError: "Error accepting invitation.",
            })
            return response;
        }

        // set state
        setServiceState({ ...service, isFetching: false, hasServerError: false, serverError: "", })

        return response

    }, [ user.id, app.config.gateways.eventLog, service ])

    // load code details
    const loadCodeDetails = useCallback(async (code: string) => {

        setServiceState({ ...service, isFetching: true })

        // conditionally set auth header
        const headers = new Headers()
        user.isLoggedIn && headers.set("Authorization", `Bearer ${user.tokens.access}`)

        // send request
        const url = `${app.config.gateways.auth}/auth/v4/prototeam-invitation-code/${code}`
        let response = await fetch(url, { headers }).then(res => res.json()).catch(err => {
            return {
                status: "error",
                errors: [{ code: 500, message: err.message }]
            }
        })

        if (response.status === "error") {
            setServiceState({
                ...service,
                isFetching: false,
                hasServerError: true,
                serverError: response.errors.map((message: string) => message).join(),
            })
            return response;
        }

        // the payload should be changed to match the error format, but for now we'll just return the response
        // add the status property to the response for consistency and fault tolerance
        response = {
            status: response.status || "success",
            data: response.data || response
        }

        // set state
        setServiceState({
            ...service,
            isFetching: false,
            hasServerError: false,
            serverError: "",
        })

        return response

    }, [
        user.tokens.access,
        app.config.gateways.auth,
        service
    ])

    const createAccount = useCallback(async (firstName: string, lastName: string, email: string, password: string, teamId: string, uid: string) => {
        const requestData = {
            attributes: {
                event: "create-account",
                schema: "account-info",
                version: "v1",
            },
            data: {
                firstName: firstName,
                lastName: lastName,
                email: email,
                password: password,
                teamId: teamId,
                uid: uid,
            },
        };
    
        const url = `${app.config.gateways.eventLog}/v5/users/create-account`;
        const headers = new Headers();
        user.isLoggedIn && headers.set("Authorization", `Bearer ${user.tokens.access}`);
    
        try {
          const response = await fetch(url, {
            method: "POST",
            headers,
            body: JSON.stringify(requestData),
          });
    
          return response;
        } catch (error) {
          console.error("Error while creating account", error);
          return error;
        }
    }, [app.config.gateways.eventLog, user.tokens.access, user.isLoggedIn]);

    const setFirebaseUserId = useCallback(async (email: string, uid: string) => {

        setServiceState({ ...service, isFetching: true })

        // send request
        let response = await fetch(`/events/set-user-fuid`, {
            method: 'POST',
            body: JSON.stringify({ email, uid })
        }).then(res => {
            return {
                status: "success",
                errors: [],
                data: {}
            }
        }).catch(err => {
            return {
                status: "error",
                errors: [{ code: 500, message: err.message }]
            }
        })

        if (response.status === "error") {
            setServiceState({
                ...service,
                isFetching: false,
                hasServerError: true,
                serverError: response.errors.map((e: { message: string }) => e.message).join(),
            })
            return response;
        }

        setServiceState({ ...service, isFetching: false })
        return response

    }, [
        app.config.gateways.auth,
        service
    ])

    const mergeTeams = useCallback(async (strategy: string, teamId: number, prototeamId: string) => {

        setServiceState({ ...service, isFetching: true })

        // conditionally set auth header
        const headers = new Headers()
        user.isLoggedIn && headers.set("Authorization", `Bearer ${user.tokens.access}`)

        // send request
        let response = await fetch(`/events/merge-teams`, {
            method: 'POST',
            body: JSON.stringify({ strategy, prototeamId, teamId }),
            headers
        }).then(res => res.json()).catch(err => {
            return {
                status: "error",
                errors: [{ code: 500, message: err.message }]
            }
        })

        if (response.status === "error") {
            setServiceState({
                ...service,
                isFetching: false,
                hasServerError: true,
                serverError: response.errors.map((e: { message: string }) => e.message).join(),
            })
            return response;
        }

        setServiceState({ ...service, isFetching: false })

        return {
            status: "success",
        }

    }, [
        user.tokens.access,
        service
    ])

    // get preview of merging invite team with existing prototeam
    const loadMergePreview = useCallback(async (teamId: number, prototeamId: string) => {

        setServiceState({ ...service, isFetching: true })

        // conditionally set auth header
        const headers = new Headers()
        user.isLoggedIn && headers.set("Authorization", `Bearer ${user.tokens.access}`)

        // send request
        const url = `${app.config.gateways.events}/prototeams`
        let response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                event: "preview-shuffle-proto-team",
                attributes: {
                    schema: "preview-shuffle-team-event"
                },
                data: {
                    id: prototeamId,
                    seasonTeam: teamId
                }
            }),
            headers
        }).then(res => res.json()).catch(err => {
            return {
                status: "error",
                errors: [{ code: 500, message: err.message }]
            }
        })

        if (response.status === "error") {
            setServiceState({
                ...service,
                isFetching: false,
                hasServerError: true,
                serverError: response.errors.map((e: { message: string }) => e.message).join(),
            })
            return response;
        }

        // the payload should be changed to match the error format, but for now we'll just return the response
        // add the status property to the response for consistency and fault tolerance
        response = {
            status: response.status || "success",
            data: response.data || response
        }

        // set state
        setServiceState({
            ...service,
            isFetching: false,
            hasServerError: false,
            serverError: "",
        })

        return response

    }, [
        user.tokens.access,
        app.config.gateways.events,
        service
    ])

    return {

        // data
        ...service,

        // functions
        loadCodeDetails,
        getTeam,
        inviteAccepted,
        createAccount,
        setFirebaseUserId,
        mergeTeams,
        loadMergePreview,
        reset: () => _setServiceState({ ...initialState }),
    }

}