import { useCallback, useEffect, useState } from "react";
import { atom, selector, useRecoilState, useRecoilValue } from "recoil";
import { firestore } from "@/libs/firebase";
import { collection, collectionGroup, query, getDocs, onSnapshot, where } from "firebase/firestore";
import { useAppState } from "@/state/app/useAppState";
import { SeasonTeamData, SeasonTeamsData, useSeasonsData } from "./useSeasonTeams";
import { useUnleashContext } from "@unleash/proxy-client-react";
import { useUserState } from "@/state/user/useUserState";
import { MemberClaim } from "@/services/useMemberService/types.ts/member";
import { Relationship } from "@/flows/AcceptInvitationFlow/types";

export type TeamData = {
    id: string;
    title: string;
    logo: string;
    club: string;
    sport?: string;
    seasonTeams: SeasonTeamData[];
    claim?: MemberClaim;
    teamRelationship?: Relationship;
}

export type TeamsData = {
    data: TeamData[],
    unmigratedTeams: TeamData[],
    loaded: boolean,
    lastUpdated: number,
}

// imported into useSeasonTeams!
export const rawTeamsDataState = atom<TeamsData>({
    key: 'rawTeamsDataState',
    default: {
        data: [],
        unmigratedTeams: [],
        loaded: false,
        lastUpdated: 0,
    },
});

export const teamsDataState = selector<TeamsData>({
    key: 'teamsDataState',
    get: ({get}) => {
        
        const rawProtoTeams = get(rawTeamsDataState)
        const rawSeasonTeams = get(rawSeasonTeamsDataState)
        const teams: TeamData[] = []
        for (const prototeam of rawProtoTeams.data) {
            
            const seasonTeams = rawSeasonTeams[prototeam.id]
            if(!seasonTeams) continue;
            if(!seasonTeams.length) continue;
            teams.push({...prototeam, seasonTeams: seasonTeams})
            
        }
        
        return {
            data: teams,
            unmigratedTeams: rawProtoTeams.unmigratedTeams,
            loaded: rawProtoTeams.loaded,
            lastUpdated: rawProtoTeams.lastUpdated,
        }

    },
});

export const rawSeasonTeamsDataState = atom<{[key:string]:SeasonTeamsData}>({
    key: 'rawSeasonTeamsDataState',
    default: {},
});

export function useReadTeamsData() {

    const app = useAppState()
    const user = useUserState()
    const [ rawTeamsData, setRawTeamsData ] = useRecoilState(rawTeamsDataState)
    const [ rawSeasonTeamsData, setRawSeasonTeamsData ] = useRecoilState(rawSeasonTeamsDataState)
    const updateContext = useUnleashContext();
    const [ userTeams, setUserTeams ] = useState<{ teams: {id: string, claim: MemberClaim, teamRelationship: Relationship}[], loaded: boolean }>({ teams: [], loaded: false });

    useEffect(() => {

        if(!user.id) return;

        const subscriptions:Array<() => void> = []

        const q = query(collectionGroup(firestore, "members"), where("id", "==", user.id))
            
        const sub = onSnapshot(q, async (querySnapshot) => {
            const teams = querySnapshot.docs.map(doc => {
                const data = doc.data();
                return { id: data.team.id, claim: data.claim };
            });
            
            const uniqueTeams = Array.from(new Set(teams.map(team => team.id)))
                .map(id => teams.find(team => team.id === id)) as { id: string, claim: MemberClaim, teamRelationship: Relationship }[];
            
            setUserTeams({ teams: uniqueTeams, loaded: true });
        });
        subscriptions.push(sub)

        return () => {
            subscriptions.forEach(unsub => unsub())
        }

    }, [ app.activeUser ])

    useEffect(() => {

        // only logged in user will be able to read teams data
        if(!app.activeUser || !userTeams.loaded) return;
        
        const subscriptions:Array<() => void> = []
        
        const q = query(collection(firestore, "teams"), where("users","array-contains", app.activeUser.email))
        const sub = onSnapshot(q, async (querySnapshot) => {
            
            let data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }) as TeamData)
            const teamsList = data.filter(team => !userTeams.teams.some(userTeam => userTeam.id === team.id));

            let noTeams = false;
            if (userTeams.teams.length === 0) noTeams = true;

            setRawTeamsData((s) => ({
                data: s.data,
                unmigratedTeams: teamsList,
                loaded: noTeams,
                lastUpdated: Date.now(),
            }));

            if (noTeams && teamsList.length > 0) {
                app.setSelectedTeam(teamsList[0]?.id);
                app.navigate("/migrate?teamId=" + teamsList[0]?.id);
            }
        

        })
        subscriptions.push(sub)

        return () => {
            subscriptions.forEach(unsub => unsub())
        }

    }, [ JSON.stringify(userTeams), app.activeUser ])

    useEffect(() => {

        // only logged in user will be able to read teams data
        if(userTeams.teams.length === 0 ) return;
        
        const subscriptions:Array<() => void> = []
        const teamIds = userTeams.teams.map(team => team.id)
        
        const q = query(collection(firestore, "teams"), where("id","in", teamIds))
        const sub = onSnapshot(q, async (querySnapshot) => {
            
            let data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }) as TeamData)
            
            for (const team of data) {

                team.teamRelationship = userTeams.teams.find(userTeam => userTeam.id === team.id)?.teamRelationship
                team.claim = userTeams.teams.find(userTeam => userTeam.id === team.id)?.claim

                const q2 = query(
                    collection(firestore, `teams/${team.id}/seasonteams`), 
                    where("deletedAt.Valid","==",false),
                )

                const sub = onSnapshot(q2, async (querySnapshot2) => {
                    
                    const seasonTeamData = querySnapshot2.docs
                        .map((doc) => ({ id: doc.id, ...doc.data() }) as SeasonTeamData)
                        .filter(team => !team.season?.archived)

                    setRawSeasonTeamsData((old) => {
                        return {
                            ...old,
                            [team.id]: seasonTeamData
                        }
                    })
                    setRawTeamsData(old => {
                        return {
                            ...old,
                            lastUpdated: Date.now(),
                        }
                    })

                })
                subscriptions.push(sub)

            }

            setRawTeamsData((s) => ({
                data,
                unmigratedTeams: s.unmigratedTeams,
                loaded: true,
                lastUpdated: Date.now(),
            }));
            
            let selectedTeam:string = app.selectedTeam

            // if a new prototeam is added, select it
            querySnapshot.docChanges().forEach((change) => {
                
                if (change.type === "added") {
                    selectedTeam = change.doc.id
                }

                if(change.type === "removed" && app.selectedTeam === change.doc.id){
                    selectedTeam = ""
                }

            });

            if (selectedTeam) {
                app.setSelectedTeam(selectedTeam)
            }


        })
        subscriptions.push(sub)

        return () => {
            subscriptions.forEach(unsub => unsub())
        }

    }, [ userTeams ])

    useEffect(() => {

        const loaded = rawTeamsData.loaded;
        const prototeams = rawTeamsData.data.length;
        const loadedPrototeamSeasonTeams = Object.keys(rawSeasonTeamsData).length;

        const validTeams: TeamData[] = []
        for (const prototeam of rawTeamsData.data) {
            
            const seasonTeams = rawSeasonTeamsData[prototeam.id]
            if(!seasonTeams) continue;
            if(!seasonTeams.length) continue;
            validTeams.push({...prototeam})
            
        }

        if (!app.selectedTeam || !validTeams.find(team => team.id === app.selectedTeam)) {
            app.setSelectedTeam(validTeams[0]?.id)
        }

        if(!loaded){
            return
        }

        if(prototeams > loadedPrototeamSeasonTeams){
            return
        }

        app.loading.complete('teams')

    }, [ rawTeamsData.loaded, rawTeamsData.data.length, JSON.stringify(rawSeasonTeamsData) ])

}

export function useTeamsCountOnLogin() {
    const app = useAppState();
  
    useEffect(() => {
      if (!app.activeUser) return;
  
      const fetchTeamsCount = async () => {
        try {
          const q = query(collection(firestore, 'teams'), where('users', 'array-contains', app.activeUser?.email) );
          const querySnapshot = await getDocs(q);
          const data = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          
          app.teams.setCheckedOnLogin(true);
        } catch (error) {
          console.error('Error fetching teams count:', error);
          
          app.teams.setCheckedOnLogin(true);
        }
      };
  
      fetchTeamsCount();
    }, [ app.activeUser ]);
  
    return app.teams.count; // You can return the count if needed
}

export function useTeamsData() {
    
    const { lastUpdated, data, unmigratedTeams } = useRecoilValue(teamsDataState)
    const { prototeams } = useSeasonsData()

    const getPlaysIn = useCallback((teamId: string) => {
        
        const seasonTeams = prototeams.find((prototeam) => prototeam.id === teamId)?.seasonTeams

        if( seasonTeams?.length ){
            
            for(let i = seasonTeams?.length - 1; i >= 0; i--){
                try {
                    const season = seasonTeams[i]?.season.title
                    const division = seasonTeams[i]?.division.title
                    return `${division}, ${season}`
                } catch (e) {
                    continue;
                }
            }

        }

        return null
    }, [ JSON.stringify(prototeams) ])

    const getSeasonIds = (teamId: string) => {
        const seasonTeams = prototeams.find((prototeam) => prototeam.id === teamId)?.seasonTeams;
      
        if (seasonTeams?.length) {
          const seasonIds = seasonTeams?.map((seasonTeam) => seasonTeam.season.id).filter(Boolean);
          return seasonIds;
        }
      
        return [];
      };

    const getSeasonTeams = (teamId: string) => prototeams.find((prototeam) => prototeam.id === teamId)?.seasonTeams;
      
    return {
        onChange: lastUpdated,
        all: () => data,
        teams: data,
        unmigratedTeams: unmigratedTeams,
        getSeasonIds,
        getPlaysIn,
        getSeasonTeams,
    }

}