import { Button, Title, WhistleIcon } from "@gamesheet/ui";
import { useEffect, useMemo } from "react";
import { useAppState } from "@/state/app/useAppState";
import { styled } from "styled-components";

const DesktopSwitchToDashboard = styled.div`
    font-size: 20px;
    line-height: 23.7px;

    .header {
        font-weight: 700;
        padding-top: 20px;
    }

    .body {
        font-weight: 400;
        font-size: 19.2px;
        line-height: 22.75px;
        padding-top: 20px;
    }

    .info {
        font-style: italic;
        font-size: 19.2px;
        line-height: 22.75px;
        padding-top: 20px;
    }
`

const MobileSwitchToDashboard = styled.div`
    font-size: 20px;
    line-height: 23.7px;

    .header {
        font-weight: 700;
        padding-top: 20px;
    }

    .body {
        font-weight: 400;
        font-size: 19.2px;
        line-height: 22.75px;
        padding-top: 20px;
    }

    .info {
        font-style: italic;
        font-size: 19.2px;
        line-height: 22.75px;
        padding-top: 20px;
    }
`

type SwitchToDashboardProps = {
    onSwitch: () => void;
    onBack: () => void;
    register: boolean;
}

export function SwitchToDashboard({ onBack, onSwitch, register }:SwitchToDashboardProps){
    
    const app = useAppState()
    const StyledSwitchToDashboard = (app.layout.showTablet || app.layout.showDesktop) ? DesktopSwitchToDashboard : MobileSwitchToDashboard;

    const BtnBack = useMemo(() => <Button type="button" size="lg" style={register ? {} : {marginLeft: '16px', height: '36px', marginBottom: '-100px'}} variant="inverted" onClick={onBack}>Back</Button>, [ onBack ])
    useEffect(() => app.ui('leftTray').set(BtnBack, app.layout.showMobile), [ BtnBack, app.layout.showMobile ])

    const BtnNext = useMemo(() => <Button type="button" size="lg" style={register ? {} : {marginRight: '16px', height: '36px', marginBottom: '-100px'}} onClick={onSwitch}>Continue</Button>, [ onSwitch ])
    useEffect(() => app.ui('rightTray').set(BtnNext, app.layout.showMobile), [ BtnNext, app.layout.showMobile ])

    return <div>

        <StyledSwitchToDashboard>
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '28px' }}><WhistleIcon selected={false} height={80} width={80}/></div>
            <div className="header">Whoops! It looks like you're in the wrong app.</div>
            <div className="body">Since you're not directly involved with the team, you should be using the GameSheet Dashboard. It's equipped with all the tools you need to review scoresheets and track the team's progress. At the end of the flow, you will be automatically redirected to the GameSheet Dashboard</div>
            <div className="info">If you believe this is a mistake and you need to change your role, click 'Back' to return to the previous step.</div>
        </StyledSwitchToDashboard>
        
        { (app.layout.showTablet || app.layout.showDesktop) && <>
            <hr/>
            <div style={{ display:'flex', justifyContent:'space-between' }}>
                {BtnBack}
                {BtnNext}
            </div>
        </>}

    </div>

}