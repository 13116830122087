import { listMembers } from "@/services/useMemberService/members/func.listMembers";
import { FirestoreMember, Member, MemberUnreadMessages } from "@/services/useMemberService/types.ts/member";
import { DocumentReference } from "firebase/firestore";
import { useEffect } from "react";
import { atom, selector, useRecoilValue, useSetRecoilState } from "recoil";
import { useAppState } from "../app/useAppState";
import { channelsState } from "../messaging/useChannelsState";

export const firestoreMembersState = atom<FirestoreMember[]>({
    key: "firestoreMembersState",
    default: [],
    dangerouslyAllowMutability: true,
});

export const membersState = selector({
    key: "membersState",
    get: ({get}):Member[] => {
        
        const channels = get(channelsState);
        
        return get(firestoreMembersState).map(doc => {
            return { 
                ...doc, 
                unread: doc.unread.reduce((
                    unread:MemberUnreadMessages, 
                    ref:DocumentReference
                ) => {

                    const channel = ref.parent?.parent;
                    if(!channel) return unread;

                    unread[channel.id] = [
                        ...(unread[channel.id] || []),
                        ref
                    ]
                    return unread;
                }, {}),
                hasRead(messageId:string){
                    return !doc.unread.find(ref => {
                        return ref.id === messageId
                    });
                },
                unreadByTeam(teamId:string){
                    
                    return doc.unread.filter(ref => {
                        
                        const channelId = ref.parent?.parent?.id;
                        if(!channelId) return false;
                        
                        const channel = channels.data.find(channel => channel.id === channelId);
                        return !!channel?.relationships.find(relationship => {
                            return relationship.type === "team" 
                                && relationship.ref.id === teamId
                        })

                    }).length;
                    
                },
                unreadByChannel(channelId:string){
                    return this.unread[channelId]?.length || 0;
                }
            } as Member
        });

    },
    dangerouslyAllowMutability: true,
})

export const useReadMembersState = (teamId:string|null) => {

    const app = useAppState();
    const setMembers = useSetRecoilState(firestoreMembersState)

    useEffect(() => {
        
        if(!teamId){
            setMembers([]);
            return;
        }

        const unsubscribe = listMembers(teamId, async (snapshot) => {
            setMembers(snapshot.docs.map(doc => {
                return { 
                    ...doc.data(), 
                    id: doc.id,
                    _ref: doc.ref,
                } as Member;
            }))
        })

        return () => {
            unsubscribe();
            setMembers([]);
        };

    }, [ teamId ]);

    return useMembersState()

}

export const useMembersState = () => {
    
    const members = useRecoilValue(membersState);

    return {
        members,
        getMemberById(id:string){
            return members.find(member => member.id === id);
        },
        getMemberByEmail(email:string){
            return members.find(member => member.email === email);
        }
    };

}