import { firestore } from "@/libs/firebase";
import { arrayRemove, updateDoc, doc } from "firebase/firestore";

export async function removeFromUsersArray(
    team: string,
    email: string
){
    const teamRef = doc(firestore, `teams/${team}`);

    try {
        await updateDoc(teamRef, {
            users: arrayRemove(email)
        });
    } catch (error) {
        console.error("Error removing club team: ", error);
    }
}