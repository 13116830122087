import { useEffect, useState, ChangeEvent } from "react";
import styled from "styled-components";
import { Modal, Card, Alert, Button } from "@gamesheet/ui";
import { useEditPrototeam } from "./useEditPrototeam";
import { useAppState } from "@/state/app/useAppState";
import redTrashcan from "../../assets/images/red_trash_can.svg";
import jersey from "../../assets/images/dark_blank_jersey.svg";
import { useRecoilValue } from "recoil";
import { protoTeamModalState } from "./useEditPrototeam";
import { ImageUploader } from "@/components/pure/ImageUploader";
import { useUserState } from "@/state/user/useUserState";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 5200;
`;

const EditTeamContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  width: 191px;
  height: 191px;
  border-radius: 0.3125rem;
  border: 1px solid #d7d7d8;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageAndControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
`;

const InfoText = styled.div`
  color: #36383d;
  width: 9.9375rem;
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
`;

const ErrorMessage = styled.div`
  color: #000;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 0.3125rem;
  background: #ffafaf;
  max-width: 9.25rem;
  padding: 0.25rem;
  margin-bottom: 0.5rem;
`;

const EmptyErrorMessages = styled.div`
  width: 9.25rem;
  height: 2rem;
  margin-bottom: 0.5rem;
`;

const ErrorControlsDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0.75rem;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

const RemoveTeamContainer = styled.div`
  padding-top: 1.25rem;
  padding-bottom: 0.75rem;
  .button {
    font-size: 1rem !important;
    font-weight: 500 !important;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .button {
    font-size: 1rem !important;
    font-weight: 500 !important;
  }
`;

const Logo = styled.img`
  object-fit: contain;
  padding: 0.75rem;
  width: 100%;
  height: 100%;
`;

const ConfirmDeleteContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const Divider = styled.div`
  border: 1px solid #D7D7D8;
  margin-bottom: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
`

export function EditPrototeam() {
  const app = useAppState();
  const user = useUserState();

  const { isOpen, close, loading, onSubmit } = useEditPrototeam();

  const prototeam = useRecoilValue(protoTeamModalState);

  const [teamName, setTeamName] = useState<string>(prototeam.prototeam.title);
  const [teamNameError, setTeamNameError] = useState("");
  const [teamLogo, setTeamLogo] = useState<string>(prototeam.prototeam.logo);
  const [isSubmittingImage, setIsSubmittingImage] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "teamName") {
      if (value.trim() === "") {
        setTeamNameError("Team Name is required.");
      } else {
        setTeamNameError("");
      }
      setTeamName(value);
    }
  };

  const handleCancel = () => {
    setError(null);
    setCanSubmit(false);
    setConfirmDelete(false);
    setTeamLogo(prototeam.prototeam.logo);
    setTeamName(prototeam.prototeam.title);
    close();
  };

  useEffect(() => {
    setError(null);
    setTeamNameError("");
    setConfirmDelete(false);
    setTeamLogo(prototeam.prototeam.logo);
    setTeamName(prototeam.prototeam.title);
  }, [prototeam.prototeam]);

  useEffect(() => {
    if (teamNameError === "" && (teamName !== prototeam.prototeam.title || teamLogo !== prototeam.prototeam.logo)) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [teamName, teamLogo, prototeam.prototeam]);

  const handleUploadSuccess = (imageUrl: string) => {
    setTeamLogo(imageUrl);
  };

  if (!isOpen || !prototeam) {
    return <></>;
  }

  return (
    <ModalContainer>
      <Modal title="Edit Team" show={isOpen} onClose={handleCancel}>
        <EditTeamContainer>
          <div>
            <div className="form-floating mb-3">
              <input type="text" name="teamName" id="teamName" className={"form-control " + (teamNameError ? "is-invalid" : "")} placeholder="Team Name" onChange={handleChange} value={teamName} />
              <label htmlFor="teamName" className="floating">
                {teamNameError ? teamNameError : "Team Name*"}
              </label>
            </div>
          </div>
          <ImageAndControlsContainer>
            <ImageContainer>{teamLogo && teamLogo !== "Remove" ? <Logo src={`${teamLogo}/256`} alt={`${prototeam.prototeam.title}-logo`} /> : <Logo src={jersey} alt="team-logo" />}</ImageContainer>
            <ErrorControlsDetailsContainer>
              {error ? <ErrorMessage>{error}</ErrorMessage> : <EmptyErrorMessages />}
              <ControlsContainer>
                <img src={redTrashcan} alt="trashcan-icon" style={{ margin: "0 0.5rem", cursor: "pointer" }} onClick={() => setTeamLogo("Remove")} />
                <ImageUploader
                  label="Change Logo"
                  onUploadSuccess={handleUploadSuccess}
                  getToken={user.GetToken}
                  setIsSubmitting={setIsSubmittingImage}
                  setError={setError}
                  isSubmitting={isSubmittingImage}
                  disabled={false}
                />
              </ControlsContainer>
              <InfoText>Supported formats are PNG, JPEG, WebP, and SVG. The maximum file size is 10 MB</InfoText>
            </ErrorControlsDetailsContainer>
          </ImageAndControlsContainer>
          <Divider/>
          <FooterContainer>
            <Button variant="inverted" label="Cancel" onClick={handleCancel}>
              Cancel
            </Button>
            {canSubmit ? <Button label="Save" onClick={() => onSubmit({ id: prototeam.prototeam.id, teamName, teamLogo })} /> : <Button state="disabled" variant="inverted" label="Save" />}
          </FooterContainer>
        </EditTeamContainer>
      </Modal>
    </ModalContainer>
  );
}
