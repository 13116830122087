import { CSVRosterImportStatus as ImportStatus } from "./types/importStatus";
import { useRosterImport } from "./hooks/useRosterImport";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { Parser } from "./steps/Parser";
import { ValidationFailed } from "./steps/ValidationFailed";
import { ValidationSucceeded } from "./steps/ValidationSucceeded";
import { ImportingProgress } from "./steps/ImportingProgress";
import { ImportComplete } from "./steps/ImportComplete";
import { Button } from "@gamesheet/ui";

import { ShowIf } from "@/components/pure/ShowIf";
import React, { useEffect, useRef } from "react";
import { useAppState } from "@/state/app/useAppState";

const StyledBottomButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }

  @media (min-width: 769px) {
    align-items: center;
  }
  

  .button-group {
    display: flex;
    gap: 20px;


    @media (max-width: 768px) {
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;
    }
    @media (min-width: 769px) {
      justify-content: center;
    align-items: center;

    }
  }
`;

const StyledFilePicker = styled.div`
  display: flex;
  flex-direction: column;
`;

// component definition
export function ImportFromCsvWizard() {
  const app = useAppState();

  // import the roster managed state
  const {
    status,
    error,
    handleStartImport,
    handleFileSelection,
    handleDownloadTemplate,
    clearData,
  } = useRosterImport();

  useEffect(() => {
    clearData();
  }, []);

  const onCancel = () => {
    clearData();
    app.navigate("/roster/team");
  }

  // render the wizard
  return (
    <>
      {(status === ImportStatus.PENDING ||
        status === ImportStatus.PARSING_FAILED ||
        status === ImportStatus.VALIDATION_FAILED) && (
        <Parser error={error && error.message} />
      )}

      {status === ImportStatus.VALIDATION_FAILED && <ValidationFailed />}

      {status === ImportStatus.PARSED && <ValidationSucceeded />}

      <ShowIf
        cond={status}
        value={ImportStatus.IMPORTING}
        component={<ImportingProgress />}
      />
      <ShowIf
        cond={status}
        value={ImportStatus.COMPLETE}
        component={<ImportComplete />}
      />
      <StyledBottomButtonGroup>
        {status !== ImportStatus.COMPLETE &&
          status !== ImportStatus.IMPORTING && (
            <Button
              type="button"
              color="secondary"
              variant="inverted"
              textColor="white"
              size="lg"
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
          )}

        <div className="button-group">
          {(status === ImportStatus.PENDING ||
            status === ImportStatus.PARSING_FAILED ||
            status === ImportStatus.VALIDATION_FAILED) && (
            <FilePicker onChange={handleFileSelection} text="Select File" />
          )}

          {(status === ImportStatus.PENDING ||
            status === ImportStatus.PARSING_FAILED ||
            status === ImportStatus.VALIDATION_FAILED) && (
            <Button
              type="button"
              color="secondary"
              onClick={(e) => handleDownloadTemplate(e)}
              gs-style="outline"
              textColor="black"
              size="lg"
            >
              Download Template
            </Button>
          )}
        </div>

        <ShowIf cond={status} value={ImportStatus.PARSED}>
          <Button type="button" color="success" size="lg" onClick={handleStartImport}>
            Begin Import
          </Button>
        </ShowIf>

        <ShowIf cond={status} value={ImportStatus.COMPLETE}>
          <NavLink to="/roster/team">
            <Button type="button" color="primary">
              Go To Team Roster
            </Button>
          </NavLink>
        </ShowIf>
      </StyledBottomButtonGroup>
    </>
  );
}

const FilePicker = ({
  onChange,
  text,
}: {
  onChange: (f: File | undefined) => any;
  text: string;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const _handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    onChange(file);
  };

  return (
    <StyledFilePicker>
      <input
        type="file"
        accept=".csv"
        style={{ display: "none" }}
        onChange={_handleUpload}
        ref={inputRef}
      />
      <Button
        type="button"
        color="success"
        size="lg"
        onClick={() => inputRef?.current?.click()}
      >
        {text}
      </Button>
    </StyledFilePicker>
  );
};
