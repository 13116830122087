import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";
import { useAppState } from "@/state/app/useAppState";
import { ForgotPasswordFormState } from "@/forms/ForgotPasswordForm";
import { useSelectedTeamState } from "@/state/team/useSelectedTeamState";

type InviteUserModal = {
  loading: boolean;
  isOpen: boolean;
};

export const forgotPasswordModalState = atom<InviteUserModal>({
  key: "forgotPasswordModalState",
  default: {
    loading: false,
    isOpen: false,
  },
});

export function useForgotPassword() {
  const app = useAppState();

  const [{ loading, isOpen }, setState] = useRecoilState(forgotPasswordModalState);

  const open = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: true,
    }));

    document.body.style.overflow = "hidden";
  }, [setState]);

  const close = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: false,
    }));

    document.body.style.overflow = "auto";
  }, [setState]);

  const onSubmit = useCallback(async ({ email }: ForgotPasswordFormState) => {
    if (!email || !email.valid) {
      return;
    }

    const requestData: any = {
      attributes: {
        event: "request-password-reset",
        schema: "user-email",
        version: "v1",
      },
      data: {
        email: email.value,
      },
    };

    const url = `${app.config.gateways.eventLog}/v5/users/request-password-reset`;
    const headers = new Headers();

    try {
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        setState((prev) => ({
          ...prev,
          isOpen: false,
        }));
      } else {
        console.error("Error while sending invitation emails", response);
      }
    } catch (error) {
      console.error("Error while sending invitation emails", error);
    }
  }, []);

  const manageUserPasswordReset = useCallback(async (email: string) => {
    if (!email) {
      return;
    }

    const requestData: any = {
      attributes: {
        event: "request-password-reset",
        schema: "user-email",
        version: "v1",
      },
      data: {
        email: email,
      },
    };

    const url = `${app.config.gateways.eventLog}/v5/users/request-password-reset`;
    const headers = new Headers();

    try {
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        setState((prev) => ({
          ...prev,
          isOpen: false,
        }));
      } else {
        console.error("Error while sending invitation emails", response);
      }
    } catch (error) {
      console.error("Error while sending invitation emails", error);
    }
  }, []);

  return {
    loading,
    open,
    close,
    isOpen,
    onSubmit,
    manageUserPasswordReset,
  };
}
