import { useAppState } from "@/state/app/useAppState";
import { useUserState } from "@/state/user/useUserState";
import { Loading, Title } from "@gamesheet/ui";
import { getMembers } from "@/services/useMemberService/members/func.getMembers";
import { useCallback, useEffect, useRef, useState } from "react";
import { FirestoreMember } from "@/services/useMemberService/types.ts/member";
import styled from "styled-components";
import { getAllInvitations } from "@/services/useMemberService/invitations/func.getAllInvitations";
import { usePageState } from "@/state/page/usePageState";
import { useInviteUserService } from "@/flows/InviteUserFlow/services/useInviteUserService";
import { acceptInvitation } from "@/services/useMemberService/invitations/func.markInvitation";
import { removeMember } from "@/services/useMemberService/members/func.removeMember";
import { useForgotPassword } from "@/windows/forgotPassword/useForgotPassword";
import { useRemoveFromTeam } from "@/windows/removeFromTeam/useRemoveFromTeam";

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-family: Rubik;

  th, td {
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
    text-align: left;
    min-height: 61px;
    height: 61px;
  }

  td {
    white-space: normal;
    word-break: break-word;
  }

  tr {
    &:hover {
      background-color: #D2E4FD;
    }
  }

  th {
    font-size: 1.2em;
    font-weight: 500;
    text-decoration: underline;
    background-color: #fafafa;
  }

  td.status.active {
    color: green;
  }

  td.status.pending {
    color: orange;
  }

  tr:nth-child(odd) {
    background-color: #f3f3f3;

    &:hover {
      background-color: #D2E4FD;
    }
  }

  td.email-cell {
    white-space: pre-wrap;
    word-break: break-word;
  }
`;


const OptionsButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  position: relative;
  
  &::before {
    content: '⋮';
    font-size: 1.5em;
    color: #333;
  }
`;

const PopUpMenu = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  z-index: 10;
  width: 180px;
`;

const MenuItem = styled.button`
  text-family: Rubik;
  display: block;
  width: 100%;
  padding: 10px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  
  &:hover {
    background: #f3f3f3;
  }
`;

type ListedUser = {
    index: number;
    id: string;
    name: string;
    email: string;
    displayEmail: string;
    role: string;
    status: string;
}

export function ManageUsersPage() {
    const app = useAppState();
    const events = app.events
    const user = useUserState();
    const [members, setMembers] = useState<ListedUser[]>([]);
    const [loading, setLoading] = useState(true);
    const [showMenu, setShowMenu] = useState<{ [key: string]: boolean }>({});
    const [memberCount, setMemberCount] = useState(0);
    const [ refreshMembers, setRefreshMembers ] = useState<number>(0);
    const { open: openInviteUserModal } = useInviteUserService()
    const { open: openRemoveFromModal } = useRemoveFromTeam()
    const { manageUserPasswordReset } = useForgotPassword();
    const menuRef = useRef<HTMLDivElement>(null);
    const showMobile = app.layout.showMobile

    useEffect(() => events.add('show-invite-user', openInviteUserModal), [openInviteUserModal])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setShowMenu({});
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const formatEmail = (email: string) => {
        if ((!showMobile && email.length > 35) || (showMobile && email.length > 23)) {
            return email.replace("@", "@\n");
        }
        return email;
    };

    const Page = usePageState('manage-users', {
        showActionsMenu: true,
        rememberScroll: false
    })

    useEffect(() => {
        Page.clearButtons()

        Page.addButton(() => {
            openInviteUserModal();
            Page.closeActionMenu();
        }, 'Invite to Team')

    }, [openInviteUserModal]);

  useEffect(() => {
    let mounted = true;

    const fetchMembers = async () => {
      try {
        const invitations = await getAllInvitations(app.selectedTeam);
        const membersData = await getMembers(app.selectedTeam);
        const _invitations = invitations.filter((invitation) => invitation.status === 'pending' && invitation.type === 'one_time' && invitation.claim.role === 'staff' && invitation.email !== "");
        let i = 0;
        const users = (membersData?.map((member: FirestoreMember) => ({ index: i++, id: member.id, name: member.name, email: member.email, displayEmail: formatEmail(member.email), role: member.claim.role, status: 'Active'})) || [])
          .concat(_invitations.map((invitation) => ({ index: i++, id: invitation.id, name: "", email: invitation.email, displayEmail: formatEmail(invitation.email), role: "staff", status: 'Pending' })));
        if (mounted) {
          setMembers(users);
          setMemberCount(users.length);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching members:", error);
        setMembers([]);
        setMemberCount(0);
        setLoading(false);
      }
    };

    let timer: NodeJS.Timeout;
    if (refreshMembers > 0) {
      timer = setTimeout(() => {
        fetchMembers();
      }, 4500);
    } else {
      fetchMembers();
    }
    

    return () => {
      mounted = false;
      clearTimeout(timer);
    };
  }, [app.selectedTeam, refreshMembers]);

  const toggleMenu = (id: number) => {
    setShowMenu((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleResendEmail = (id: number) => {
    const member = members.find((member) => member.index === id);
    openInviteUserModal(member?.email || "")
    toggleMenu(id);
  };

  const handleCancelInvitation = useCallback((id: number) => {
    const member = members.find((member) => member.index === id);
    member && acceptInvitation(app.selectedTeam, member.id)
    member && setRefreshMembers(refreshMembers + 1)
    toggleMenu(id);
  }, [refreshMembers, JSON.stringify(members)]);
  
  const handleForgotPassword = (id: number) => {
    const member = members.find((member) => member.index === id);
    manageUserPasswordReset(member?.email || "")
    toggleMenu(id);
  };

  const handleRemove = useCallback((id: number) => {
    const member = members.find((member) => member.index === id);
    member && openRemoveFromModal(member.id, member.name, member.email, member.role)
    member && setRefreshMembers(refreshMembers + 1)
    toggleMenu(id);
  }, [ refreshMembers, JSON.stringify(members)]);

  if (loading) {
    return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
      <Loading />
    </div>)
  }

  return (
    <Container>
      <Title bartype="divider" text={`Users (${memberCount})`} size="h2" />
      {members.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              { !showMobile && <th>Role</th> }
              { !showMobile && <th>Status</th> }
              <th></th>
            </tr>
          </thead>
          <tbody>
            {members.map((member) => (
              <tr key={member.index}>
                <td>{member.name}</td>
                <td className="email-cell">{member.displayEmail}</td>
                { !showMobile && <td>{member.role === 'staff' ? 'Staff' : 'Follower'}</td> }
                { !showMobile && <td className={`status ${member.status.toLowerCase()}`}>{member.status}</td> }
                <td>
                   { member.id !== user.id && <div style={{ position: 'relative' }} ref={menuRef}>
                        <OptionsButton onClick={() => toggleMenu(member.index)}/>
                        {showMenu[member.index] && member.status === 'Pending' && <PopUpMenu ref={menuRef}>
                            <MenuItem onClick={() => handleResendEmail(member.index)} style={{color: '#343A40'}}>Resend Invitation</MenuItem>
                            <MenuItem onClick={() => handleCancelInvitation(member.index)} style={{color: 'red'}}>Cancel Invitation</MenuItem>
                        </PopUpMenu>
                        }
                        {showMenu[member.index] && member.status === 'Active' && <PopUpMenu ref={menuRef}>
                            <MenuItem onClick={() => handleForgotPassword(member.index)} style={{color: '#343A40'}}>Reset Password</MenuItem>
                            <MenuItem onClick={() => handleRemove(member.index)} style={{color: 'red'}}>Remove from Team</MenuItem>
                        </PopUpMenu>
                        }
                  </div>}                
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No members found.</p>
      )}
    </Container>
  );
}
