import { useRecoilValue, useResetRecoilState } from "recoil";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { AcceptInvitationFlowPath, RegistrationData } from "@/flows/AcceptInvitationFlow/types";
import { useInvitationCodeService } from "@/flows/AcceptInvitationFlow/services/useInvitationCodeService";
import { AcceptInvitationFlowState } from "./atom.AcceptInvitationFlowState";
import { AcceptInvitationFlowScreenState } from "./selector.AcceptInvitationFlowScreenState";
import { useManagedFlow } from "@/hooks/useManagedFlow";
import { createUserWithEmailAndPassword, updateProfile, User, OAuthProvider, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from '@/libs/firebase';
import { SignupFormState } from "@/forms/SignupForm"
import { useAppState } from "@/state/app/useAppState";
import { useResetForm } from "@/forms/hooks/useResetForm";
import { SampleTeamState } from "./selector.SampleTeam";
import { TeamData } from "@/state/data/useTeams";
import { MergePreviewDataState } from "./selector.MergedPreviewWithRoster";
import { LoginFlowPath } from "@/flows/LoginFlow/types";
import { useLoginFlow } from "@/flows/LoginFlow/state/useLoginFlow";
import { useUserState } from "@/state/user/useUserState";
import { getInvitation } from "@/services/useMemberService/invitations/func.getInvitation";
import { createMember, MemberCreation } from "@/services/useMemberService/members/func.createMember";
import { addClubTeam, removeClubTeam } from "@/services/useMemberService/members/func.addClubTeam";
import { removeFromUsersArray } from "@/services/useMemberService/members/func.removeFromUsersArray";
import { FirestoreInvitation } from "@/services/useMemberService/types.ts/invitation";
import { getMembers } from "@/services/useMemberService/members/func.getMembers";
import { RosterPlayer } from "@/state/data/useTeamRoster/types";
import { firestore } from "@/libs/firebase";
import { doc } from "firebase/firestore";
import { Relationship } from "@/flows/AcceptInvitationFlow/types";
import { setExpiry, acceptInvitation } from "@/services/useMemberService/invitations/func.markInvitation";
import { NewReleases } from "@mui/icons-material";

export function useAcceptInvitationFlow(path: AcceptInvitationFlowPath) {

    const app = useAppState();
    const user = useUserState();
    const navigate = useNavigate();
    const reset = useResetRecoilState(AcceptInvitationFlowState)
    const resetSignupForm = useResetForm('signup')
    const { next, prev, to, state, setState } = useManagedFlow(AcceptInvitationFlowState, path, path === AcceptInvitationFlowPath.MigrateTeam ? 2 : 0)
    const invitationCodeService = useInvitationCodeService();
    const screenState = useRecoilValue(AcceptInvitationFlowScreenState);
    const sampleTeam = useRecoilValue(SampleTeamState);
    const mergedPreview = useRecoilValue(MergePreviewDataState);
    const loginFlow = useLoginFlow(LoginFlowPath.Login);
    const [ invitation, setInvitation ] = useState<FirestoreInvitation | undefined>(undefined);
    const [ confirmPassword, setPassword ] = useState('');
    const hasStaffRole = app.teams?.teamData?.some((team: any) => team?.claim?.role === 'staff');

    useEffect(() => {
        const fetchTeamRoster = async () => {
            try {
                if (invitation?.team?.id) {
                    setState(state => ({ ...state, loading: true }));
                    const team = await invitationCodeService.getTeam(invitation.team.id);
                    setState(state => ({ ...state, invitationTeam: team, loading: false }));
                }
            } catch (error) {
                setState(state => ({ ...state, loading: false }));
                console.error("Error getting documents:", error);
            }
        };
    
        fetchTeamRoster();
    }, [invitation?.team?.id]);

    function generateRandomString(length: number): string {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        
        const unixTimestamp = Math.floor(Date.now() / 1000);
        return result + unixTimestamp;
      }

    const acceptFirebaseInvitationCode = useCallback(async (manualTeamRelationship?: Relationship, newUser?: User, fromReg?: boolean, ssRegister: boolean = false) => {
        const relationship = state.teamRelationship ? state.teamRelationship : manualTeamRelationship
        const previousClubTeam = user?.extraDetails?.clubTeams?.find((teamId: any) => teamId === invitation?.team?.id)
        const clubUser = relationship?.relationship === "admin" ? true : false;

        if (invitation && relationship) {
            if (!clubUser) {
                const member: MemberCreation = {
                    id: newUser ? newUser.uid : user?.id || "",
                    email: newUser ? newUser?.email || "" : user.email || "",
                    name: newUser ? newUser?.displayName || "" : user.displayName || "",
                    claim: invitation?.claim,
                    teamRelationship: relationship,
                };
                await createMember(invitation?.team?.id, member);
                if (previousClubTeam) {
                    removeClubTeam(invitation?.team?.id, user?.id || "");
                }
            } else {
                console.log("Start")
                console.log(invitation?.team?.id, user?.id);
                addClubTeam(invitation?.team?.id, user?.id || newUser?.uid || "");
                removeFromUsersArray(invitation?.team?.id, user?.email || newUser?.email || "");
            }
        }

        if (state.prototeamOptions.strategy === "link" && !clubUser) {
            // Need to merge
            var prototeamId = ""
            var teamId = 0
            if ((sampleTeam?.seasonteams?.length || 0) > 1) {
                // If the invitation has multiple teams, that is the main prototeam.
                // The prototeam you already have will be merged into the team in the invitation.
                prototeamId = sampleTeam?.id || ""
                teamId = parseInt((state.prototeamOptions.linkedTeam?.seasonTeams![0]?.id || "0"), 10)
            } else {
                prototeamId = state.prototeamOptions.linkedTeam?.id || ""
                teamId = sampleTeam?.seasonteams[0]?.id || 0
            }
            const response = await invitationCodeService.mergeTeams(state.prototeamOptions.strategy, teamId, prototeamId)
            if (response.status === 'error') {
                setState(state => ({ ...state, error: response.errors.map((e: any) => e.message).join() }));
                return;
            };
        }

        if (invitation?.claim.role === 'staff' && (fromReg === undefined) && !ssRegister) {
            if (!hasStaffRole) {
                // create account in postgres
                const response: any = await invitationCodeService.createAccount(user.extraDetails.firstName, user.extraDetails.lastName, user?.email || "", confirmPassword, invitation.team.id, user.id || "");
            }
        }

        // Mark invite accepted
        if (invitation?.claim.role === 'staff') {
            // QR Codes don't expire
            // if (invitation.type === 'qr' && !invitation.expiresAt) {
            //     setExpiry(invitation.team.id, invitation.id);
            // }
            if (invitation.type === 'one_time') {
                acceptInvitation(invitation.team.id, invitation.id);
            }
        }

        if (state.invitationType.role === "staff" && (fromReg === undefined) && (previousClubTeam === undefined)) {
            await invitationCodeService.inviteAccepted(invitation?.team?.id || "")
        }

        app.setAcceptedInvite(true)
        await user.Expire();

        // If it's a clubUser invite, redirect them to the Admin Dashboard
        if (clubUser) {
            const otp = await user.GetOtp()
            if (otp) {
                const url = `${app.config.apps.leagueDashboard}/associations?otp=${otp}`
                window.open(url);
            } else {
                const url = `${app.config.apps.leagueDashboard}`
                window.open(url);
            }
        }

        if (!ssRegister) {
            // Trigger flow complete
            app.events.trigger("AcceptInvitationFlow.ExistingUser.Complete")
            app.events.trigger("AcceptInvitationFlow.Complete")
            loginFlow.reset()

            !clubUser && app.setSelectedTeam(invitation?.team?.id || "");
            clubUser && app.setSelectedTeam(app.teams.teamData[0].id);

            // return user back to where they came from
            navigate("/roster"); 
        }
            
    }, [ createMember, JSON.stringify(invitation), JSON.stringify(user), JSON.stringify(user.extraDetails), JSON.stringify(state.teamRelationship), JSON.stringify(sampleTeam), state.prototeamOptions, invitationCodeService.mergeTeams, navigate, confirmPassword, app.config.apps.leagueDashboard ])

    const readPostgresInvitationCode = useCallback(async (code: string) => {

        // get code details
        const response = await invitationCodeService.loadCodeDetails(code)

        // save error
        if (response.status === 'error') {
            return response;
        }

        const schedulerInvite = response.data?.invitation?.roles?.some((role: any) => role.title === "scheduler")
        if (schedulerInvite) {
            // If the invitation is for a scheduler, the user needs to be routed to the admin dashboard.
            return "scheduler";
        }

        // set invitation and continue
        const invitation: FirestoreInvitation = {
            id: code,
            email: "",
            name: "",
            claim: {
                role: "staff",
            },
            type: "permanent",
            status: "pending",
            team: doc(firestore, `teams/${response.data?.teams[0].prototeam_id}`),
        }

        return invitation;

    }, [ invitationCodeService.loadCodeDetails ])

    // const sendToDashboard = useCallback(async () => {
    //     if (state.path === "existingUserPath") {
    //         const otp = await user.GetOtp()
    //         console.log(otp);
    //         if (otp) {
    //             const url = `${app.config.apps.leagueDashboard}/account/invitation?invitation=${invitation?.id}&otp=${otp}`
    //             window.open(url);
    //         } else {
    //             const url = `${app.config.apps.leagueDashboard}/account/new?invitation=${invitation?.id}`
    //             window.open(url);
    //         }
    //     } else if (state.path === "migrateTeamPath") {
    //         const otp = await user.GetOtp()
    //         const url = `${app.config.apps.leagueDashboard}?otp=${otp}`
    //         window.open(url);
    //     } else {
    //         const url = `${app.config.apps.leagueDashboard}/account/new?invitation=${invitation?.id}`
    //         window.open(url);
    //     }
        
    // }, [JSON.stringify(user), app.config.apps.leagueDashboard, JSON.stringify(invitation), JSON.stringify(state)])

    const sendToDashboard = useCallback(async () => {
        
        if (state.path === "existingUserPath") {
            if (!hasStaffRole) {
                // To the confirm password page
                to(6);
            } else {
                await acceptFirebaseInvitationCode();
            }
        } else if (state.path === "migrateTeamPath") {
            await acceptFirebaseInvitationCode();
        } else {
            // newUserPath always goes to registration
            next();
        }

        setState(state => ({ ...state, indirectStaff: true }));
        
    }, [JSON.stringify(user), app.config.apps.leagueDashboard, JSON.stringify(invitation), JSON.stringify(state)])

    // events
    useEffect(() => {
        app.events.add("AcceptInvitationFlow.Complete", reset)
    }, [ reset ])

    return {

        ...state,
        ...screenState,

        get isLoading() {
            const loadingTeam = (state.step === 1 && sampleTeam === null) ? true : false;
            return state.loading || invitationCodeService.isFetching || loadingTeam;
        },

        controls: {
            next,
            prev: useCallback(() => {
                setState(state => ({ ...state, error: "" }))
                prev()
            }, [prev, setState]),
            to
        },

        readFirebaseInviteCode: useCallback(async (id: string) => {
            setState(state => ({ ...state, fInvitation: undefined, invitationTeam: undefined, inviterName: "", error: "" }));
            setInvitation(undefined);
            var invitation: any = undefined;
            var inviteSource: string = "";
        
            // Get invite
            setState(state => ({ ...state, loading: true }));
            invitation = await getInvitation(id);
            if (invitation) {
                setState(state => ({ ...state, inviteSource: "firebase" }))
                inviteSource = "firebase";
            }
        
            // Check if invitation is valid
            if (invitation === undefined) {

                const pgInvitation = await readPostgresInvitationCode(id);

                if (pgInvitation?.status == "error") {
                    setState(state => ({ ...state, loading: false, error: pgInvitation.errors.map((e: any) => e.message).join()}));
                    return;
                } else if (pgInvitation == "scheduler") {
                    to(4);
                    return;
                } else {
                    invitation = pgInvitation;
                    setState(state => ({ ...state, inviteSource: "postgres" }))
                    inviteSource = "postgres";
                }
            }
        
            // Get members
            const members = await getMembers(invitation.team.id);

            if (members !== undefined) {
                // Check if user is already on the team
                const existingMember = members.find(member => member.id && (member.id === user?.id));
        
                if (existingMember) {
                    // If the user is already on the team, are they a follower and this invite is manager
                    if (existingMember.claim.role === 'follower' && invitation.claim.role === 'manager') {
                        // This user can be upgraded to a manager
                        // TODO: Upgrade the user's claims. Will error for now.
                        setState(state => ({ ...state, loading: false, fInvitation: undefined, error: "You're already a follower member of this team. To accept a staff invitation, leave the team and retry with the staff invitation code." }));
                        return;
                    }
                
                    // User is already on the team and their role is equal or higher than the invitation role
                    setState(state => ({ ...state, fInvitation: undefined, loading:false, error: "You're already a member of this team" }));
                    return;
                }
            }

            if (invitation.claim.role !== 'follower') {

                if ((invitation.type === "one_time") && (invitation.status !== "pending")) {
                    setState(state => ({ ...state, fInvitation: undefined, loading: false, error: "This invitation has expired" }));
                    return;
                }

                // QR Codes don't expire
                // if ((invitation.type === "qr") && (invitation.expiresAt ? (new Date(invitation.expiresAt).getTime() < (Date.now())) : false)) {
                //     setState(state => ({ ...state, fInvitation: undefined, loading:false, error: "This invitation has expired" }));
                //     return;
                // }

            }
    
            // Set the flow state to have the required invitation data
            setInvitation(invitation);
            setState(state => ({ ...state,
                invitationType: invitation.claim,
                inviterName: invitation.name,
            }));
        
            if (inviteSource === "firebase") {
                next();
            } else to(2);

            setState(state => ({ ...state, loading: false }));
        
        }, [ setState, getInvitation, next, getMembers, user?.id, reset, JSON.stringify(state) ]),

        previewNext: useCallback(() => {
            to(3);
        }, [to]),

        toPreview: useCallback(() => {
            if (state.inviteSource === "firebase") {
                to(1);
            } else to(2);
        }, [to, state.inviteSource]),
        
        toStart: useCallback(() => {
            to(0);;
        }, [to]),

        setRelationship: useCallback((relationship: string, relatedPlayers?: RosterPlayer[]): void => {
            setState(state => ({ ...state, indirectStaff: true }));
            if (relatedPlayers) {
                setState(state => ({ ...state, teamRelationship: { relationship, relatedPlayers } }));
            } else {
                setState(state => ({ ...state, teamRelationship: { relationship } }));
            }
            if (invitation?.claim.role == "follower") {
                if (state.path === "newUserPath") {
                    // Send to registration
                    to(5);
                } else {
                    // Send to phone number page if user has no number, otherwise accept invitation
                    if (user.phoneNumber == "" && relationship !== 'fan' && relationship !== 'other') {
                        to(5);
                    } else {
                        acceptFirebaseInvitationCode({relationship, relatedPlayers});
                    }
                }
            } else if (invitation?.claim.role == "staff") {
                if (relationship === "admin") {
                    // Send to dashboard v3 registration
                    if (state.path === "migrateTeamPath") {
                        to(1);
                    } else {
                        to(4);
                    }
                } else if (state.path === "newUserPath") {
                    // Send to registration page
                    to(5);
                } else if (state.path === "migrateTeamPath") {
                    // Send to phone number page if user has no number, then send to merge options.
                    if (user.phoneNumber == "") {
                        to(2);
                    } else {
                        acceptFirebaseInvitationCode({relationship});
                    }
                } else {
                    // Send to phone number page if user has no number, then send to merge options.
                    if (relationship !== 'fan' && relationship !== 'other' && user.phoneNumber == "") {
                        to(5);
                    } else if ((!app.teams?.teamData?.some((team: any) => team?.claim?.role === 'staff')) && confirmPassword === '' ) {
                        // User is accepting their first staff role
                        to(6);
                    } else {
                        if (state.inviteSource === "postgres") {
                            to(7);
                        } else acceptFirebaseInvitationCode({relationship});
                    }
                }
            }
        }, [setState, to, acceptFirebaseInvitationCode, state.path, invitation?.claim.role, user.phoneNumber, state.inviteSource ]),

        backToTeamRelationship: useCallback(() => {
            if (state.path === "migrateTeamPath") {
                to(0);
            } else {
                to(3);
            }
        }, [to]),

        resetFlow: useCallback(() => {
            reset();
            to(0);
        }, [reset, to]),

        setTeamInvitation: useCallback((teamId: string) => {

            const invitation: FirestoreInvitation = {
                id: "",
                email: "",
                name: "",
                claim: {
                    role: "staff",
                },
                type: "permanent",
                status: "pending",
                team: doc(firestore, `teams/${teamId}`),
            }

            setInvitation(invitation);
            setState(state => ({ ...state,
                invitationType: invitation.claim,
                inviterName: invitation.name,
            }));

        }, [ setState ]),

        setPasswordConfirmation: useCallback(async (password: string) => {

            try {
                setState(state => ({ ...state, loading: true, error: ""}));
                await user.ReauthenticateUser(password);
            } catch (error) {
                setState(state => ({ ...state, loading: false, error: "Password is incorrect" }));
                return;
            }

            setState(state => ({ ...state, loading: false, error: ""}));
            setPassword(password);
            if (state.inviteSource === "postgres") {
                to(7);
            } else acceptFirebaseInvitationCode();

        }, [ setPassword, to, state.inviteSource, acceptFirebaseInvitationCode ]),

        UpdateFirebaseDetails: useCallback( async ( details: Partial<{firstName: string, lastName: string, phoneNumber: string, displayName: string}>, buttonClick?: boolean) => {

            setState(state => ({ ...state, loading: true }));
            await user.UpdateFirebaseDetails(details);

            if (buttonClick) {
                if (state.path === "migrateTeamPath") {
                    acceptFirebaseInvitationCode();
                    setState(state => ({ ...state, loading: false }));
                } else if (state.path === "newUserPath") {
                    app.events.trigger("AcceptInvitationFlow.ExistingUser.Complete")
                    app.events.trigger("AcceptInvitationFlow.Complete")
                    app.setAcceptedInvite(true)
                    user.Expire();
                    loginFlow.reset()
                    app.setSelectedTeam(invitation?.team?.id || "");
                    app.loading.unload('newSignup')
                    navigate("/roster");
                    setState(state => ({ ...state, loading: false }));
                } else if (!hasStaffRole) {
                    to(6);
                    setState(state => ({ ...state, loading: false }));
                } else {
                    if (state.inviteSource === "postgres") {
                        to(7);
                    } else acceptFirebaseInvitationCode();
                    setState(state => ({ ...state, loading: false }));
                }
            }

        }, [JSON.stringify(user), next, JSON.stringify(state.path), setState, state.inviteSource, acceptFirebaseInvitationCode ]),

        previewMerge: useCallback(async () => {

            // start preview merge request
            const teamId = sampleTeam?.seasonteams[0].id
            const prototeamId = state.prototeamOptions.linkedTeam?.id

            if (!teamId || !prototeamId) {
                return;
            }

            const response = await invitationCodeService.loadMergePreview(teamId, prototeamId)
            // save error
            if (response.status === 'error') {
                setState(state => ({ ...state, mergedPreview: undefined, error: response.errors.map((e: any) => e.message).join() }));
                return
            }

            // set mergedPreview and continue
            setState(state => ({ ...state, rawMergedData: response.data["preview-invitation"] }));
            next();

        }, [next, setState, invitationCodeService.loadMergePreview, JSON.stringify(sampleTeam), state.prototeamOptions]),

        acceptFirebaseInvitationCode,

        registerNewAccount: useCallback(async (formdata: SignupFormState) => {
            
            //check it valid
            for (const field of Object.values(formdata)) {
                if (!field.value.match(field.test)) {
                    return;
                }
            }

            const data: RegistrationData = {
                firstName: formdata.firstName.value,
                lastName: formdata.lastName.value,
                email: formdata.email.value,
                password: formdata.password.value,
                phoneNumber: formdata.phoneNumber.value,
            }

            app.loading.complete('revalidatingUser')
            setState(state => ({ ...state, loading: true }))
            let newUser = undefined;

            // create firebaser account
            try {
                const userCredential = await createUserWithEmailAndPassword(auth, data.email, data.password);
                newUser = userCredential.user;
            } catch (error: any) {
                if (error.code == "auth/email-already-in-use") {
                    setState({ ...state, loading: false, error: "Email is already in use." })
                    app.loading.unload('revalidatingUser')
                } else {
                    setState({ ...state, loading: false, error: "Error creating account." })
                    app.loading.unload('revalidatingUser')
                }
            }

            newUser && await updateProfile(newUser, { displayName: `${data.firstName} ${data.lastName}` })
            newUser && await user.UpdateFirebaseDetails({firstName: data.firstName, lastName: data.lastName, phoneNumber: data.phoneNumber, displayName: `${data.firstName} ${data.lastName}`}, newUser.uid);

            if (invitation?.claim.role === 'staff') {
                // create account in postgres
                const response: any = await invitationCodeService.createAccount(data.firstName, data.lastName, data.email, data.password, invitation.team.id, newUser!.uid);
                if (!response.ok) {
                        setState(state => ({ ...state, error: "Error creating account", loading: false}))
                        app.loading.unload('revalidatingUser')
                        return;
                };
            }

            newUser && await acceptFirebaseInvitationCode(undefined, newUser, true);
            // await user.SignOut();
            // await user.SignIn(data.email, data.password);

            app.events.trigger("AcceptInvitationFlow.NewUser.Complete")
            app.events.trigger("AcceptInvitationFlow.Complete")
            await user.Expire();

            resetSignupForm()
            reset()
            navigate("/roster")
            setState(state => ({ ...state, loading: false }))
            app.loading.unload('revalidatingUser')

        }, [setState, resetSignupForm, invitationCodeService.setFirebaseUserId, JSON.stringify(user), acceptFirebaseInvitationCode, resetSignupForm, navigate, JSON.stringify(invitation)]),

        handleSSOLogin: useCallback(async (provider: string) => {
            app.loading.complete('newSignup');
            try {
                const ssoProvider = provider === 'google' ? new GoogleAuthProvider() : new OAuthProvider('apple.com');
                const result = await signInWithPopup(auth, ssoProvider);
                console.log('Google Login Success:', result.user);

                setState(state => ({ ...state, loading: true }));
                const newUser = result.user;
                const displayName = newUser.displayName || "";
                const firstName = displayName.substring(0, displayName.indexOf(' '));
                const lastName = displayName.substring(displayName.indexOf(' ') + 1);
                
                newUser && await updateProfile(newUser, { displayName: newUser.displayName || ""})
                newUser && await user.UpdateFirebaseDetails({firstName: firstName, lastName: lastName, phoneNumber: "", displayName: newUser.displayName || ""}, newUser.uid);

                if (invitation?.claim.role === 'staff') {
                    // create account in postgres
                    const randomPass = generateRandomString(15);
                    const response: any = await invitationCodeService.createAccount(firstName, lastName, newUser.email || "", randomPass, invitation.team.id, newUser!.uid);
                    if (!response.ok) {
                            setState(state => ({ ...state, error: "Error creating account", loading: false}))
                            app.loading.unload('revalidatingUser')
                            return;
                    };
                }
                await user.Expire()

                newUser && await acceptFirebaseInvitationCode(undefined, newUser, true, true);
                setState(state => ({ ...state, loading: false }));
                if (state.teamRelationship?.relationship !== "admin" && state.teamRelationship?.relationship !== "fan" && state.teamRelationship?.relationship !== "other") {
                    to(6);
                } else {
                    window.location.reload();
                }
            } catch (error) {
                app.loading.unload('newSignup');
                setState(state => ({ ...state, error: "Error logging in with Google", loading: false }));
            }
        }, [ setState, JSON.stringify(user), acceptFirebaseInvitationCode, resetSignupForm, to, JSON.stringify(invitation)]),

        setStrategy: (strategy: "link" | "add" | "") => {
            setState(s => ({ ...s, strategy }))
        },

        setLinkedTeam: (team: TeamData | undefined) => {
            setState(state => ({ ...state, linkedTeam: team }))
        },

        sendToDashboard,

        sampleTeam,

        mergedPreview,

        strategy: state.prototeamOptions.strategy,

    }

}