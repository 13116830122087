import { QueryConstraint, QuerySnapshot, collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { firestore as db } from "../../../libs/firebase";

export const listMembers = (teamId: string, onMembers: (snapshot: QuerySnapshot) => Promise<void>) => {
    
    const clauses:QueryConstraint[] = [
        // no conditions    
    ]
    
    const members = collection(db, `teams/${teamId}/members`);
    return onSnapshot(query(members,...clauses), onMembers);

}