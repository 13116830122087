import { Button, Title, WhistleIcon } from "@gamesheet/ui";
import { useEffect, useMemo, useState, ChangeEvent } from "react";
import { useAppState } from "@/state/app/useAppState";
import { styled } from "styled-components";
import tearDropFace from "@/assets/images/tear_drop_face.svg";
import { PasswordInput } from "@/forms/components/PasswordInput";
import { NewFormField, useCreateForm } from "@/forms/hooks/useCreateForm";

const DesktopPasswordConfirmation = styled.div`
    font-size: 18px;
    line-height: 23.7px;

    .header {
        font-weight: 700;
        padding-top: 20px;
    }

    .body {
        font-weight: 400;
        font-size: 16px;
        line-height: 22.75px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .input {
        padding-top: 20px;
        padding-bottom: 30px;
    }

    .tos {
        font-size: 14px;
        font-color: #404247;
        line-height: 16.59px;
    }
`

const MobilePasswordConfirmation = styled.div`
    font-size: 18px;
    line-height: 23.7px;

    .header {
        font-weight: 700;
        padding-top: 20px;
    }

    .body {
        font-weight: 400;
        font-size: 16px;
        line-height: 22.75px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .input {
        padding-top: 20px;
        padding-bottom: 30px;
    }

    .tos {
        font-size: 14px;
        font-color: #404247;
        line-height: 16.59px;
    }
`

const Emoji = styled.div`
  background: url(${tearDropFace}) no-repeat center;
  background-size: contain;
  width: 80px;
  height: 80px;
`;

const Error = styled.div`
  margin-top: 10px;
  padding-left: 4px;
  color: red;
`;

type PasswordConfirmationProps = {
    onSubmit: (password: string) => Promise<void>;
    onBack: () => void;
    register: boolean;
    error?: string;
}

export function PasswordConfirmation({ onBack, onSubmit, register, error }:PasswordConfirmationProps){
    const app = useAppState()
    const StyledPasswordConfirmation = (app.layout.showTablet || app.layout.showDesktop) ? DesktopPasswordConfirmation : MobilePasswordConfirmation;

    const { state: formState, onChange } = useCreateForm('passwordConfirmation', {
        password: NewFormField('', /.{8,}/, 'Password must be at least 8 characters'),
    })

    const BtnBack = useMemo(() => <Button type="button" size="lg" style={register ? {} : {marginLeft: '16px', height: '36px', marginBottom: '-100px'}} variant="inverted" onClick={onBack}>Back</Button>, [ onBack ])
    useEffect(() => app.ui('leftTray').set(BtnBack, app.layout.showMobile), [ BtnBack, app.layout.showMobile ])

    const BtnNext = useMemo(() => <Button type="button" size="lg" style={register ? {} : {marginRight: '16px', height: '36px', marginBottom: '-100px'}} variant={(formState?.password?.value?.length >= 8) ? "" : "muted"} disabled={formState?.password?.value?.length < 8} onClick={() => onSubmit(formState?.password?.value || "")}>Continue</Button>, [ onSubmit, formState?.password?.valid, formState?.password?.value ])
    useEffect(() => app.ui('rightTray').set(BtnNext, app.layout.showMobile), [ BtnNext, app.layout.showMobile ])

    return <div>

        <StyledPasswordConfirmation>
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '28px' }}><Emoji /></div>
            <div className="header">It looks like you don't have a staff account set up yet.</div>
            <div className="body">As you are accepting your first staff invitation, you need to confirm your password.</div>
            <PasswordInput state={formState?.password} name="password" label="Password" onChange={onChange} autocomplete={false} />
            { error !== '' && <Error>{error}</Error> }
        </StyledPasswordConfirmation>
        
        { (app.layout.showTablet || app.layout.showDesktop) && <>
            <hr/>
            <div style={{ display:'flex', justifyContent:'space-between' }}>
                {BtnBack}
                {BtnNext}
            </div>
        </>}

    </div>

}