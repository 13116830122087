import { useCallback, useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { useAppState } from "@/state/app/useAppState";
import { useUserState } from "@/state/user/useUserState";
import { removeMember } from "@/services/useMemberService/members/func.removeMember";

type RemoveFromTeamModal = {
  loading: boolean;
  isOpen: boolean;
  userId: string;
  userName: string;
  email: string;
  role: string;
};

export const removeFromTeamModalState = atom<RemoveFromTeamModal>({
  key: "removeFromTeamModalState",
  default: {
    loading: false,
    isOpen: false,
    userId: "",
    userName: "",
    email: "",
    role: ""
  },
});

export function useRemoveFromTeam() {
  const app = useAppState();
  const user = useUserState();
  const [{ loading, isOpen, userId, userName, email, role }, setState] = useRecoilState(removeFromTeamModalState);

  const open = useCallback((memberId: string, memberName: string, email: string, role: string) => {
    setState((prev) => ({
      ...prev,
      isOpen: true,
      userId: memberId || "",
      userName: memberName || "",
      email: email || "",
      role: role || ""
    }));
    document.body.style.overflow = "auto";
  }, [setState]);

  const close = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: false,
      memberId: "",
      memberName: "",
    }));

    document.body.style.overflow = "auto";
  }, [setState]);

  const setLoading = useCallback((loading: boolean) => {
    setState((prev) => ({
      ...prev,
      isOpen: !loading ? false : prev.isOpen,
      loading,
    }));
  }, [setState]);

  const sendEmail = useCallback(async (teamId: string, email: string, team_title: string, user_name: string, user_role: string) => {
    const requestData = {
      attributes: {
        event: "send-removed-email",
        schema: "user-team-info",
        version: "v1",
      },
      data: {
        team_title: team_title,
        user_name: user_name,
        email: email,
        user_role: user_role
      },
    };

    const url = `${app.config.gateways.eventLog}/v5/teams/${teamId}/send-removed-email`;
    const headers = new Headers();

    try {
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      return response;
    } catch (error) {
      console.error("Error while sending invitation emails", error);
    }
  }, [app.config.gateways.eventLog]);

  const onRemoveFromTeam = async () => {
    const team = app.teams.teamData.find((team: any) => team.id === app.selectedTeam);
    setLoading(true);
    if (app.selectedTeamClaim?.role === 'staff') {
      const url = `${app.config.gateways.eventLog}/v5/users/${userId}/remove-prototeam`;
      const headers = new Headers();
      user.isLoggedIn && headers.set("Authorization", `Bearer ${user.tokens.access}`);

      const requestData = {
        attributes: {
          event: "remove-prototeam",
          schema: "user-prototeam",
          version: "v1",
        },
        data: {
          userId: userId,
          prototeamId: app.selectedTeam,
        },
      };

      try {
        const response = await fetch(url, {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        });

        if (response.ok) {
          setState((prev) => ({
            ...prev,
            loading: false,
            isOpen: false,
          }));
        } else {
          setLoading(false);
          console.error("Error while updating team data:", response);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error while updating team data:", error);
      }
    }
    if (userId) {
      await removeMember(app.selectedTeam, userId);
      await sendEmail(app.selectedTeam, email, team.title, userName, role);
      setLoading(false);
    }
  };

  return {
    loading,
    open,
    close,
    isOpen,
    onRemoveFromTeam,
    userName,
  };
}
