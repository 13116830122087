import { atom, selector } from "recoil";
import { NavigationItem } from "@/components/pure/NavButton";
import { useNestedApp } from "./useNestedApp";
import { MemberClaim } from "@/services/useMemberService/types.ts/member";

export type AppUiLocations = "leftTray" | "rightTray" | "actionTray";

export type AppState = {
    selectedTeam: string|undefined,
    tempSelectedTeam: {title: string, logo: string},
    selectedTeamClaim: MemberClaim|undefined,
    updateAvailable: boolean,
    acceptedFirstInvite: boolean,
    inviteAccepted: boolean,
    eventsChanged: boolean,
    lastChange: number,
    loaded: string[]
    nestedApps: {
        [key: string]: ReturnType<typeof useNestedApp>
    },
    teams: {
        count: number,
        checkedOnLogin: boolean,
        teamData: any,
        unmigratedTeams?: any,
    },
    menu: {
        isOpen: boolean,
    },
    ui: {
        [key in AppUiLocations]: JSX.Element | undefined;
    },
    navigation: NavigationItem[]
    unleashContext: {
        [key: string]: string;
    };
    unreadMessages: number;
}

export const appState = atom<AppState>({
    key: "appState",
    default: {
        selectedTeam: undefined,
        tempSelectedTeam: {title: '', logo: ''},
        selectedTeamClaim: undefined,
        updateAvailable: false,
        acceptedFirstInvite: false,
        inviteAccepted: false,
        eventsChanged: false,
        lastChange: 0,
        loaded: [],
        nestedApps: {},
        teams: {
            count: 0,
            checkedOnLogin: false,
            teamData: [],
        },
        menu: {
            isOpen: false,
        },
        ui: {
            leftTray: undefined,
            rightTray: undefined,
            actionTray: undefined,
        },
        navigation: [],
        unleashContext: {},
        unreadMessages: 0,
    },
})

// This a little bit of a hack.
// If we want app.selectedTeam in a selector, we have to use get(appState), but then the selector will re-run every time any value in appState changes.
// If we only care about app.selectedTeam, we can use this selector that won't trigger a downstream re-run everytime appState changes, just when selectedTeam does.
export const selectedPrototeamIdState = selector({
    key: 'selectedPrototeamIdState',
    get: ({get}) => {
        const app = get(appState)
        return app.selectedTeam
    }
})