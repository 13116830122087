import { selector } from "recoil";
import { AcceptInvitationFlowState } from "./atom.AcceptInvitationFlowState";
import { ConvertInvitationPlayerToRosterPlayer } from "../lib/ConvertInvitationPlayerToRosterPlayer";

export const SampleTeamState = selector({
    key: 'SampleTeamState',
    get: ({ get }) => {
        const state = get(AcceptInvitationFlowState);

        const team = state.invitationTeam;
        if (!team) {
            return null;
        }

        return {
            ...team
        }
    }
})