import { firestore } from "@/libs/firebase";
import { doc, updateDoc } from "firebase/firestore";

export const acceptInvitation = (teamId: string, id:string) => {
    const ref = doc(firestore, `teams/${teamId}/invitations/${id}`);
    return updateDoc(ref, { status: "accepted" });
}

export const rejectInvitation = (teamId: string, id:string) => {
    const ref = doc(firestore, `teams/${teamId}/invitations/${id}`);
    return updateDoc(ref, { status: "rejected" });
}

export const setExpiry = (teamId: string, id:string) => {
    const expiresAt = Date.now() + 24 * 60 * 60 * 1000;
    const ref = doc(firestore, `teams/${teamId}/invitations/${id}`);
    return updateDoc(ref, { expiresAt });
}