import { Config } from "./type";

export const config:Config = {
    name: "",
    settings: {
        showDumpStateButton: false,
    },
    features: {

    },
    apps: {
        statsWidget: "https://gamesheetstats.com",
        leagueDashboard: "https://gamesheet.app",
    },
    gateways: {
        data: "/gateway/data",
        events: "/gateway/events",
        auth: "/gateway/auth",
        eventLog: "/gateway/eventlog",
        lookups: "gateway/lookups",
    },
    services: {
        bffApi: "/api", // functions folder
        assetsApi: "/service/assets",
        registryApi: "/service/registrysync",
        scoresheetExportApi: "/service/scoresheets",
        unleashApi: "https://features.gamesheet.io/api/frontend",
    },
    // gateways: {
    //     data: "https://gateway-data-rxj26aywsq-nn.a.run.app",
    //     events: "https://gateway-events-rxj26aywsq-nn.a.run.app",
    //     auth: "https://gateway-authserver-rxj26aywsq-nn.a.run.app",
    //     eventLog: "https://gateway-eventlog-rxj26aywsq-nn.a.run.app",
    //     lookups: "https://lookups.gamesheet.io"
    // },
    // services: {
    //     bffApi: "/api",
    //     assetsApi: "https://assetsapi-rxj26aywsq-nn.a.run.app",
    //     registryApi: "https://registrysync-v4-rxj26aywsq-nn.a.run.app",
    //     scoresheetExportApi: "https://scoresheet-service-rxj26aywsq-nn.a.run.app",
    //     unleashApi: "https://features.gamesheet.io/api/frontend",
    // },
    firebase: {},
    unleash: {},
}