import { firestore } from "@/libs/firebase";
import { getDocs, query, collection } from "firebase/firestore";
import { FirestoreInvitation } from "@/services/useMemberService/types.ts/invitation";

export const getAllInvitations = async (teamId: string): Promise<FirestoreInvitation[]> => {
  try {
    // Create a query against the collection "invitations"
    const teamsRef = collection(firestore, "teams", teamId, "invitations");

    // Execute the query
    const querySnapshot = await getDocs(teamsRef);

    // Define an array of type FirestoreInvitation[]
    const invitations: FirestoreInvitation[] = [];

    // Check if any documents were found
    if (!querySnapshot.empty) {
      for (const docSnap of querySnapshot.docs) {
        const invitation = docSnap.data() as FirestoreInvitation;
        invitations.push(invitation);
      }
      return invitations;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error getting document:", error);
    return [];
  }
};
