import { firestore } from "@/libs/firebase";
import { getDocs, collection } from "firebase/firestore";
import { FirestoreMember } from "@/services/useMemberService/types.ts/member";

export const getMembers = async (teamId: string): Promise<FirestoreMember[] | undefined> => {
  try {
    // Create a reference to the members collection
    const membersRef = collection(firestore, "teams", teamId, "members");

    // Execute the query
    const querySnapshot = await getDocs(membersRef);

    // Map the documents to the FirestoreMember type
    const members: FirestoreMember[] = querySnapshot.docs.map(doc => ({
      ...doc.data(),
    } as FirestoreMember));

    // Check if any documents were found
    if (members.length > 0) {
      return members;
    } else {
      console.log("No such document!");
      return undefined;
    }
  } catch (error) {
    console.error("Error getting documents:", error);
    return undefined;
  }
};
