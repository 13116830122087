import logoPlaceholder from "@/assets/images/blank_jersey.svg"
import { Button, FormWrapper, Title } from "@gamesheet/ui";
import { useCallback, useEffect, useMemo } from "react";
import { useAppState } from "@/state/app/useAppState";
import { styled } from "styled-components";
import { useTeamsData } from "@/state/data/useTeams";
import { Menu, DropdownMenu } from "@gamesheet/ui"
import { InsetTextBlock } from "./components/InsetTextBlock";
import { PrototeamOption } from "./components/PrototeamOption";
import { usePrototeamOptionsState } from "./state/usePrototeamOptionsState";
import { InvitationTeam } from "./types";
import { TeamData } from "@/state/data/useTeams";
import { SeasonTeam } from "./types";

const DropdownContainer = styled(InsetTextBlock)`

.dd-container {
    width: 100%;

    .button {
        text-align: left;

        svg {
            float: right;
        }
    }

    .dd-menu {
        left: 0;
        max-height: 250px;

        .menu {
            width: 100%;
            max-width: 100%;
            max-height: calc(250px - 0.1rem);
            overflow-y: auto;

            .menu-item {
                position: relative;
                padding-left: 73px;

                img {
                    position: absolute;
                    top: 4px;
                    left: 12px;
                    width: 48px;
                    height: 48px;
                    object-fit: contain;
                }
            }
        }
    }
}`

type PrototeamOptionsProps = {
    title: string;
    invitationTeams: SeasonTeam[];
    register: boolean;
    onAccept: () => void;
    onBack: () => void;
}

function getSelectableTeams(teams: TeamData[], invitationTeams: SeasonTeam[]) {
    
    // Cannot merge teams that have season teams in the same season
    const _selectableTeams = teams.filter(team => !team.seasonTeams.some(
        seasonTeam => invitationTeams.some(
            invitationTeam => seasonTeam.season.id === invitationTeam.season.id)));
      
    var selectableTeams: TeamData[] = [];

    for (const team of _selectableTeams) {
        if (team.seasonTeams.length > 1 && invitationTeams.length > 1) {
            // Cannot merge a team when they both have mulitple teams
            continue;
        } else if (team.claim?.role === 'follower') {
            // Cannot merge a team you are a follower of
            continue
        } else  {
            selectableTeams.push(team);
        }
    }

    return selectableTeams;
}

export function PrototeamOptions({ title, invitationTeams, register, onBack, onAccept }: PrototeamOptionsProps) {

    const app = useAppState()
    const { teams, getPlaysIn } = useTeamsData()
    const selectableTeams = getSelectableTeams(teams, invitationTeams);
    const { options: { strategy, linkedTeam }, setStrategy, setLinkedTeam } = usePrototeamOptionsState();
    const isLinkSelected = strategy === "link";
    const dropdownState = isLinkSelected ? undefined : "disabled";
    const isAcceptDisabled = (isLinkSelected && !linkedTeam) || (strategy === "default");
    const variant = isAcceptDisabled ? "muted" : "";
    
    const onClickAccept = useCallback(() => {
        onAccept()
    }, [onAccept])

    const BtnBack = useMemo(() => <Button type="button" size="lg" variant="inverted" style={register ? {} : {marginLeft: '16px', height: '36px', marginBottom: '-100px'}} onClick={onBack}>Back</Button>, [onBack])
    useEffect(() => app.ui('leftTray').set(BtnBack, app.layout.showMobile), [BtnBack, app.layout.showMobile])

    const nextText = isLinkSelected ? "Continue" : (app.layout.showTablet || app.layout.showDesktop) ? "Accept Invitation" : "Accept";
    const BtnNext = useMemo(() => <Button type="button" size="lg" style={register ? {} : {marginRight: '16px', height: '36px', marginBottom: '-100px'}} variant={variant} disabled={isAcceptDisabled} onClick={onClickAccept}>{nextText}</Button>, [onClickAccept, isAcceptDisabled, variant, nextText])
    useEffect(() => app.ui('rightTray').set(BtnNext, app.layout.showMobile), [BtnNext, app.layout.showMobile])

    return <div>

        <Title bartype="plain" text="" size="h5">
            <InsetTextBlock>How would you like to add {title} to your account?</InsetTextBlock>
        </Title>

        <FormWrapper>

            { selectableTeams.length !== 0 && <PrototeamOption
                id="link"
                selectedId={strategy}
                onClick={setStrategy as (id: string) => void}
                name="mergeTeamOption"
                text={`Link ${title} to an existing team`}
            /> }
            { selectableTeams.length !== 0 && <DropdownContainer>
                <DropdownMenu
                    disabled={!isLinkSelected}
                    label={linkedTeam?.title || "Select Team"}
                    variant="inverted"
                    state={dropdownState}
                    style={{ display: 'block', width: '100%' }}
                >
                    {selectableTeams.map(team => <Menu.Item key={team.id} label={team.title} onClick={() => setLinkedTeam(team)}>
                        <img src={team.logo ? `${team.logo}/256` : logoPlaceholder} />
                        Plays in {getPlaysIn(team.id)}
                    </Menu.Item>)}
                </DropdownMenu>
            </DropdownContainer> }
            { selectableTeams.length !== 0 && <InsetTextBlock style={{ fontSize: "0.85rem", fontWeight: "500", lineHeight: ".9rem", marginBottom: "2.5rem", marginTop: "-10px" }}>
                <p>Select this option to link {title} to an existing team and merge the rosters.</p>
            </InsetTextBlock> }

            <PrototeamOption
                id="add"
                selectedId={strategy}
                onClick={setStrategy as (id: string) => void}
                name="mergeTeamOption"
                text={`Add ${title} without linking to an existing team`}
            />
            <InsetTextBlock style={{ fontSize: "0.85rem", fontWeight: "500", lineHeight: ".9rem", marginTop: "5px" }}>
                Select this option to add {title} as a new team. They will appear as a new team
                in your dashboard with the logo and roster shown on the previous page.{" "}
                <a href="https://help.gamesheet.app/article/50-accepting-an-invitation-code-as-an-existing-user">More Info</a>
            </InsetTextBlock>

        </FormWrapper>

        { (app.layout.showTablet || app.layout.showDesktop) && <>
            <hr />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {BtnBack}
                {BtnNext}
            </div>
        </>}

    </div>

}