import { Button } from "@gamesheet/ui";
import { ErrorCard } from "@/components/pure/EntityCard/MobileEntityCard";
import { FormEvent, forwardRef, MouseEvent, useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { EditUserForm, EditUserFormState } from "@/forms/EditUserForm";
import { EditPasswordForm, EditPasswordFormState } from "@/forms/EditPasswordForm";
import { PageLoading } from "@/components/pure/PageLoading";
import { useAppState } from "@/state/app/useAppState";
import { Navigate } from "react-router-dom";
import { useAppNavigation } from "@/hooks/useAppNavigation";
import { usePageState } from "@/state/page/usePageState";
import { useUserState } from "@/state/user/useUserState";
import { UserUpdate } from "@/services/useUserService";
import { FormFieldType, NewFormField, useCreateForm } from "@/forms/hooks/useCreateForm";
import { emailRegex } from "@/forms/SignupForm";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 92%;
  margin: 0 auto;
  overflow-y: scroll;
`;

const PasswordStyle = styled.div`
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  color: blue;
  cursor: pointer;

  button {
    width: 100%;
    height: 3rem;
  }
`;

const ButtonDiv = styled.div`
  margin-top: auto;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  margin-bottom: 25px;

  button {
    width: 100%;
    height: 3rem;
    background-color: #fafafa;
    color: #36383D;
    border: 2px solid #36383D;
  }
`;

export function UserEdit() {
    const app = useAppState();
    const user = useUserState();
    const [loading, setLoading] = useState(false);
    const { navigate, destination, hasNavigation, handleNavigation } = useAppNavigation();
    const [error, setError] = useState("");
    const [ showSave, setShowSave ] = useState(false);
    const [ emailChanged, setEmailChanged ] = useState(false);
    const [ canChangePassword, setCanChangePassword ] = useState(false);

    const [userDetails, setUserDetails] = useState<UserUpdate>({
        id: user.id || "",
        firstName: user.extraDetails.firstName,
        lastName: user.extraDetails.lastName,
        displayName: user.displayName || "",
        phoneNumber: user.extraDetails.phoneNumber || "",
        email: user.email || "",
        password: "",
    });
    const [loadingUser, setLoadingUser] = useState(true);
    const [editUser, setEditUser] = useState(true);

    useEffect(() => {
        async function fetchData() {
        try {
            const data = await user.GetExtraDetails();
            setUserDetails({ ...userDetails, firstName: data.firstName, lastName: data.lastName });
            setLoadingUser(false);
        } catch (error) {
            setLoadingUser(false);
        }
        }

        if (user.extraDetails.loaded === true) {
          if (user.extraDetails.firstName === "") {
              fetchData();
          } else {
              setUserDetails({ ...userDetails, phoneNumber: user.extraDetails.phoneNumber });
              setLoadingUser(false);
          }
        }
    }, [JSON.stringify(user)]);

    const { state: formState, onChange } = useCreateForm('editUser', {
        firstName: NewFormField((userDetails?.firstName || ""), /.{2,}/, 'First Name must be at least 2 characters'),
        lastName: NewFormField((userDetails?.lastName || ""), /.{2,}/, 'Last Name must be at least 2 characters'),
        displayName: NewFormField((user?.displayName || ""), /.{2,}/, 'Display Name must be at least 2 characters'),
        phoneNumber: NewFormField((userDetails?.phoneNumber || ""), /^(\+)?\d*$/, 'Please enter a valid phone number'),
        email: NewFormField((user.email || ""), emailRegex, 'Email must be valid'),
        confirmPassword: NewFormField((""), /.{0,}/, 'Please enter your password'),
    })

    const { state: passwordFormState, onChange: onChangePassword } = useCreateForm('editPassword', {
        currentPassword: NewFormField('', /.{1,}/, 'Password field is empty'),
        newPassword: NewFormField('', /.{8,}/, 'Password must be at least 8 characters'),
    })

    const onSaveUser = useCallback(async (userForm: EditUserFormState, revalidateUser: boolean) => {
        try {
        setLoading(true);
        if (userForm.email?.value !== userDetails.email) {
            if (userForm.confirmPassword?.value === "") {
            setError("Please enter your password to change your email");
            setLoading(false);
            return;
            }
        }
        const response = await user.UpdateUser(
            {
            id: user.id || "",
            firstName: userForm.firstName.value,
            lastName: userForm.lastName.value,
            displayName: userForm.displayName.value,
            phoneNumber: userForm.phoneNumber.value,
            email: userForm.email.value.toLowerCase(),
            password: "",
            },
            userForm.confirmPassword.value,
            revalidateUser
        );

        if (response == "User details updated successfully" || response === undefined) {
            setError("");
            setLoading(false);
            navigate(`/`);
        }
        } catch (error: any) {
        console.error("Error while updating user data:", error);
        setError(error);
        setLoading(false);
        }
    }, []);

    const onSavePassword = useCallback(async (passwordForm: EditPasswordFormState, userInfo: UserUpdate) => {
        try {
        setLoading(true);
        const response = await user.UpdatePassword(passwordForm.currentPassword.value, {
            id: userInfo.id,
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            displayName: userInfo.displayName,
            phoneNumber: userInfo.phoneNumber,
            email: userInfo.email.toLowerCase(),
            password: passwordForm.newPassword.value,
        });
        if (response == "Password updated successfully") {
            navigate(`/`);
            setError("");
            setLoading(false);
        }
        } catch (error: any) {
        console.error("Error while updating password:", error);
        setError(error);
        setLoading(false);
        }
    }, []);

    const handleChangeForm = () => {
        setError("");
        setEditUser(!editUser);
    };

    // Edit user form
    useEffect(() => {
        if(formState){
            (setEmailChanged(formState.email?.value !== userDetails.email))
        }
    }, [ userDetails.email, formState?.email?.value ])

    useEffect(() => {
        if(formState && editUser){
            setShowSave((formState.email?.value !== userDetails.email) || (formState.lastName?.value !== userDetails.lastName) || (formState.firstName?.value !== userDetails.firstName) || (formState.displayName?.value !== userDetails.displayName) || (formState.phoneNumber?.value !== userDetails.phoneNumber))
            console.log(showSave)
        } else if (passwordFormState && !editUser) {
            setShowSave((passwordFormState.currentPassword?.value.length >= 1) && (passwordFormState.newPassword?.value.length >= 8))
        }
    },[ JSON.stringify(formState), JSON.stringify(userDetails), JSON.stringify(passwordFormState), editUser ])

    const handleSubmitUser = useCallback((e:MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>) => {
        e && e.preventDefault();
        
        for(const field of Object.values(formState)){
            if(!field.value.match(field.test)){
                return;
            }
        }
        onSaveUser(formState, emailChanged);
    }, [ onSaveUser, JSON.stringify(formState), emailChanged ])

    const handleCancel = useCallback(() => {
        if (editUser) {
        navigate(`/`);
        } else {
        handleChangeForm();
        }
    }, [navigate, editUser, handleChangeForm]);

    // Password form
    const handleSubmitPassword = useCallback((e:MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>) => {
        e && e.preventDefault();
        
        for(const field of Object.values(passwordFormState)){
            if(!field.value.match(field.test)){
                return;
            }
        }
        
        onSavePassword(passwordFormState, userDetails);
    }, [ onSavePassword, JSON.stringify(passwordFormState) ])

    const handleUpdateProfile = useCallback(() => {
        if (editUser){
            for(const field of Object.values(formState)){
                if(!field.value.match(field.test)){
                    return;
                }
            }
            onSaveUser(formState, emailChanged);
        } else {
            for(const field of Object.values(passwordFormState)){
                if(!field.value.match(field.test)){
                    return;
                }
            }
            
            onSavePassword(passwordFormState, userDetails);
        }
    }, [editUser, handleSubmitPassword, handleSubmitUser])

  if (hasNavigation) {
    return <Navigate to={destination} />;
  }

  if (loading || loadingUser) {
    return <PageLoading />;
  }

  return (
    <Container>
      {editUser && (
        <div>
          <EditUserForm onSubmit={handleSubmitUser} emailChanged={emailChanged} formState={formState} onChange={onChange} app={app}/>
          { user?.providerDetails?.some((provider: any) => provider.providerId === 'password') && <PasswordStyle>
            <Button type="button" gs-style="outline" variant="inverted" size="lg" onClick={handleChangeForm}>
              Change Password
            </Button>
          </PasswordStyle>}
        </div>
      )}
      {!editUser && (
        <div>
          <EditPasswordForm onSubmit={handleSubmitPassword} passwordFormState={passwordFormState} onChange={onChangePassword} />
        </div>
      )}
      {error !== "" && (
        <div style={{ width: "100%", marginTop: "20px" }}>
          <ErrorCard title="" message={error} />
        </div>
      )}
      <ButtonDiv>
        <Button type="button" gs-style="solid" variant={showSave ? "" : "muted"} disabled={!showSave} size="lg" style={{marginBottom: '1rem'}} onClick={handleUpdateProfile}>Update Profile</Button>
        <Button type="button" gs-style="solid" variant="inverted" size="lg" onClick={handleCancel}>Cancel</Button>
      </ButtonDiv>
    </Container>
  );
}
