import React, { useCallback, useState } from 'react';
import { Button, Title, WhistleIcon } from "@gamesheet/ui";
import { useAppState } from "@/state/app/useAppState";
import { styled } from "styled-components";
import { BreakPoints } from '@/libs/breakpoints';
import logoutIcon from "@/assets/images/logout.png";
import { TextInput } from '@/forms/components/TextInput';
import { FormFieldType, NewFormField, useCreateForm } from '@/forms/hooks/useCreateForm';
import { useUserState } from '@/state/user/useUserState';
import { getInvitation } from "@/services/useMemberService/invitations/func.getInvitation";
import { getMembers } from "@/services/useMemberService/members/func.getMembers";
import { useInvitationCodeService } from '@/flows/AcceptInvitationFlow/services/useInvitationCodeService';
import { ErrorCard } from "@/components/pure/EntityCard/MobileEntityCard";
import { FirestoreInvitation } from '@/services/useMemberService/types.ts/invitation';
import { firestore } from '@/libs/firebase';
import { doc } from 'firebase/firestore';

const DesktopJoinTeam = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  line-height: 23.7px;
  padding: 20px;
  padding-top: 25px;
  min-height: 100vh;
  position: relative;

  .header {
    font-weight: 700;
    padding-top: 20px;
  }

  .body {
    font-weight: 400;
    font-size: 16px;
    line-height: 22.75px;
    padding-top: 20px;
  }

  .input {
    padding-top: 20px;
    padding-bottom: 30px;
  }
`;

const MobileJoinTeam = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  line-height: 23.7px;
  padding: 20px;
  padding-top: 25px;
  min-height: 100vh;

  .header {
    font-weight: 700;
    padding-top: 20px;
  }

  .body {
    font-weight: 400;
    font-size: 16px;
    line-height: 22.75px;
    padding-top: 20px;
  }

  .input {
    padding-top: 20px;
    padding-bottom: 30px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 20px;
`;

const BottomButtons = styled.div`
  width: calc(100%);
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 40px;
  margin-bottom: 20px;
`;

const StyledTitle = styled(Title)`
  font-size: 24px;
  font-weight: bold;
`;

const BorderButton = styled.div`
  display: flex;
  padding: 0.5rem 0 0.5rem 0;
  justify-content: center;
  align-items: center;
  gap: 0.9375rem;
  cursor: pointer;
  border-radius: 0.3125rem;
  border: 3px solid #36383D;
  color: #36383D;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
  height: 48px;
  font-size: 18px;
  font-weight: 600;

  img {
    width: 1rem;
    height: 1rem;
  }

  @media only screen and (max-width: ${BreakPoints.lg}) {
    border: 3px solid #36383D;
  }

`

const IntroText = styled.div`
  font-size: 18px;
  margin-bottom: 20px;
  position: absolute;
  top: 85px;
  display: flex;
  width: calc(100% - 40px);
`;

export type JoinTeamFormState = {
  invitationCode: FormFieldType<string>;
};

export function JoinTeam() {
  const app = useAppState();
  const user = useUserState();
  const invitationCodeService = useInvitationCodeService();
  const StyledJoinTeam = app.layout.showTablet || app.layout.showDesktop ? DesktopJoinTeam : MobileJoinTeam;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const hasError = error !== "";

  const { state: formState, onChange } = useCreateForm("joinTeam", {
    invitationCode: NewFormField("", /.{10,}/, "Invalid invitation code"),
  });

  const handleBack = () => {
    app.navigate('/club-user')
  }

  const handleLogOut = () => {
    user.SignOut();
  }

  const readPostgresInvitationCode = useCallback(async (code: string) => {

    // get code details
    const response = await invitationCodeService.loadCodeDetails(code)

    // save error
    if (response.status === 'error') {
        return response;
    }

    const schedulerInvite = response.data?.invitation?.roles?.some((role: any) => role.title === "scheduler")
    if (schedulerInvite) {
        // If the invitation is for a scheduler, the user needs to be routed to the admin dashboard.
        return "scheduler";
    }

    const invitation: FirestoreInvitation = {
      id: code,
      email: "",
      name: "",
      claim: {
          role: "staff",
      },
      type: "permanent",
      status: "pending",
      team: doc(firestore, `teams/${response.data?.teams[0].prototeam_id}`),
  }

    return invitation;

}, [ invitationCodeService.loadCodeDetails, formState?.invitationCode?.value ])

  const handleJoinTeam = useCallback(async () => {
      
      var invitation: any = undefined;
      const inviteCode = formState?.invitationCode?.value;

      // Get invite
      // Set loading
      setLoading(true);

      invitation = await getInvitation(inviteCode);
      console.log(invitation)
  
      // Check if invitation is valid
      if (invitation === undefined) {

          const pgInvitation = await readPostgresInvitationCode(inviteCode);

          if (pgInvitation?.status == "error") {
              // invitation is invalid
              setError("Invitation not found");
              setLoading(false);
              return;
          } else if (pgInvitation == "scheduler") {
              // invitation is scheduler
              setError("Scheduler invitations must be accepted on the Admin Dashboard");
              setLoading(false);
              return;
          } else {
              // inviation is good
              invitation = pgInvitation;
          }
      }
  
      // Get members
      const members = await getMembers(invitation.team.id);

      if (members !== undefined) {
          // Check if user is already on the team
          const existingMember = members.find(member => member.id && (member.id === user?.id));
  
          if (existingMember) {
              // If the user is already on the team, are they a follower and this invite is manager
              if (existingMember.claim.role === 'follower' && invitation.claim.role === 'manager') {
                  // You are already a member of this team
                  setError("You are already a member of this team");
                  setLoading(false);
                  return;
              }
          
              // You are already a member of this team
              setError("You are already a member of this team");
              setLoading(false);
              return;
          }
      }

      if (invitation.claim.role !== 'follower') {

          if ((invitation.type === "one_time") && (invitation.status !== "pending")) {
              // Invitation has expired
              setError("Invitation has expired");
              setLoading(false);
              return;
          }

      }

      // Send to the accept invitation flow
      app.navigate(`/invitation?invitation=${inviteCode}`);
      setLoading(false);
  
    }, [ getInvitation, getMembers, user?.id, formState?.invitationCode?.value ]);

  return (
    <div>
      <StyledJoinTeam>
        <StyledTitle text="Join a Team" bartype="divider" size="h2" />
        <IntroText> 
          To use GameSheet Teams you’ll need to join a team. If you have a team invitation code, enter it below. 
        </IntroText>

        <div style={{top: '190px', position:'absolute', width: 'calc(100% - 40px)'}}>
          <TextInput state={formState?.invitationCode || ""} name="invitationCode" label="Invitation Code" onChange={onChange} autocomplete={false} />
        </div>

        { hasError &&  <div style={{marginTop: '50px'}}><ErrorCard title={"Bad Invitation Code"} message={error} /></div>}

        <Container>
          <div style={{ fontSize: '20px', fontWeight: '600' }}>Don’t have an invitation code?</div>
          <div>Contact your league/event administrator or team manager and request an invitation to your team.</div>

          <BottomButtons>
            <Button style={{ height: '48px', fontSize: '18px', marginBottom: '10px' }} variant={formState?.invitationCode?.valid ? '' : 'muted'} disabled={!formState?.invitationCode?.valid} onClick={handleJoinTeam}>Join Team</Button>
            <BorderButton onClick={handleLogOut}>
                  <img src={logoutIcon} alt="logout" />
                  LOG OUT
              </BorderButton>
            <Button style={{ height: '48px', fontSize: '18px', marginBottom: '0px' }} variant="inverted" onClick={handleBack}>Back</Button>
          </BottomButtons>
        </Container>
      </StyledJoinTeam>
    </div>
  );
}
