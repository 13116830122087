import React from "react";
import { Title, TeamRelationshipCard, WhistleIcon, FoamFingerIcon, BlankJerseyIcon, HouseIcon, BinocularIcon, EyeIcon } from "@gamesheet/ui";
import styled from "styled-components";

type PrototeamOptionProps = {
  onClick: (id: string) => void;
  staff: boolean;
  icon: string;
  text: string;
  selected: boolean;
  style?: React.CSSProperties;
};

export function TeamRelationshipOption({ onClick, icon, text, staff, selected, style }: PrototeamOptionProps) {
  let iconSVG;

  switch (icon) {
    case "FoamFingerIcon":
      iconSVG = <FoamFingerIcon selected={selected}/>;
      break;
    case "BlankJerseyIcon":
      iconSVG = <BlankJerseyIcon selected={selected}/>;
      break;
    case "HouseIcon":
      iconSVG = <HouseIcon selected={selected}/>;
      break;
    case "BinocularIcon":
      iconSVG = <BinocularIcon selected={selected}/>;
      break;
    case "WhistleIcon":
      iconSVG = <WhistleIcon selected={selected}/>;
      break;
    case "EyeIcon":
      iconSVG = <EyeIcon selected={selected}/>;
      break;
    default:
      iconSVG = null;
  }

  return (
      <TeamRelationshipCard
        onClick={() => onClick("some-id")}
        icon={iconSVG}
        selected={selected}
        staff={staff}
        text={text}
        style={{boxShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.25)', ...style}}
      />
  );
}
