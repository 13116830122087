import { firestore } from "@/libs/firebase";
import { getDocs, query, collectionGroup, where } from "firebase/firestore";
import { FirestoreInvitation } from "@/services/useMemberService/types.ts/invitation";

export const getInvitation = async (id: string): Promise<any> => {
  try {
    // Create a query against the collection group "invitations"
    const invitationsRef = collectionGroup(firestore, "invitations");
    const q = query(invitationsRef, where("id", "==", id));

    // Execute the query
    const querySnapshot = await getDocs(q);

    // Check if any documents were found
    if (!querySnapshot.empty) {
      const docSnap = querySnapshot.docs[0]; // Get the first matching document
      const invitationData = docSnap.data() as FirestoreInvitation;
      return invitationData;
    } else {
      return undefined;
    }
  } catch (error) {
    console.error("Error getting document:", error);
    return undefined;
  }
};
