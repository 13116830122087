// CoachForm.tsx
import { FormWrapper, Title } from "@gamesheet/ui";
import "@gamesheet/ui/build/index.css";
import { CoachFormData } from "@/state/data/useCoachData";
import React, { useState } from "react";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { selectedTeamSportCoachPositionsOptionsData } from "@/state/data/useSeasonTeams";

type CoachFormProps = {
  formData: CoachFormData;
  setFormData: React.Dispatch<React.SetStateAction<CoachFormData>>;
  useLineupConfig?: boolean;
};

const DisabledStylingOverride = styled.div`
  .form-floating > .form-control:not(:placeholder-shown):disabled ~ label::after {
    background-color: inherit;
  }

  select:disabled {
    border-color: #ced4da !important;
  }
`

const CoachForm = ({ formData, setFormData, useLineupConfig }:CoachFormProps) => {

  const positionOptions = useRecoilValue(selectedTeamSportCoachPositionsOptionsData);
  const [ fNameTestResult, setFNameTestResult ] = useState("")
  const [ lNameTestResult, setLNameTestResult ] = useState("")


  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    
    if (name === "firstName" || name === "lastName") {
      if (!value.match(/.{2,}/)) {
        name === "firstName" ? setFNameTestResult("invalid") : setLNameTestResult("invalid")
      } else {
        name === "firstName" ? setFNameTestResult("valid") : setLNameTestResult("valid")
      }
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  //TODO list: Convert birthdate to correct format for db
  // Stylign for birthday section
  // Add validation for required fields
  // Jersey number comparison across team
  // Status value is a string here but it should be a boolean in the DB

  return (
    <FormWrapper>
      <DisabledStylingOverride>
      <fieldset>
        <div className="form-floating mb-3">
          <input type="text" name="firstName" id="firstName" className={"form-control " + "is-" + fNameTestResult} placeholder="First Name" onChange={handleChange} value={formData.firstName} disabled={useLineupConfig} />
          <label htmlFor="firstName" className="floating">
            { fNameTestResult === "invalid" ? "First name must be at least 2 characters" : "First Name*" }
          </label>
        </div>

        <div className="form-floating mb-3">
          <input type="text" name="lastName" id="lastName" className={"form-control " + "is-" + lNameTestResult} placeholder="Last Name" onChange={handleChange} value={formData.lastName} disabled={useLineupConfig} />
          <label htmlFor="lastName" className="floating">
            { lNameTestResult === "invalid" ? "First name must be at least 2 characters" : "Last Name*" }
          </label>
        </div>

        <div className="form-floating mb-3">
          <select name="position" id="position" className="form-select" onChange={handleChange} value={formData.position} disabled={useLineupConfig}>
            <option value="">None</option>
            {positionOptions.map(option => (
              <option key={option.key} value={option.key}>
                {option.title}
              </option>
            ))}
          </select>

          <label htmlFor="position" className="floating">
            Position
          </label>
        </div>

      </fieldset>
      </DisabledStylingOverride>
    </FormWrapper>
  );
};

export default CoachForm;
