import config from "@/config";
import dayjs from "@/libs/dayjs";
import { useUserService } from "@/services/useUserService";
import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { useAppState } from "../app/useAppState";
import { getOtp } from "./getOtp";
import { useSetGameSheetUserState } from "./useGameSheetUserState";
import { useLoginWithOtp } from "./useLoginWithOtp";
import { firebaseUserState, useSetFirebaseAuth } from "./useSetFirebaseAuth";
import { useMembersState } from "../members/useMembersState";
import { setDoc, doc } from 'firebase/firestore';
import { firestore } from "@/libs/firebase";

export const userState = atom({
    key: 'userState',
    default: {
        tokens: {
            access: "",
            refresh: "",
            roles: "",
        }
    },
})

export type ExtraUserDetails = {
    claims: {
        title: string;
        level: {
            id: string;
            type: string;
        }
    }[],
    email: string;
    firstName: string;
    fuid: string;
    id: number;
    lastName: string;
    phoneNumber: string;
    clubTeams: string[];
    maintenance: {
        refreshTeamManagerRoles: boolean;
    },
    resetCode: string;
    loaded: boolean;
}

export const extraUserDetailsState = atom<ExtraUserDetails>({
    key: 'extraUserDetailsState',
    default: {
        claims: [],
        email: "",
        firstName: "",
        fuid: "",
        id: 0,
        lastName: "",
        phoneNumber: "",
        clubTeams: [],
        maintenance: {
            refreshTeamManagerRoles: false,
        },
        resetCode: "",
        loaded: false,
    },
})

export type UserType = {
    tokens: {
        access: string;
        refresh: string;
        roles: string;
    }
}

export function useSetUserState(){

    useSetFirebaseAuth()
    useLoginWithOtp()
    useSetGameSheetUserState()

}

export type UserState = ReturnType<typeof useUserState>
export function useUserState() {

    const app = useAppState()
    const members = useMembersState()

    const firebaseUser = useRecoilValue(firebaseUserState)
    const user = useRecoilValue(userState)
    const resetFirebaseUserState = useResetRecoilState(firebaseUserState);
    const resetUserState = useResetRecoilState(userState);
    const resetAllUserState = () => {resetUserState(); resetFirebaseUserState();}
    const [extraDetails, setExtraDetails] = useRecoilState(extraUserDetailsState);
    const { UpdatePassword, UpdateUser, SignIn, SignOut, GetUserEmailSubscriptions, GetClaims, CheckPermissions, GetLineupSubscriptions, GetUserFirebaseDetails, UpdateFirebaseDetails, ReauthenticateUser } = useUserService(user, firebaseUser, resetAllUserState)
    const [ phoneNumber, setPhoneNumber ] = useState("")
    const [ providerDetails, setProviderDetails ] = useState<any>(null)

    const Expire = useCallback(async () => {
        return fetch(`${config.gateways.auth}/auth/v4/expire-user`, { 
            method: "POST", 
            headers: { 
                Authorization: `Bearer ${user.tokens.access}` 
            } 
        })
    }, [user.tokens.access, config.gateways.auth])

    useEffect(() => {
        app.setUnleashContext("userId", firebaseUser?.email || "", true)
    }, [firebaseUser?.email]);

    useEffect(() => {

        if (firebaseUser){
            GetUserFirebaseDetails(firebaseUser.uid).then((userData) => {
                setPhoneNumber(userData?.phoneNumber || phoneNumber)
                setExtraDetails(u => ({...u, firstName: userData?.firstName || u.firstName, lastName: userData?.lastName || u.lastName, phoneNumber: userData?.phoneNumber || u.phoneNumber, clubTeams: userData?.clubTeams || u.clubTeams, loaded: true}))
            })
            setProviderDetails(firebaseUser.providerData)
        }

    }, [ firebaseUser?.uid ])

    return {
        id: firebaseUser?.uid,
        displayName: firebaseUser?.displayName,
        email: firebaseUser?.email,
        phoneNumber,
        photoURL: firebaseUser?.photoURL,
        tokens: user.tokens,
        isLoggedIn: !!firebaseUser,
        isRecentSignup: !!firebaseUser && dayjs().diff(dayjs(firebaseUser?.metadata.creationTime), "seconds") < 3,
        extraDetails,
        providerDetails,

        SignIn,

        SignOut,
        
        GetToken: () => {
            return user.tokens.access
        },
        
        GetOtp: async () => {
            return getOtp(user.tokens.access);
        },
    
        GetExtraDetails: async () => {
            const response = await fetch(`${config.gateways.auth}/auth/v4/user`, { 
                method: "GET",
                headers: { 
                    Authorization: `Bearer ${user.tokens.access}` 
                } 
            })
            if (response.ok) {
                const data = await response.json();
                setExtraDetails((prev) => ({...prev, ...data.user}));
                return data.user as ExtraUserDetails;
            }

            return extraDetails;
        },

        ActiveMember(){
            return members.getMemberByEmail(firebaseUser?.email || "")
        },

        GetUserFirebaseDetails,
        UpdateFirebaseDetails,
        UpdatePassword,
        UpdateUser,
        GetUserEmailSubscriptions,
        GetLineupSubscriptions,
        Expire,
        GetClaims,
        CheckPermissions,
        ReauthenticateUser
    }

}