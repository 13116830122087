import { useResetRecoilState, useSetRecoilState } from "recoil"
import { auth, firestore } from "@/libs/firebase"
import { reauthenticateWithCredential, EmailAuthProvider, User, setPersistence, browserLocalPersistence, signInWithEmailAndPassword, signOut } from "firebase/auth"
import config from "@/config";
import { useAppState } from "../../state/app/useAppState";
import { UserType, extraUserDetailsState } from "../../state/user/useUserState";
import { decodeToken } from "react-jwt";
import { isAllowedOn, Resource, Record, Claim, Level, Levels, Action } from "@gamesheet/tspkg-auth-client"
import { getDoc, doc, setDoc } from 'firebase/firestore';
import { getAuth, updateEmail, updatePassword, updateProfile } from "firebase/auth";
import { useCallback } from "react";


export type UserUpdate = {
    id: string;
    firstName: string;
    lastName: string;
    displayName: string;
    phoneNumber: string;
    email: string;
    password: string;
    socialSignUp?: boolean;
}

export function useUserService(user: UserType, firebaseUser: User | undefined, resetState: () => void){

    const app = useAppState()
    const events = app.events
    const setExtraUserDetails = useSetRecoilState(extraUserDetailsState);
    const resetExtraUserDetails = useResetRecoilState(extraUserDetailsState);

    const SignIn = async (email: string, password: string) => {
            
        try {
            
            await setPersistence(auth, browserLocalPersistence);
            await signInWithEmailAndPassword(auth, email, password);

        } catch (error: any) {
            
            switch (error.code) {
                case "auth/requires-recent-login": throw "Please logout then back in first to refresh authentication"
                case "auth/wrong-password": throw "Email or password is incorrect"
                case "auth/user-not-found": throw "Email or password is incorrect"
                case "auth/too-many-requests": throw "Too many login attempts, please try again later"
            }
            throw error.message

        }
    }

    const SignOut = async () => {
        await signOut(auth)
        resetExtraUserDetails();
        resetState();
        events.trigger("user:signout", user)
        window.location.reload();
    }

    const quietSignOut = async () => {
        await signOut(auth)
        resetExtraUserDetails();
    }

    const ReauthenticateUser = async (password: string) => {
        if (password !== "") {
            const credential = EmailAuthProvider.credential(
                firebaseUser?.email || "",
                password
            );
    
            if (!firebaseUser) {
                throw "No user is logged in";
            }
    
            // Reauthenticate the user
            await reauthenticateWithCredential(firebaseUser, credential);
        }
    }

    const UpdateUserViaPostgres = async (updatedUser: UserUpdate, confirmPassword: string, revalidateUser: boolean) => {
        const body = {
            firstName: updatedUser.firstName,
            lastName: updatedUser.lastName,
            email: updatedUser.email,
            password: updatedUser.password,
        };

        try {

            if (revalidateUser) {
                await ReauthenticateUser(confirmPassword);
            }

            // Use the formatted body in the fetch request and await the response
            const response = await fetch(`${config.gateways.auth}/auth/v4/account`, {
                method: "POST",
                body: JSON.stringify(body), // Use the formatted body
                headers: {
                    Authorization: `Bearer ${user.tokens.access}`,
                    "Content-Type": "application/json",
                },
            });

            if (response.ok) {
                setExtraUserDetails((prev) => ({
                    ...prev,
                    email: updatedUser.email,
                    firstName: updatedUser.firstName,
                    lastName: updatedUser.lastName,
                }));

                if (updatedUser.password == "") {  // This is for signing the user back in after changing email
                    updatedUser.password = confirmPassword;
                }
                // User details updated successfully, so you can call SignIn
                if (revalidateUser){
                    app.loading.complete('revalidatingUser')
                    await quietSignOut();
                    await SignIn(updatedUser.email, updatedUser.password);
                    app.loading.unload('revalidatingUser')
                }
                return "User details updated successfully";
            } else {
                // Handle error response
                app.loading.unload('revalidatingUser')
                throw "Failed to update user details";
            }
        } catch (error: any) {
            if (revalidateUser){
                app.loading.unload('revalidatingUser')
            }

            switch (error.code) {
                case "auth/user-mismatch":
                    throw "Something went wrong. Please refresh the page and try again";
                case "auth/user-not-found":
                    throw "Something went wrong. Please refresh the page and try again";
                case "auth/invalid-email":
                    throw "Something went wrong. Please refresh the page and try again";
                case "auth/invalid-credential":
                    throw "Password is incorrect";
                case "auth/wrong-password":
                    throw "Password is incorrect";
                case "auth/too-many-requests":
                    throw "Too many failed attempts, please try again later";
                default:
                    throw error.message;
            }
        }
        
    };

    const UpdateFirebaseDetails = useCallback(async (updatedUser: (Partial<UserUpdate>), userId?: string) => {

            const userDocRef = doc(firestore, 'users', userId ? userId : firebaseUser?.uid || "");
            const userUpdates: { [key: string]: any } = {};
            if (updatedUser.firstName !== undefined) userUpdates.firstName = updatedUser.firstName;
            if (updatedUser.lastName !== undefined) userUpdates.lastName = updatedUser.lastName;
            if (updatedUser.phoneNumber !== undefined) userUpdates.phoneNumber = updatedUser.phoneNumber;

            await setDoc(userDocRef, userUpdates, { merge: true });

            if (updatedUser.displayName && firebaseUser) {
                await updateProfile(firebaseUser, {
                    displayName: updatedUser.displayName,
                }).catch((error) => {
                    console.error("Error updating display name:", error);
                });
            }

    }, [JSON.stringify(firebaseUser)])

    const UpdateUserViaFirebase = async (updatedUser: UserUpdate, confirmPassword: string, revalidateUser: boolean) => {
        // Update email (and password if revaidateUser)
        if (firebaseUser && revalidateUser) {
            if (updatedUser.password === "") {
                await updateEmail(firebaseUser, updatedUser.email)
                .then(() => {
                    app.loading.complete('revalidatingUser')
                    quietSignOut();
                    SignIn(updatedUser.email, confirmPassword);
                    app.loading.unload('revalidatingUser')
                })
                .catch((error) => {
                    switch (error.code) {
                        case "auth/user-mismatch":
                            throw "Something went wrong. Please refresh the page and try again";
                        case "auth/user-not-found":
                            throw "Something went wrong. Please refresh the page and try again";
                        case "auth/invalid-email":
                            throw "Something went wrong. Please refresh the page and try again";
                        case "auth/invalid-credential":
                            throw "Password is incorrect";
                        case "auth/wrong-password":
                            throw "Password is incorrect";
                        case "auth/too-many-requests":
                            throw "Too many failed attempts, please try again later";
                        default:
                            throw error.message;
                    }
                });
            } else {
                const credential = EmailAuthProvider.credential(updatedUser.email, confirmPassword);
                try {
                    await reauthenticateWithCredential(firebaseUser, credential);
                  } catch (error: any) {
                    throw "Incorrect Password";   
                }

                updatePassword(firebaseUser, updatedUser.password)
                .then(() => {
                    app.loading.complete('revalidatingUser')
                    quietSignOut();
                    SignIn(updatedUser.email, updatedUser.password);
                    app.loading.unload('revalidatingUser')
                    return "User details updated successfully";
                }).catch((error) => {
                    if (revalidateUser){
                        app.loading.unload('revalidatingUser')
                    }

                    switch (error.code) {
                        case "auth/user-mismatch":
                            throw "Something went wrong. Please refresh the page and try again";
                        case "auth/user-not-found":
                            throw "Something went wrong. Please refresh the page and try again";
                        case "auth/invalid-email":
                            throw "Something went wrong. Please refresh the page and try again";
                        case "auth/invalid-credential":
                            throw "Password is incorrect";
                        case "auth/wrong-password":
                            throw "Password is incorrect";
                        case "auth/too-many-requests":
                            throw "Too many failed attempts, please try again later";
                        default:
                            throw error.message;
                    }
                });
            }
        }
        return "User details updated successfully";
    }

    const UpdateUser = async (updatedUser: UserUpdate, confirmPassword: string, revalidateUser: boolean) => {
        await UpdateFirebaseDetails(updatedUser);
        if (user.tokens.access) {
            return await UpdateUserViaPostgres(updatedUser, confirmPassword, revalidateUser);
        } else {
            return await UpdateUserViaFirebase(updatedUser, confirmPassword, revalidateUser);
        }
    }

    const GetUserEmailSubscriptions = async () => {
        if (app.teams.teamData.some((team: any) => team.claim?.role === 'staff')) {
            try {
                const response = await fetch(`${config.gateways.data}/email-subscriptions/v4?filter[firebaseUserId]=${firebaseUser?.uid}&filter[updatedAfter]=${Date.now()}`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${user.tokens.access}`,
                        "Content-Type": "application/json",
                    },
                });
                if (response.ok){
                    return await response.json();
                }
            } catch (error) {
                
            }
        }
    }

    const GetUserFirebaseDetails = async (userId: string) => {
        try {
            const userDocRef = doc(firestore, 'users', userId);
            const userDoc = await getDoc(userDocRef);
      
            if (userDoc.exists()) {
                return userDoc.data();
            } else {
                console.log('No such document!');
                return null;
            }
        } catch (error) {
            console.error('Error getting user document:', error);
            throw error;
        }
      };

    const GetLineupSubscriptions = async () => {
        if (app.teams.teamData.some((team: any) => team.claim?.role === 'staff')) {
            try {
            const response = await fetch(`${config.gateways.data}/lineup-subscriptions/v4?filter[firebaseUserId]=${firebaseUser?.uid}&filter[updatedAfter]=${Date.now()}`, {
                method: "GET",
                headers: {
                Authorization: `Bearer ${user.tokens.access}`,
                "Content-Type": "application/json",
                },
            });
            if (response.ok) {
                return await response.json();
            }
            } catch (error) {
                console.log("error getting lineup subscriptions", error);
            }
        }
    };
    

    const UpdatePassword =  async (password: string, updatedUser: UserUpdate) => {
        try {
            const response = await UpdateUser(updatedUser, password, true);
            if (response == "User details updated successfully") {
                // User details updated successfully
                return "Password updated successfully";
            } else {
                // Handle error response
                throw response;
            }
        } catch (error: any) {
            throw error;
        }
    };

    const GetClaims =  () => {
        const token = user.tokens.roles;
        const decoded: any = decodeToken(token);
        
        const claimsList: Claim[] = (decoded?.roles || []).map((roleItem: any) => ({
            title: roleItem.title,
            level: {
                type: roleItem.level.type as Level,
                id: parseInt(roleItem.level.id, 10), // assuming id is a number
            },
        }));
        return claimsList;
    };

    const CheckPermissions = async (resource: Resource, actions: Action[]) => {
        
        const claims = GetClaims();
        const selectedTeam = app.teams.teamData.find((team: any) => team.id === app.selectedTeam);

        const record: Record = {
            [Levels.ASSOCIATIONS]: selectedTeam?.seasonTeams[0]?.association.id,
            [Levels.LEAGUES]: selectedTeam?.seasonTeams[0]?.league.id,
            [Levels.SEASONS]: selectedTeam?.seasonTeams[0]?.season.id,
            [Levels.DIVISIONS]: selectedTeam?.seasonTeams[0]?.division.id,
            [Levels.TEAMS]: selectedTeam?.seasonTeams[0]?.id,
        }

        return isAllowedOn({[resource]: actions }, claims, record);

    };

    return {

        SignIn,
        SignOut,
        UpdateUser,
        UpdatePassword,
        GetUserEmailSubscriptions,
        GetClaims,
        CheckPermissions,
        ReauthenticateUser,
        GetUserFirebaseDetails,
        GetLineupSubscriptions,
        UpdateFirebaseDetails
    }

}