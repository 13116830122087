import React, { useEffect, useState } from "react";
import { Button, Loading, Modal } from "@gamesheet/ui";
import { styled } from "styled-components";
import { useAppState } from "@/state/app/useAppState";
import { InviteUserFlowPath, InviteUserFlowStep } from "./types";
import { useInviteUserFlow } from "./state/useInviteUserFlow"; 
import { InviteFollower } from "./step.inviteFollower";
import { SelectRole } from "./step.selectRole";
import { InviteStaff } from "./step.inviteStaff";
import { useInviteUserService } from "./services/useInviteUserService";
import { MemberClaim } from "@/services/useMemberService/types.ts/member";
import { InvitationType, Invitation, FirestoreInvitation } from "@/services/useMemberService/types.ts/invitation";
import { getAllInvitations } from "@/services/useMemberService/invitations/func.getAllInvitations";
import { useUserState } from "@/state/user/useUserState";
import { firestore } from "@/libs/firebase";
import { doc } from "firebase/firestore";

const ModalContainer = styled.div<{ $show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5200;
  display: ${(props) => (props.$show ? 'block' : 'none')};
`;

export function InviteUserFlow() {

  const app = useAppState();
  const flow = useInviteUserFlow(InviteUserFlowPath.InviteUser);
  const { isOpen, close, loading, onSubmit, handleAddInvitation, getInvitations, userEmail } = useInviteUserService(flow.controls.to);
  const [ codesLoading, setCodesLoading ] = useState(true);
  const [ modalTitle, setModalTitle ] = useState("Select A Role");
  const [ followerInvitation, setFollowerInvitation ] = useState<Invitation>();
  const [ staffQRInvitation, setStaffQRInvitation ] = useState<Invitation>();
  const staff = app.selectedTeamClaim?.role === 'staff';

  useEffect(() => {
    if (!!followerInvitation && (!!staffQRInvitation || !staff)) {
      setCodesLoading(false);
    } else {
      setCodesLoading(true);
    }

  }, [ JSON.stringify(followerInvitation), JSON.stringify(staffQRInvitation) ]);

  useEffect(() => {
    const fetchInvitations = async () => {
      const {followerInvitation, staffQRInvitation} = await getInvitations();

      if (followerInvitation) setFollowerInvitation(followerInvitation);
      if (staffQRInvitation && staff) setStaffQRInvitation(staffQRInvitation);
    }

    if ( app.selectedTeam && (!followerInvitation || (!staffQRInvitation && staff))) {
      fetchInvitations();
    }

  }, [app.selectedTeam, isOpen, JSON.stringify(followerInvitation), JSON.stringify(staffQRInvitation), staff]);

  const onClose = () => {
    !loading && close();
    !loading && flow.controls.to(0);
  }

  const handleSubmit = (emails: string, type: MemberClaim, invitationType: InvitationType, invitation?: Invitation) => {
    invitation ? onSubmit(emails, type, invitationType, invitation) : onSubmit(emails, type, invitationType);
    onClose();

  }

  useEffect(() => {
    switch (flow.step) {
      case InviteUserFlowStep.InviteFollower:
        setModalTitle("Invite Team Follower");
        break;
      case InviteUserFlowStep.InviteStaff:
        setModalTitle("Invite Team Staff");
        break;
      default:
        setModalTitle("Select A Role");
    }
  }, [flow.step]);

  useEffect(() => {

    if (!staff && (flow.step === InviteUserFlowStep.SelectRole) && isOpen) {
      flow.controls.to(1);
    }

  }, [ staff, flow.step, isOpen ]);

  useEffect(() => {
    if (userEmail !== "") {
      if (flow.step === InviteUserFlowStep.SelectRole) {
        flow.controls.to(2);
      }
    }

  }, [ userEmail ])

  return (
    <ModalContainer $show={isOpen}>
      <Modal title={modalTitle} show={isOpen} onClose={onClose}>
        {(loading || codesLoading) && <div style={{ width: '100%', minWidth: '250px',display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loading /></div>}

        { flow.step === InviteUserFlowStep.SelectRole && (!loading && !codesLoading) &&
          <SelectRole onSelect={flow.controls.to}/>
        }

        { flow.step === InviteUserFlowStep.InviteStaff && (!loading && !codesLoading) &&
          <InviteStaff onBack={flow.controls.to} onSubmit={(emails: string, type: MemberClaim) => handleSubmit(emails, type, 'one_time')} inviteCode={staffQRInvitation?.id || ""} userEmail={userEmail}/>
        }

        { flow.step === InviteUserFlowStep.InviteFollower && (!loading && !codesLoading) && followerInvitation &&
          <InviteFollower onBack={flow.controls.to} onSubmit={(emails: string, type: MemberClaim) => handleSubmit(emails, type, 'permanent', followerInvitation)} inviteCode={followerInvitation?.id || ""} staff={staff} />
        }

    </Modal>
  </ModalContainer>
  );
}
