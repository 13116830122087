import { Button, Title, WhistleIcon } from "@gamesheet/ui";
import { useEffect, useMemo, useState, ChangeEvent } from "react";
import { useAppState } from "@/state/app/useAppState";
import { styled } from "styled-components";
import tearDropFace from "@/assets/images/tear_drop_face.svg";

const DesktopPhoneNumber = styled.div`
    font-size: 18px;
    line-height: 23.7px;

    .header {
        font-weight: 700;
        padding-top: 20px;
    }

    .body {
        font-weight: 400;
        font-size: 16px;
        line-height: 22.75px;
        padding-top: 20px;
    }

    .input {
        padding-top: 20px;
        padding-bottom: 30px;
    }

    .tos {
        font-size: 14px;
        font-color: #404247;
        line-height: 16.59px;
    }
`

const MobilePhoneNumber = styled.div`
    font-size: 18px;
    line-height: 23.7px;

    .header {
        font-weight: 700;
        padding-top: 20px;
    }

    .body {
        font-weight: 400;
        font-size: 16px;
        line-height: 22.75px;
        padding-top: 20px;
    }

    .input {
        padding-top: 20px;
        padding-bottom: 30px;
    }

    .tos {
        font-size: 14px;
        font-color: #404247;
        line-height: 16.59px;
    }
`

const Emoji = styled.div`
  background: url(${tearDropFace}) no-repeat center;
  background-size: contain;
  width: 80px;
  height: 80px;
`;

const StyledInput = styled.input`
    height: 60px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
    margin-right: 10px;
    width: 20px;
    height: 20px;
    appearance: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    
    &:checked {
        background-color: #FEC307;
        border-color: #FEC307;
    }

    &:checked::before {
        content: '✔';
        display: block;
        align-items: center;
        text-align: center;
        color: #fff;
        font-size: 14px;
    }
`;

const CheckboxLabel = styled.label`
  font-size: 16px;
  font-weight: 400;
`;

const TosContainer = styled.div`
  padding-top: 20px;

  .tos {
    font-size: 14px;
    margin-top: 20px;
    font-color: #404247;
  }

  .tos a {
    text-decoration: underline;
    color: #404247;
  }
`;

type PhoneNumberProps = {
    onSubmit: (details: Partial<{firstName: string, lastName: string, phoneNumber: string, displayName: string}>, buttonClick: boolean) => Promise<void>;
    onBack: () => void;
    register: boolean;
    role: string;
}

export function PhoneNumber({ onBack, onSubmit, register, role }:PhoneNumberProps){
    const app = useAppState()
    const StyledPhoneNumber = (app.layout.showTablet || app.layout.showDesktop) ? DesktopPhoneNumber : MobilePhoneNumber;
    const [ phoneNumber, setPhoneNumber ] = useState("");
    const [ checked, setChecked ] = useState(false);

    const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const regex = /^(\+)?\d*$/;
        if (regex.test(value)) {
          setPhoneNumber(value);
        }
    };

    const BtnBack = useMemo(() => <Button type="button" size="lg" variant="inverted" style={register ? {} : {marginLeft: '16px', height: '36px', marginBottom: '-100px'}} onClick={onBack}>Back</Button>, [ onBack ])
    useEffect(() => app.ui('leftTray').set(BtnBack, app.layout.showMobile), [ BtnBack, app.layout.showMobile ])

    const BtnNext = useMemo(() => <Button type="button" size="lg" style={register ? {} : {marginRight: '16px', height: '36px', marginBottom: '-100px'}} variant={(!checked || (phoneNumber.length < 6)) ? "muted" : ""} disabled={!checked || (phoneNumber.length < 6)} onClick={() => onSubmit({phoneNumber}, true)}>Continue</Button>, [ onSubmit, checked, phoneNumber ])
    useEffect(() => app.ui('rightTray').set(BtnNext, app.layout.showMobile), [ BtnNext, app.layout.showMobile ])

    const _role = role === 'staff' ? 'Team Staff member' : 'Team Follower'

    return <div>

        <StyledPhoneNumber>
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '28px' }}><Emoji /></div>
            <div className="header">It looks like we missed your phone number.</div>
            <div className="body">As a {_role}, your phone number is required for emergency contact purposes and will only be accessible only to Team Staff.</div>
            <div className="input"><StyledInput
                required
                type="tel"
                name={`phoneNumber`}
                id={`phoneNumber`}
                className="form-control"
                placeholder="Phone Number"
                onChange={handleInput}
                value={phoneNumber}
                height={'60px'}
            /></div>
            <div className="header">You need to be over the age of 18 to use this app.</div>
            <CheckboxContainer>
                <StyledCheckbox
                    type="checkbox"
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    id="ageVerification"
                />
                <CheckboxLabel htmlFor="ageVerification">I verify that I am over the age of 18</CheckboxLabel>
            </CheckboxContainer>
            <TosContainer>
                <div className="tos">
                        By using GameSheet, you agree to our{" "}
                    <a href="https://gamesheetinc.com/terms/" target="_blank" rel="noopener noreferrer">Terms of Service</a>
                        {" "}and{" "}
                    <a href="https://gamesheetinc.com/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                </div>
            </TosContainer>
        </StyledPhoneNumber>
        
        { (app.layout.showTablet || app.layout.showDesktop) && <>
            <hr/>
            <div style={{ display:'flex', justifyContent:'space-between' }}>
                {BtnBack}
                {BtnNext}
            </div>
        </>}

    </div>

}