import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Modal, Card, Title } from "@gamesheet/ui";
import { useEditEmailNotifications } from "./useEditEmailNotifications";
import { useAppState } from "@/state/app/useAppState";
import { useUserState } from "@/state/user/useUserState";
import { useTeamsData } from "@/state/data/useTeams";
import jersey from "../../assets/images/dark_blank_jersey.svg";
import Entitlements from "@gamesheet/tspkg-entitlements";

type TeamCheckboxes = {
  [teamId: string]: boolean;
};

type TeamSubscriptionStatus = {
  postGameEmail: boolean;
  gameLineup: boolean;
};

type SubscriptionStatuses = Record<string, TeamSubscriptionStatus>;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 5200;

  img {
    height: 50px;
  }
`;

const entitlements = new Entitlements();

export function EditEmailNotifications() {
  const app = useAppState();
  const user = useUserState();
  const _teams = useTeamsData().all();
  const teams = _teams.filter((team) => team.claim?.role === 'staff');
  const [entitledTeams, setEntitledTeams] = useState<any>([]);
  const [subscriptionStatuses, setSubscriptionStatuses] = useState<SubscriptionStatuses>({});

  const { isOpen, close, loading, handlePostGameEmailSubscribe, handlePostGameEmailUnsubscribe, handleToggleAllTeams, handleGameLineupSubscribe, handleGameLineupUnsubscribe } =
    useEditEmailNotifications();

  const [allEmailNotifications, setAllEmailNotifications] = useState(false);

  useEffect(() => {
    let mounted = true;
    const checkEntitlements = async () => {
      await Entitlements.ensureEntitlementsLoaded();

      const entitled = teams.filter((team) => {
        if (!team.sport) return false;

        return entitlements.with("sport", team.sport).isEntitled("LineupNotifications");
      });

      mounted && setEntitledTeams(entitled);
    };

    checkEntitlements();

    return () => {
      mounted = false;
    };
  }, [JSON.stringify(teams)]);

  useEffect(() => {
    let mounted = true;

    async function fetchSubscriptions() {
      try {
        const emailSubscriptionsResponse = await user.GetUserEmailSubscriptions();
        const lineupSubscriptionsResponse = await user.GetLineupSubscriptions();

        const emailSubscriptions = emailSubscriptionsResponse?.prototeamIds ?? [];
        const lineupSubscriptions = lineupSubscriptionsResponse?.prototeamIds ?? [];

        const initialStatuses: SubscriptionStatuses = {};

        teams.forEach((team) => {
          initialStatuses[team.id] = {
            postGameEmail: emailSubscriptions.includes(team.id),
            gameLineup: lineupSubscriptions.includes(team.id),
          };
        });

        mounted && setSubscriptionStatuses(initialStatuses);
      } catch (error) {
        console.error("Error fetching subscription data:", error);
      }
    }

    fetchSubscriptions();

    return () => {
      mounted = false;
    };
  }, [JSON.stringify(teams)]);

  useEffect(() => {
    const allPostGameEmailSubscribed = Object.values(subscriptionStatuses).every((status) => status.postGameEmail);

    const allGameLineupSubscribedForEntitledTeams = entitledTeams.every((team: any) => {
      return subscriptionStatuses[team.id]?.gameLineup !== false;
    });

    setAllEmailNotifications(allPostGameEmailSubscribed && allGameLineupSubscribedForEntitledTeams);
  }, [JSON.stringify(subscriptionStatuses), JSON.stringify(entitledTeams)]);

  const handleAllCheckboxChange = async (checked: boolean) => {
    setAllEmailNotifications(checked);

    const updatedStatuses: SubscriptionStatuses = { ...subscriptionStatuses };

    teams.forEach((team) => {
      const isEntitledForGameLineup = entitledTeams.find((entitledTeam: any) => entitledTeam.id === team.id);

      updatedStatuses[team.id] = {
        ...updatedStatuses[team.id],
        postGameEmail: checked,
        gameLineup: isEntitledForGameLineup ? checked : updatedStatuses[team.id]?.gameLineup,
      };
    });

    setSubscriptionStatuses(updatedStatuses);

    await handleToggleAllTeams(
      checked,
      teams.map((team) => team.id),
      entitledTeams.map((team: any) => team.id)
    );
  };

  const handleCheckboxChange = (teamId: string, category: "postGameEmail" | "gameLineup") => {
    const isChecked = !subscriptionStatuses[teamId][category];

    setSubscriptionStatuses((prevStatuses) => ({
      ...prevStatuses,
      [teamId]: {
        ...prevStatuses[teamId],
        [category]: isChecked,
      },
    }));

    if (category === "postGameEmail") {
      isChecked ? handlePostGameEmailSubscribe(teamId) : handlePostGameEmailUnsubscribe(teamId);
    } else if (category === "gameLineup") {
      isChecked ? handleGameLineupSubscribe(teamId) : handleGameLineupUnsubscribe(teamId);
    }
  };

  if (!isOpen) {
    return <></>;
  }

  return (
    <ModalContainer>
      <Modal title="Email Notifications" footerMessage="Tap the switch icon to customize your notification preferences." show={isOpen} onClose={close}>
        <Card.Card size="sm">
          <Card.CardContentContainer header="All Notifications" />
          <Card.CardIconContainer checkBox={{ checked: allEmailNotifications, onChange: () => handleAllCheckboxChange(!allEmailNotifications) }} />
        </Card.Card>
        <Title bartype="divider" size="h2" text="Post Game Email" />
        {teams.map((team) => (
          <Card.Card key={team.id} size="sm">
            <Card.CardContentContainer header={team?.title} imgUrl={team.logo ? `${team.logo}/96` : jersey} />
            <Card.CardIconContainer
              checkBox={{
                checked: subscriptionStatuses[team.id]?.postGameEmail || false,
                onChange: () => handleCheckboxChange(team.id, "postGameEmail"),
              }}
            />
          </Card.Card>
        ))}

        {entitledTeams.length > 0 && <Title bartype="divider" size="h2" text="Game Lineup" />}
        {entitledTeams.map((team: any) => (
          <Card.Card key={team.id} size="sm">
            <Card.CardContentContainer header={team?.title} imgUrl={team.logo ? `${team.logo}/96` : jersey} />
            <Card.CardIconContainer
              checkBox={{
                checked: subscriptionStatuses[team.id]?.gameLineup || false,
                onChange: () => {
                  handleCheckboxChange(team.id, "gameLineup");
                },
              }}
            />
          </Card.Card>
        ))}
      </Modal>
    </ModalContainer>
  );
}
