import { selector } from "recoil";
import { AcceptInvitationFlowStep as Step } from "@/flows/AcceptInvitationFlow/types";
import { AcceptInvitationFlowState } from "./atom.AcceptInvitationFlowState";

export const AcceptInvitationFlowScreenState = selector({
    key: 'AcceptInvitationFlowScreenState',
    get: ({get}) => {
        const state = get(AcceptInvitationFlowState);
        
        return {
            showCodeInput: state.step === Step.InsertInvitationCode,
            showTeamPreview: state.step === Step.PreviewTeam,
            showTeamRoster: state.step === Step.PreviewRoster,
            showTeamRelationship: state.step === Step.TeamRelationship,
            showSwitchToDashboard: state.step === Step.SwitchToDashboard,
            showAccountRegistration: state.step === Step.RegisterNewAccount,
            showPhoneNumber: state.step === Step.PhoneNumber,
            showPasswordConfirmation: state.step === Step.PasswordConfirmation,
            showPrototeamOptions: state.step === Step.PrototeamOptions,
            showSelectedOptionSummary: state.step === Step.SelectedOptionSummary,
            showSuccess: state.step === Step.Success,
        };
    }
})