import { useEffect, useState, ChangeEvent } from "react";
import styled from "styled-components";
import { Modal, Card, Alert, Button, Loading } from "@gamesheet/ui";
import { useRemoveFromTeam } from "./useRemoveFromTeam";
import { useAppState } from "@/state/app/useAppState";
import { useUserState } from "@/state/user/useUserState";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 5200;
`;

const InfoText = styled.div`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 1rem;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .button {
    font-size: 1rem !important;
    font-weight: 500 !important;
  }
`;

const Divider = styled.div`
  border: 1px solid #D7D7D8;
  margin-bottom: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
`

export function RemoveFromTeam() {
  const app = useAppState();
  const user = useUserState();

  const { isOpen, close, loading, onRemoveFromTeam, userName } = useRemoveFromTeam();
  const prototeam = app.teams.teamData.find((team: any) => team.id === app.selectedTeam);
  const title = `Confirm Removal`

  const handleCancel = () => {
    close();
  };

  if (!isOpen) {
    return <></>;
  }

  return (
    <ModalContainer>
      <Modal title={title} show={isOpen} onClose={handleCancel}>
      {(loading) && <div style={{ width: '100%', minWidth: '250px',display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loading /></div>}
        { !loading && <div>
          <InfoText>
            You are removing {userName} from your team. Are you sure you want to remove {userName}? This is a permanent action.
          </InfoText>
          <Divider/>
          <FooterContainer>
            <Button variant="inverted" label="Cancel" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
                label="CONFIRM REMOVAL"
                size="lg"
                width="auto"
                backgroundColor="#DC3545"
                shadowColor="#DC3545"
                textColor="#fff"
                onClick={() => {
                  onRemoveFromTeam();
                }}
              />
          </FooterContainer>
        </div>}
      </Modal>
    </ModalContainer>
  );
}
