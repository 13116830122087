import { createBrowserRouter } from "react-router-dom";
import App from "@/App";
import { ExternalContentLayout } from "@/layouts/ExternalContentLayout";
import { MainLayout } from "@/layouts/MainLayout";
import { HelpAndSupportPage } from "@/pages/help";
import { EditTeamPage } from "@/pages/team.edit";
import { JoinSeasonPage } from "@/pages/season.join";
import { TeamSettingsPage } from "@/pages/team.settings";
import { RosterPage } from "@/pages/roster";
import { TeamSchedulePage } from "@/pages/team.schedule";
import { SchedulePageBoxScore } from "@/pages/game.boxscore";
import { TeamStatsPage } from "@/pages/team.stats";
import { TeamRosterPage } from "@/pages/team.roster";
import { SeasonsRostersPage } from "@/pages/roster.seasons";
import { SeasonRosterPage } from "@/pages/roster.season";
import { TeamRosterPlayerEdit } from "@/pages/roster.team.player.edit";
import { TeamRosterCoachEdit } from "@/pages/roster.team.coach.edit";
import { ChatPage } from "@/pages/chat";
import { TeamsApp } from "./App";
import { ImportFromRegistry } from "@/pages/roster.importFromRegistry";
import { ImportFromCsvWizard } from "@/pages/RosterImportWizard/Wizard";
import { TeamRosterPlayerDelete } from "@/pages/roster.team.player.delete";
import { TeamRosterCoachDelete } from "@/pages/roster.team.coach.delete";
import { useDeviceState } from "@/state/device/useDeviceState";
import { UserEdit } from "@/pages/user.edit.profile";
import { InvitationPage } from "@/pages/user.invitation";
import { LegacyNatificationsPage } from "@/pages/user.notifications.legacy";
import { LoginLayout } from "@/layouts/LoginLayout";
import { GameCreatePage } from "@/pages/game.create";
import { EventCreatePage } from "@/pages/event.create";
import { PracticeCreatePage } from "@/pages/practice.create";
import { FlowLayout } from "@/layouts/FlowLayout";
import LineUpFlow from "@/flows/LineupFlow";
import { HUDLayout } from "@/layouts/HUDLayout";
import { MigrateTeam } from "@/pages/user.migrateTeam";
import { ManageUsersPage } from "@/pages/team.manage.users";
import { ClubTeams } from "@/pages/user.club.teams.only";
import { JoinTeam } from "@/pages/user.join.team";

export const router = (device: ReturnType<typeof useDeviceState>) =>
  createBrowserRouter([
    {
      path: "/",
      element: <App />,
      children: [
        {
          element: <TeamsApp />,
          children: [
            {
              element: <HUDLayout />,
              children: [
                {
                  element: <MainLayout />,
                  children: [
                    { path: "/", element: <TeamSchedulePage /> },
                    {
                      path: "roster",
                      element: <RosterPage />,
                      children: [
                        { path: "team", element: <TeamRosterPage /> },
                        { path: "coach/:id", element: <TeamRosterCoachEdit /> },
                        { path: "coach/:id/delete", element: <TeamRosterCoachDelete /> },
                        { path: "player/:id", element: <TeamRosterPlayerEdit /> },
                        { path: "player/:id/delete", element: <TeamRosterPlayerDelete /> },
                        { path: "seasons", element: <SeasonsRostersPage /> },
                        { path: "seasons/:seasonId", element: <SeasonRosterPage /> },
                        { path: "import/registry/:registry", element: <ImportFromRegistry /> },
                        { path: "import/csv", element: <ImportFromCsvWizard /> },
                      ],
                    },
                    { path: "chat", element: <ChatPage /> },
                    { path: "event/:eventId", element: <EventCreatePage /> },
                    { path: "practice/:eventId", element: <PracticeCreatePage /> },
                    { path: "games", element: <TeamSchedulePage /> },
                    { path: "games/:gameId", element: <GameCreatePage /> },
                    { path: "team/settings", element: <TeamSettingsPage /> },
                    { path: "team/join", element: <JoinSeasonPage /> },
                    { path: "team/edit", element: <EditTeamPage /> },
                    { path: "users/manage", element: <ManageUsersPage /> },
                    { path: "signup", element: <LoginLayout /> },
                  ],
                },
                {
                  element: <ExternalContentLayout $isActionBarOpen />,
                  children: [{ path: "seasons", element: <TeamStatsPage /> }],
                },
                {
                  element: <ExternalContentLayout />,
                  children: [
                    { path: "games/:seasonId/:gameId", element: <SchedulePageBoxScore /> },
                    { path: "myaccount/profile", element: <UserEdit /> },
                    { path: "myaccount/notifications", element: <LegacyNatificationsPage /> },
                  ],
                },
                {
                  element: <ExternalContentLayout hideUserBar />,
                  children: [
                    { path: "help", element: <HelpAndSupportPage /> },
                  ],
                },
                {
                  element: <ExternalContentLayout hideUserBar hideNavigation />,
                  children: [
                    { path: "invitation", element: <InvitationPage /> },
                    { path: "migrate", element: <MigrateTeam /> },
                    { path: "club-user", element: <ClubTeams />},
                    { path: "join-team", element: <JoinTeam />}
                  ],
                },
              ],
            },
            {
              element: <FlowLayout />,
              children: [
                { path: "/teams/:teamId/games/:gameId/lineups", element: <LineUpFlow /> },
              ],
            },
          ],
        },
      ],
    },
  ]);
