import { PageLoading } from "@/components/pure/PageLoading";
import { SignupForm, SignupFormState } from "@/forms/SignupForm";
import { useAppState } from "@/state/app/useAppState";
import { Button, Title } from "@gamesheet/ui";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { styled } from "styled-components";
import { BreakPoints } from "@/libs/breakpoints";
import googleimage from "@/assets/images/google-image.png"
import appleimage from "@/assets/images/apple-image.png"
import { Divider } from "@mui/material";

type RegisterNewAccountProps = {
    onAccept: (data:SignupFormState) => void;
    onBack: () => void;
    onSSO: (provider: string) => void;
    relationship: string;
    register: boolean;
    error?: string;
}

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
    margin-right: 10px;
    width: 20px;
    height: 20px;
    appearance: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    
    &:checked {
        background-color: #FEC307;
        border-color: #FEC307;
    }

    &:checked::before {
        content: '✔';
        display: block;
        align-items: center;
        text-align: center;
        color: #fff;
        font-size: 14px;
    }
`;

const CheckboxLabel = styled.label`
  font-size: 16px;
  font-weight: 400;
`;

const TosContainer = styled.div`
  .tos {
    font-size: 14px;
    font-color: #404247;
  }

  .tos a {
    text-decoration: underline;
    color: #404247;
  }
`;

const StyledRegistrationPage = styled.div`
    font-size: 18px;
    line-height: 23.7px;

    .header {
        font-weight: 700;
        padding-top: 20px;
    }

    .body {
        font-weight: 400;
        font-size: 16px;
        line-height: 22.75px;
        padding-top: 20px;
    }

    .input {
        padding-top: 20px;
        padding-bottom: 30px;
    }

    .tos {
        font-size: 14px;
        font-color: #404247;
        line-height: 16.59px;
    }
`

const SSButton = styled.div`
  display: flex;
  padding: 0.5rem 0 0.5rem 0;
  justify-content: center;
  align-items: center;
  gap: 0.9375rem;
  cursor: pointer;
  border-radius: 0.3125rem;
  border: 3px solid #36383D;
  color: #36383D;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0.5rem;

  img {
    width: 1.75rem;
    height: 1.75rem;
  }

  @media only screen and (max-width: ${BreakPoints.lg}) {
    padding: 0.4375rem 3.3125rem 0.45313rem 3.25rem;
    border: 2px solid #36383D;
    font-size: 1rem;
  }

`

export function RegisterNewAccount({ onAccept, onBack, onSSO, error, register, relationship }:RegisterNewAccountProps){

    const app = useAppState()
    const formRef = useRef<{ submitForm: () => void | null, loading: boolean }>(null)
    const [ checked, setChecked ] = useState(false);
    const submitForm = useCallback(() => {
        formRef.current?.submitForm()
    }, [ formRef.current ])
    const needPhoneNumber = !(relationship === 'fan' || relationship === 'other');
    
    const BtnBack = useMemo(() => <Button type="button" size="lg" style={register ? {} : {marginLeft: '16px', height: '36px', marginBottom: '-100px'}} onClick={onBack}>Back</Button>, [ onBack ])
    useEffect(() => app.ui('leftTray').set(BtnBack), [ BtnBack ])

    const BtnAccept = useMemo(() => <Button type="button" size="lg" style={register ? {} : {marginRight: '16px', height: '36px', marginBottom: '-100px'}} onClick={submitForm} disabled={!checked} variant={checked ? "" : "muted"} >Create Account</Button>, [ submitForm, checked ])
    useEffect(() => app.ui('rightTray').set(BtnAccept), [ BtnAccept ])

    if(formRef.current?.loading){
        return <PageLoading />
    }

    return <StyledRegistrationPage>
        <SSButton onClick={() => onSSO('google')}>
            <img src={googleimage} alt="google-logo" />
            Continue with Google
        </SSButton>
        <SSButton onClick={() => onSSO('apple')}>
            <img src={appleimage} alt="apple-logo" />
            Continue with Apple
        </SSButton>
        <Divider>OR</Divider>
        <div style={{ marginTop:'5px'}}>
            <SignupForm ref={formRef} onSubmit={onAccept} error={error} needPhoneNumber={needPhoneNumber}/>
        </div>
        { needPhoneNumber && <div className="tos" style={{margin: '8px 0px'}}>Your phone number is for emergency contact purposes and will only be visible to Team Staff.</div>}
        <CheckboxContainer>
                <StyledCheckbox
                    type="checkbox"
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    id="ageVerification"
                />
            <CheckboxLabel htmlFor="ageVerification">I verify that I am over the age of 18</CheckboxLabel>
        </CheckboxContainer>
        <TosContainer>
            <div className="tos">
                    By using GameSheet, you agree to our{" "}
                <a href="https://gamesheetinc.com/terms/" target="_blank" rel="noopener noreferrer">Terms of Service</a>
                    {" "}and{" "}
                <a href="https://gamesheetinc.com/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
            </div>
        </TosContainer>
    </StyledRegistrationPage >

}