import { FormFieldType } from "@/forms/hooks/useCreateForm"
import { useState } from "react"

type InputProps = {
    className?: string[]
    state: FormFieldType,
    name: string
    label: string
    autocomplete?:boolean
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => FormFieldType<any>
    isDisabled?: boolean
}

export function TextInput({ className=[], state, name, label, onChange, autocomplete=true, isDisabled}:InputProps){
    
    const [isDirty, setIsDirty] = useState(false)
    const testResult = isDirty ? state?.valid ? "valid" : "invalid" : "";
    const inputClass = `is-${testResult}`
    const labelClass = `${testResult}-feedback`

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e);
    }

    const handleFirstValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(e);
        setIsDirty(true);
    }

    // Warning: A component is changing an uncontrolled input to be controlled. 
    // This is likely caused by the value changing from undefined to a defined value, which should not happen. 
    // Decide between using a controlled or uncontrolled input element for the lifetime of the component. 
    // More info: https://reactjs.org/link/controlled-components
    if(!state){
        return <></>
    }
    
    return <div className={`form-floating ${className.join(" ")}`}>
        <input
            required
            type={name === "email" ? "email" : name === "phoneNumber" ? "tel" : "text"}
            name={name}
            id={name}
            className={`form-control ${inputClass}`}
            placeholder={label}
            onChange={handleChange} 
            onBlur={handleFirstValidation}
            value={state?.value}
            role={autocomplete ? "textbox" : "presentation"}
            autoComplete={autocomplete ? "on" : "off"}
            disabled={isDisabled}
        />

        <label htmlFor={name} className={`floating`}>
            {label}
        </label>
        { !state?.valid && testResult && <span className={labelClass}>{state?.error}</span> }

    </div>
}