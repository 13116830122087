import React from "react"
import { emailRegex } from "@/forms/SignupForm";
import { PasswordInput } from "@/forms/components/PasswordInput";
import { TextInput } from "@/forms/components/TextInput";
import { NewFormField, useCreateForm } from "@/forms/hooks/useCreateForm";
import { BreakPoints } from "@/libs/breakpoints";
import { useAppState } from "@/state/app/useAppState";
import { UserState } from "@/state/user/useUserState";
import { useForgotPassword } from "@/windows/forgotPassword/useForgotPassword";
import { Button, FormWrapper, Logo } from "@gamesheet/ui";
import { useState, useEffect } from "react";
import { auth } from "@/libs/firebase"
import { setPersistence, browserLocalPersistence, signInWithCustomToken, signInWithPopup, GoogleAuthProvider, OAuthProvider } from "firebase/auth"
import appleimage from "@/assets/images/apple-image.png"
import { styled } from "styled-components";
import ErrorBar from "@/components/pure/ErrorBar";
import googleimage from "@/assets/images/google-image.png"
import { PageLoading } from "@/components/pure/PageLoading";

const PageContainer = styled.div`
  display: flex;
  gap: 3rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 100vw;
  padding: 3rem;
  overflow-y: scroll;
  padding-top: 0rem;
  @media only screen and (max-width: ${BreakPoints.md}) {
    flex-direction: column;
    gap: 1rem;
    justify-content: flex-start;
    padding: 0 1rem;
  }

  @media only screen and (max-width: ${BreakPoints.sm}) {
    gap: .5rem;
  }
`;

const LogoContainer = styled.div`
  flex-grow: 1;
  min-width: 200px;
  max-width: 500px;
  @media only screen and (max-width: ${BreakPoints.md}) {
    padding: 1rem 0 1.5rem 0;
    min-width: 175px;
  }
`;

const HorizontalLine = styled.div`
  display: flex;
  align-items: center;
  color: #AFAFB1;
  
  .line {
    flex-grow: 1;
    height: 3px;
    background-color: #AFAFB1;
  }

  .text {
    padding: 0 .5rem;
    font-weight: bold;
  }
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 2;

  @media only screen and (max-width: ${BreakPoints.md}) {
    flex-grow: 1;
  }

  @media only screen and (max-width: ${BreakPoints.sm}) {
    gap: .5rem;
  }
`;

const LoginFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.3125rem;
  background: #F5F5F4;
  box-shadow: 0px 4px 4px 0px rgba(54, 56, 61, 0.2);
  height: 100%;
  padding: 1.75rem;
  gap: 1rem;
  max-width: 30rem;

  @media only screen and (max-width: ${BreakPoints.lg}) {
    padding: 1rem;
  }
  

  .button {
    font-size: 1.25rem !important;
    font-weight: 500 !important;
  }
`;

const Banner = styled.div`
  color: #36383d;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const RegisterFormContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 0.3125rem;
  background: #36383d;
  box-shadow: 0px 4px 4px 0px rgba(54, 56, 61, 0.2);
  padding: 1rem;
  max-width: 30rem;
  
`;

const InfoText = styled.div`
  color: #fff;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media only screen and (max-width: ${BreakPoints.lg}) {
    font-size: 1.25rem;
  }
`;

const RegisterText = styled.div`
  color: #fec307;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;

  @media only screen and (max-width: ${BreakPoints.lg}) {
    font-size: 1.25rem;
  }
`;

const ForgotPassword = styled.div`
color: #36383D;
text-align: center;
font-size: 1.125rem;
font-style: normal;
font-weight: 500;
line-height: normal;
text-decoration-line: underline;
text-transform: capitalize;
cursor: pointer;
@media only screen and (max-width: ${BreakPoints.lg}) {
    font-size: 1rem;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;

`

const DisclaimerText = styled.div`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  span {
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
  }

  @media only screen and (max-width: ${BreakPoints.lg}) {
    font-size: .875rem;

    span {
      font-size: .875rem;
    }
  }
`;

const GoogleButton = styled.div`
  display: flex;
  padding: 0.8125rem 4.5625rem 0.76563rem 4.5625rem;
  justify-content: center;
  align-items: center;
  gap: 0.9375rem;
  cursor: pointer;
  border-radius: 0.3125rem;
  border: 3px solid #36383D;
  color: #36383D;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  img {
    width: 1.75rem;
    height: 1.75rem;
  }

  @media only screen and (max-width: ${BreakPoints.lg}) {
    padding: 0.4375rem 3.3125rem 0.45313rem 3.25rem;
    border: 2px solid #36383D;
    font-size: 1rem;
  }

`


export function LoginStep({ user, onSignup }: { user: UserState; onSignup: () => void }) {
  
  const app = useAppState();
  const { open: openFogotPasswordModal } = useForgotPassword();

  const [error, setError] = useState("");
  const { state: formState, onChange } = useCreateForm("loginUser", {
    email: NewFormField(user.email || "", emailRegex, "Email must be valid"),
    password: NewFormField("", /.{1,}/, "Please enter your password"),
  });

  const login = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!formState.email.valid || !formState.password.valid) {
      return
    }

    user
      .SignIn(formState.email.value, formState.password.value)
      .then(() => setError(""))
      .catch((err: any) => setError(err));
  };

  const onGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      await setPersistence(auth, browserLocalPersistence);
    } catch (error) {
      console.error("Sign-in error", error);

    }
  };

  const onAppleLogin = async () => {
    try {
      const provider = new OAuthProvider('apple.com');
      await signInWithPopup(auth, provider);
      await setPersistence(auth, browserLocalPersistence);
    } catch (error) {
      console.error("Sign-in error", error);

    }
  }

  if(!formState){
    return <PageLoading />
  }

  return (
    <PageContainer>
      <LogoContainer>
        {app.layout.showMobile ? <Logo logo="horizontal" size="h4" color="color" contrast="darkbg" /> : <Logo logo="primary" size="100%" />}
      </LogoContainer>

      <FormWrapper>
        <ControlsContainer>
          <LoginFormContainer>
            <Banner>Log in to your account</Banner>

            <GoogleButton onClick={() => onGoogleLogin()}>
              <img src={googleimage} alt="google-logo" />
              Continue with Google
            </GoogleButton>
            <GoogleButton onClick={() => onAppleLogin()}>
              <img src={appleimage} alt="apple-logo" />
              Continue with Apple
            </GoogleButton>

            <HorizontalLine>
              <div className="line" /><span className="text">OR</span><div className="line" />
            </HorizontalLine>
            <StyledForm>
              <TextInput label="Email" name="email" state={formState.email} onChange={onChange} />
              <PasswordInput label="Password" name="password" state={formState.password} onChange={onChange} />
              {error && <ErrorBar message={error}></ErrorBar>}
              <Button type="submit" gs-style="solid" width="block" size="xl" onClick={login}>
                Log in
              </Button>
            </StyledForm>
            <ForgotPassword onClick={openFogotPasswordModal} >
              Forgot your password?
            </ForgotPassword>
            <DisclaimerText>
              By using GameSheet, you agree to our{" "}
              <span onClick={() => window.open("https://www.gamesheetinc.com/terms", "_terms")} role="link" tabIndex={0}>
                Terms of Service
              </span>{" "}
              and{" "}
              <span onClick={() => window.open("https://gamesheetinc.com/privacy", "_privacy-policy")} role="link" tabIndex={0}>
                Privacy Policy
              </span>
              .
            </DisclaimerText>
          </LoginFormContainer>
          <RegisterFormContainer>
            <InfoText>Don't have an account?</InfoText>
            <RegisterText onClick={onSignup}>Register</RegisterText>
          </RegisterFormContainer>
        </ControlsContainer>
      </FormWrapper>
    </PageContainer>
  );
}
