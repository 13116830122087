import { FirestoreMember, FirestoreMemberData } from "@/services/useMemberService/types.ts/member";
import { listChannels } from "@/services/useMessagingService/channels/func.listChannels";
import { ChannelData } from "@/services/useMessagingService/types";
import { QuerySnapshot } from "firebase/firestore";
import { useEffect } from "react";
import { atom, selector, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { WithId } from "../../types";
import { useAppState } from "../app/useAppState";
import { membersState } from "../members/useMembersState";

export type ChannelDataWithId = WithId<ChannelData>
type ChannelsState = {
    data: ChannelDataWithId[],
    lastUpdated: number
}
export const channelsState = atom<ChannelsState>({
    key: "channelsState",
    default: {
        data: [],
        lastUpdated: 0,
    },
    dangerouslyAllowMutability: true
});

export const currentChannelState = atom<ChannelDataWithId|undefined>({
    key: "currentChannelState",
    default: undefined,
    dangerouslyAllowMutability: true
});

export const membersByChannelState = selector({
    key: 'membersByChannelState',
    get: ({ get }) => {
        const members = get(membersState);
        const channels = get(channelsState).data;
        const membersByChannel: { [key: string]: FirestoreMember[] } = {};

        channels.forEach(channel => {
            membersByChannel[channel.id] = members.filter(member =>
                member.channels.some(channelRef => channelRef.id === channel.id)
            );
        });

        return membersByChannel;
    },
    dangerouslyAllowMutability: true,
});

export const useReadChannelsState = () => {
    
    const app = useAppState();
    const setChannels = useSetRecoilState(channelsState)

    const handleChannels = async (snapshot:QuerySnapshot) => {
        
        setChannels({
            lastUpdated: Date.now(),
            data: snapshot.docs.map(doc => {
                console.log("doc.data()", doc.data())
                return { ...doc.data(), id:doc.id } as ChannelDataWithId
            })
        })

    }

    const clearChannels = () => {
        setChannels({
            lastUpdated: Date.now(),
            data: []
        });
    }
    
    useEffect(() => {
        
        if(!app.selectedTeam){
            clearChannels()
            return;
        }

        const unsubscribe = listChannels(app.selectedTeam, handleChannels);
  
        return () => {
            unsubscribe();
            clearChannels()
        };

    }, [app.selectedTeam]);

    return useChannelsState()

}

export const useChannelsState = () => {
    const channels = useRecoilValue(channelsState)
    const [ currentChannel, setCurrentChannel ] = useRecoilState(currentChannelState)

    return {
        channels: channels.data,
        currentChannel,
        lastUpdated: channels.lastUpdated,
        open(channelId:string|null){
            if(!channelId) return this.clearSelectedChannel();
            setCurrentChannel(channels.data.find(channel => channel.id === channelId))
        },
        clearSelectedChannel(){
            setCurrentChannel(undefined)
        }
    }
}