import React, { useEffect } from "react";
import styled from "styled-components";
import { Title, EyeIcon, RoleCard, WhistleIcon, Modal, Card, Alert, Button } from "@gamesheet/ui";
import { BreakPoints } from "@/libs/breakpoints";
import { InviteUserFlowPath } from "./types";
import { useSelectedTeamState } from "@/state/team/useSelectedTeamState";
import { useAppState } from "@/state/app/useAppState";

const Header = styled.div`
  color: #404247;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
`;

type SelectRoleProps = {
  onSelect: (pathIndex: number) => void;
};

export const SelectRole: React.FC<SelectRoleProps> = ({ onSelect }) => {

  const app = useAppState();
  const selectedTeam = useSelectedTeamState();

  return (
      <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        <Header>{`Select the type of user you'd like to invite to the ${selectedTeam?.title}.`}</Header>
        <RoleCard
          onClick={() => onSelect(1)}
          icon={<EyeIcon width={37} height={36} selected={false}/>}
          title="Team Follower"
          messages={[
            { message: "Set Game Lineups", checkmark: false },
            { message: "Schedule Practices, Events & Games", checkmark: false },
            { message: "View Scores, Schedule, Standings & Stats", checkmark: true },
            { message: "Team Chat & Messaging", checkmark: true },
          ]}
        />
        { app.selectedTeamClaim?.role === 'staff' && <RoleCard
          onClick={() => onSelect(2)}
          icon={<WhistleIcon selected={false}/>}
          title="Team Staff"
          messages={[
            { message: "Set Game Lineups", checkmark: true },
            { message: "Schedule Practices, Events & Games", checkmark: true },
            { message: "View Scores, Schedule, Standings & Stats", checkmark: true },
            { message: "Team Chat & Messaging", checkmark: true },
          ]}
        />}
      </div>
  );
};
