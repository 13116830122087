import styled from "styled-components";
import { Chatroom } from "@/components/Chatroom";
import { BreakPoints } from "@/libs/breakpoints";
import MobileChatView from "@/components/views/Chat/MobileChatView";
import DesktopChatView from "@/components/views/Chat/DesktopChatView";
import { useState } from "react";
import { useChannelsState } from "@/state/messaging/useChannelsState";
import { useAppState } from "@/state/app/useAppState";

const StyledChatPage = styled.div`
width: 100%;
`;

export function ChatPage() {

  // const app = useAppState()
  // const ChatView = app.device.isMobile ? MobileChatView : DesktopChatView

  // return <StyledChatPage>
  //   <ChatView />
  // </StyledChatPage>;
  
  // Turn off chat
  return <></>
}
