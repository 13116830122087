import { QueryConstraint, QuerySnapshot, collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { firestore as db } from '@/libs/firebase'

export const listChannels = (teamId: string, onChannels: (snapshot: QuerySnapshot) => Promise<void>) => {
    
    const clauses:QueryConstraint[] = [
        where("relationships","array-contains",{
            type:"team",
            ref:doc(db,`teams/${teamId}`)
        })
    ]
    
    const channels = collection(db, `channels`);
    return onSnapshot(query(channels,...clauses), onChannels);

}


