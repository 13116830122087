import { Button, FormWrapper, Loading, Title } from "@gamesheet/ui";
import * as Grid from "@/layouts/GridItems";
import { TextInput } from "./components/TextInput";
import { FormEvent, FormEventHandler, forwardRef, useCallback, useEffect, useImperativeHandle, useState, MouseEvent } from "react";
import { FormFieldType, NewFormField, useCreateForm } from "@/forms/hooks/useCreateForm";
import { PasswordInput } from "./components/PasswordInput";
import { styled } from "styled-components";
import { PageLoading } from "@/components/pure/PageLoading";
import { useUserState } from "@/state/user/useUserState";
import { AppStateInterface } from "@/state/app/app.interface";
import { UserUpdate } from "@/services/useUserService";

const StyledEditUserForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const ButtonDiv = styled.div`
  margin-top: auto;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  margin-bottom: 25px;

  button {
    width: 100%;
    height: 3rem;
    background-color: #fafafa;
    color: #36383D;
    border: 2px solid #36383D;
  }
`;

export type EditUserFormState = {
    firstName: FormFieldType<string>;
    lastName: FormFieldType<string>;
    displayName: FormFieldType<string>;
    phoneNumber: FormFieldType<string>;
    email: FormFieldType<string>;
    confirmPassword: FormFieldType<string>;
}

type EditUserFormProps = {
    onSubmit: (e: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>) => void;
    emailChanged: Boolean;
    formState: EditUserFormState;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => FormFieldType<any>;
    app: AppStateInterface;
}

export const EditUserForm = forwardRef(({ onSubmit, emailChanged, formState, onChange, app }:EditUserFormProps, ref ) => {
    
    if(!formState){
        return <PageLoading />
    }

    return <FormWrapper style={{ marginTop:'15px' }}>
                <Title bartype="divider" text="Edit Profile" size="h2"/>
                <StyledEditUserForm onSubmit={onSubmit} style={{ marginTop:'15px' }}>
                    <TextInput state={formState.firstName} name="firstName" label="First Name" onChange={onChange} />
                    <TextInput state={formState.lastName} name="lastName" label="Last Name" onChange={onChange} />
                    <TextInput state={formState.displayName} name="displayName" label="Display Name" onChange={onChange} />
                    <TextInput state={formState.phoneNumber} name="phoneNumber" label="Phone Number" onChange={onChange} />
                    <TextInput state={formState.email} name="email" label="Email" onChange={onChange} autocomplete={false} />
                    { emailChanged && <PasswordInput state={formState.confirmPassword} name="confirmPassword" label="Verify Password" onChange={onChange} autocomplete={false} /> }
                </StyledEditUserForm>
            </FormWrapper>
})