import { AcceptInvitationFlow } from "@/flows/AcceptInvitationFlow";
import { AcceptInvitationFlowPath } from "@/flows/AcceptInvitationFlow/types";
import styled from 'styled-components';
import bg2 from '@/assets/images/bg2.jpg'
import { useAppState } from "@/state/app/useAppState";

const MobileStyledSignupStep = styled.div.attrs({
  className: "signup-step"
})`
background-image: url(${bg2});
padding: 16px;
min-height: 500px;
width: 100%;
`

const DesktopStyledSignupStep = styled.div.attrs({
  className: "desktop-signup-step"
})`
`

export function MigrateTeam() {
  const app = useAppState();
  const StyledSignupStep = (app.layout.showTablet || app.layout.showDesktop) ? DesktopStyledSignupStep : MobileStyledSignupStep

  return <StyledSignupStep>
        <AcceptInvitationFlow path={AcceptInvitationFlowPath.MigrateTeam} onDone={() => { window.location.href = "/"; }} />
    </ StyledSignupStep>
}