import { firestore } from "@/libs/firebase";
import { addDoc, collection, doc } from "firebase/firestore";
import { FirestoreMember } from "../types.ts/member";

export type MemberCreation = Omit<FirestoreMember, "team" | "channels" | "unread" | "status">

export function createMember(
    team: string,
    member: MemberCreation
){
    if (member.teamRelationship.relatedPlayers === undefined) {
        member.teamRelationship = {
            relationship: member.teamRelationship.relationship
        }
    }
    console.log(member);
    const teamRef = doc(firestore, `teams/${team}`);
    const membersRef = collection(teamRef, `members`);
    return addDoc(membersRef, {
        ...member,
        team: teamRef,
        channels: [],
        unread: [],
        status: "offline",
    })

}