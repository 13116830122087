import { Button, Title } from "@gamesheet/ui";
import * as Grid from "@/layouts/GridItems";
import { useAppState } from "@/state/app/useAppState";
import PlayerForm from "@/forms/PlayerForm";
import { useEffect, useState, useCallback } from "react";
import { usePlayerData } from "@/state/data/usePlayerData";
import { useParams } from "react-router";
import { PageLoading } from "@/components/pure/PageLoading";
import { Navigate } from "react-router-dom";
import { useAppNavigation } from "@/hooks/useAppNavigation";
import { useSeasonsData } from "@/state/data/useSeasonTeams";
import { useTeamRoster } from "@/state/data/useTeamRoster/useTeamRoster";
import { usePageState } from "@/state/page/usePageState";
import { useRosterSelect } from "./team.roster/useRosterSelect";

export function TeamRosterPlayerEdit(){

    const app = useAppState()
    const Page = usePageState('team-player-edit', {
        rememberScroll: false,
        showBackButton: true,
        backButtonText: "Cancel",
        backButtonMethod: () => app.navigate(`/roster/team`)
    })

    const params = useParams()
    const playerId = params.id
    const isNew = playerId == "new"
    const roster = useTeamRoster()
    const { isLocked } = useRosterSelect()
    const { formData, setFormData, submit } = usePlayerData(playerId)
    const [ loading, setLoading ] = useState(false)
    const { navigate, destination, hasNavigation, handleNavigation } = useAppNavigation();
    const seasons = useSeasonsData()
    const showActions = seasons.hasUnlockedRoster
    const formValid = formData.firstName.length > 1 && formData.lastName.length > 1;
    const staff = app.selectedTeamClaim?.role === 'staff';

    const onSavePlayer = useCallback( () => {
        if (formValid) {
            setLoading(true)
            submit(onPlayerSaved)
        }
    }, [ submit ])

    const onPlayerSaved = () => {
        setLoading(false)

        if (isLocked) {
            navigate(`/roster/team`);
            return;
        }

        const updateFunc = playerId == "new" ? roster.addPlayer : roster.updatePlayer
        updateFunc({
            firstName: formData.firstName.toUpperCase(),
            lastName: formData.lastName.toUpperCase(),
            affiliated: formData.affiliated,
            id: formData.id,
            position: formData.position,
            rosterLocked: formData.rosterLocked,
            teamId: formData.teamId,
            bio: formData.biography || "",
            birthDate: {
                Time: "",
                Valid: false,
            },
            birthDay: formData.birthDay || "",
            birthMonth: formData.birthMonth || "",
            birthYear: formData.birthYear || "",
            height: formData.height || "",
            weight: formData.weight || "",
            committedTo: formData.committedTo || "",
            country: formData.country || "",
            draftedBy: formData.draftedBy || "",
            duty: formData.duty || "",
            homeTown: formData.hometown || "",
            jersey: formData.jersey || "",
            photoUrl: formData.photoURL || "",
            province: formData.province || "",
            shotHand: formData.shotHand as "right" | "left" || "",
            status: formData.status || "",
        })
        navigate(`/roster/team`)
    }

    useEffect(() => {
        if (!isLocked && staff) {
            const btnLabel = isNew ? "Create" : "Save"
            Page.setPrimaryButton(btnLabel, onSavePlayer, !formValid)
        } else {
            Page.setBackButtonText("Back")
        }
    }, [ isNew, formValid, onSavePlayer, isLocked, staff ])

    if (hasNavigation) {
        return <Navigate to={destination} />
    }

    if(loading){
        return <PageLoading />
    }

    return (<>
        <Grid.Blank $column={1} $row={1}>
            
            <PlayerForm formData={formData} setFormData={setFormData} isLocked={isLocked || !staff} />
            { staff && showActions && !isNew && !isLocked && <Button style={{marginBottom: "2rem"}} variant="danger" width="block" size="lg" onClick={handleNavigation(`/roster/player/${playerId}/delete`)}>Remove {formData.firstName} {formData.lastName}</Button> }
        </Grid.Blank>
    </>)
}