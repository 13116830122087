import { Button, FormWrapper } from "@gamesheet/ui";
import { styled } from "styled-components";
import { useCallback, useState } from "react";
import { InsertInvitationCodeProps } from "./types";
import { useInvitationCodeState } from "./state/useInvitationCodeState";
import { ErrorCard } from "@/components/pure/EntityCard/MobileEntityCard";
import { useAppState } from "@/state/app/useAppState";
import { useUserState } from "@/state/user/useUserState";

const Error = styled.span`
  color: red;
`;

const StyledInsertInvitationCode = styled.div`
width: 100%;
`

export function InsertInvitationCode({ onSubmit, error:externalError, register }:InsertInvitationCodeProps){

    const app = useAppState();
    const user = useUserState();

    const [error, setError] = useState<string>()
    const hasError = !!error;
    
    const [code, _setCode] = useInvitationCodeState();
    const setCode = useCallback((input:string) => { setError(""); _setCode(input) }, [ _setCode, setError ])
    const [ switchButton, setSwitchButton ] = useState(false);
    
    const handleSubmit = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        isValid() && onSubmit(code);
    };

    const handleCancel = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
        app.navigate('/games')
    };

    // validate
    function isValid(){
        if(code.length < 1){
            setError("Please enter a code")
            return false;
        }
        return !error;
    }

    function getErrorDescription(message:string){
        switch(message){
            case "No Teams in Invitation": return "This invitation code is not linked to any teams. Please contact the team owner for a new code.";
            case "Bad Invitation Code": return "This invitation code is invalid. Please contact the team owner for a new code.";
            case "Cannot Register With Scheduler Code": return "This invitation code must be accepted using the Dashboard.";
            case "Cannot Accept Role": return "This invitation code is for a scheduler role in a team you are not a manager of. Please enter another code or navigate to the Dashboard to accept this invitation.";
            case "This team has been deleted": return "Please contact the league, team owner or event administrator to request a new invitation code.";
            case "This division has been deleted": return "Please contact the league, team owner or event administrator to request a new invitation code.";
            case "This season has been deleted": return "Please contact the league, team owner or event administrator to request a new invitation code.";
            default: return message;
        }
    }

    const switchToDashboard = useCallback(async () => {
        if (externalError === "Cannot Accept Role") {
            setSwitchButton(true)
            const otp = await user.GetOtp()
            window.location.href = `${app.config.apps.leagueDashboard}/account/invitation?invitation=${code}&otp=${otp}`
        } 
        if (externalError === "Cannot Register With Scheduler Code") {
            setSwitchButton(true)
            window.location.href = `${app.config.apps.leagueDashboard}/account/new`
        }
    }, [externalError])

    return <StyledInsertInvitationCode>

        <p>
            An Invitation or an Invitation Code is a 10 or 20 letter random code that is linked 
            to a specific team in the system.&nbsp;
            <a href="https://help.gamesheet.app/article/26-invitation-codes" target="helpscout">Learn More</a>
        </p>

        { externalError && <ErrorCard title={externalError} message={getErrorDescription(externalError)} /> }
        { switchButton && <Button style={{ width:'100%', marginTop:'5px', height: '36px', marginBottom:'15px' }} size="lg" onClick={switchToDashboard}>Switch to League Dashboard</Button> }

        <FormWrapper>
      
            <form onSubmit={handleSubmit}>
                
                <div className="form-floating mt-3 mb-3">
                
                    <input 
                        required 
                        type="text" 
                        name="invitationCode" 
                        id="invitationCode" 
                        className="form-control" 
                        placeholder="Invitation Code" 
                        onChange={(e) => setCode(e.target.value)} 
                        value={code}
                    />
                    
                    <label htmlFor="invitationCode" className="floating">
                        {hasError ? <Error>{error}</Error> : `Invitation Code`}
                    </label>
                    
                </div>
            </form>

        </FormWrapper>

        <Button width="block" size="lg" onClick={handleSubmit}>Continue</Button>
        <Button width="block" size="lg" style={{marginTop: '0.5rem'}} variant="inverted" onClick={handleCancel}>Cancel</Button>

    </StyledInsertInvitationCode>

}