import React from 'react';
import { Button, Title, WhistleIcon } from "@gamesheet/ui";
import { useAppState } from "@/state/app/useAppState";
import { styled } from "styled-components";
import rosterIcon from "@/assets/images/roster.png";
import messagesIcon from "@/assets/images/messages.png";
import statsIcon from "@/assets/images/stats.png";
import scheduleIcon from "@/assets/images/schedule.png";
import { BreakPoints } from '@/libs/breakpoints';
import navigateIcon from "@/assets/images/open-in-new.png";
import { useUserState } from '@/state/user/useUserState';

const DesktopClubTeams = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  line-height: 23.7px;
  padding: 20px;
  padding-top: 25px;
  min-height: 100vh;
  position: relative;

  .header {
    font-weight: 700;
    padding-top: 20px;
  }

  .body {
    font-weight: 400;
    font-size: 16px;
    line-height: 22.75px;
    padding-top: 20px;
  }

  .input {
    padding-top: 20px;
    padding-bottom: 30px;
  }
`;

const MobileClubTeams = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  line-height: 23.7px;
  padding: 20px;
  padding-top: 25px;
  min-height: 100vh;

  .header {
    font-weight: 700;
    padding-top: 20px;
  }

  .body {
    font-weight: 400;
    font-size: 16px;
    line-height: 22.75px;
    padding-top: 20px;
  }

  .input {
    padding-top: 20px;
    padding-bottom: 30px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
`;

const IntroText = styled.div`
  margin-top: 10px;
  font-size: 22px;
  font-weight: 600;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 120px);
  grid-template-rows: repeat(2, 120px);
  row-gap: 20px;
  column-gap: 60px;
  font-weight: 600;
  font-size: 20px;
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const GridText = styled.div`
  position: absolute;
  bottom: 0;
  text-align: center;
`;

const BottomButtons = styled.div`
  width: calc(100%);
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 20px;
  margin-top: 40px;
`;

const StyledTitle = styled(Title)`
  font-size: 24px;
  font-weight: bold;
`;

const BorderButton = styled.div`
  display: flex;
  padding: 0.5rem 0 0.5rem 0;
  justify-content: center;
  align-items: center;
  gap: 0.9375rem;
  cursor: pointer;
  border-radius: 0.3125rem;
  border: 3px solid #36383D;
  color: #36383D;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
  height: 48px;
  font-size: 18px;
  font-weight: 600;

  img {
    width: 1rem;
    height: 1rem;
  }

  @media only screen and (max-width: ${BreakPoints.lg}) {
    border: 3px solid #36383D;
  }

`

export function ClubTeams() {
  const app = useAppState();
  const user = useUserState();
  const StyledClubTeams = app.layout.showTablet || app.layout.showDesktop ? DesktopClubTeams : MobileClubTeams;

  const handleGetStarted = () => {
    app.navigate('/join-team');
  }

  const handleSwitchToAdminDashboard = async() => {
    await user.Expire()
    const otp = await user.GetOtp()
    if (otp) {
        const url = `${app.config.apps.leagueDashboard}/associations?otp=${otp}`
        window.open(url);
    }
  }

  const handleLogOut = () => {
    user.SignOut();
  }

  return (
    <div>
      <StyledClubTeams>
        <StyledTitle text="Welcome!" bartype="divider" size="h2" />
        <div style={{ marginTop: '10px', fontSize: '18px' }}>
          <b>GameSheet Teams</b> is an app for Coaches, Managers, Parents, Participants and anyone else directly involved with a team.
        </div>

        <Container>
          <div>
            <IntroText>Use GameSheet Teams to</IntroText>
            <IntroText>manage, view, and track:</IntroText>
          </div>
          <Grid>
            <GridItem>
              <img src={rosterIcon} alt="roster icon" style={{ width: '71.88px' }} />
              <GridText>ROSTER</GridText>
            </GridItem>
            <GridItem>
              <img src={scheduleIcon} alt="schedule icon" style={{ width: '53.12px' }} />
              <GridText>SCHEDULE</GridText>
            </GridItem>
            <GridItem>
              <img src={statsIcon} alt="stats icon" style={{ width: '59.5px' }} />
              <GridText>STATS</GridText>
            </GridItem>
            <GridItem>
              <img src={messagesIcon} alt="messages icon" style={{ width: '59.38px' }} />
              <GridText>MESSAGES</GridText>
            </GridItem>
          </Grid>
        </Container>

        <div style={{ fontSize: '20px', fontWeight: '600' }}>Not a Member of a Team?</div>
        <div>If you manage a league/event or oversee multiple teams follow the link below to switch to the Admin Dashboard.</div>

        <BottomButtons>
          <Button style={{ height: '48px', fontSize: '18px', marginBottom: '10px' }} onClick={handleGetStarted}>Get started</Button>
          <BorderButton onClick={handleSwitchToAdminDashboard}>
                <img src={navigateIcon} alt="apple-logo" />
                SWITCH TO ADMIN DASHBOARD
            </BorderButton>
          <Button style={{ height: '48px', fontSize: '18px', marginBottom: '0px' }} variant="inverted" onClick={handleLogOut}>Log out</Button>
        </BottomButtons>
      </StyledClubTeams>
    </div>
  );
}
