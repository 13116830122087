import { Button, FormWrapper, Loading, Title } from "@gamesheet/ui";
import * as Grid from "@/layouts/GridItems";
import { TextInput } from "./components/TextInput";
import { FormEvent, FormEventHandler, forwardRef, useCallback, useEffect, useImperativeHandle, useState, MouseEvent } from "react";
import { FormFieldType, NewFormField, useCreateForm } from "@/forms/hooks/useCreateForm";
import { PasswordInput } from "./components/PasswordInput";
import { styled } from "styled-components";
import { PageLoading } from "@/components/pure/PageLoading";
import { useUserState } from "@/state/user/useUserState";
import { AppStateInterface } from "@/state/app/app.interface";
import { UserUpdate } from "@/services/useUserService";

const StyledEditPasswordForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

export type EditPasswordFormState = {
    currentPassword: FormFieldType<string>;
    newPassword: FormFieldType<string>;
}

type EditPasswordFormProps = {
    onSubmit: (e: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>) => void;
    passwordFormState: EditPasswordFormState;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => FormFieldType<any>;
}

export const EditPasswordForm = forwardRef(({ onSubmit, passwordFormState, onChange }:EditPasswordFormProps, ref ) => {
    
    if(!passwordFormState){
        return <PageLoading />
    }

    return <FormWrapper style={{ marginTop:'15px' }}>
        <Title bartype="divider" text="Edit Profile" size="h2"/>
        <StyledEditPasswordForm onSubmit={onSubmit} style={{ marginTop:'15px' }}>
            <PasswordInput state={passwordFormState.currentPassword} name="currentPassword" label="Current Password" onChange={onChange} autocomplete={false} />
            <PasswordInput state={passwordFormState.newPassword} name="newPassword" label="New Password" onChange={onChange} autocomplete={false} />
        </StyledEditPasswordForm>
    </FormWrapper>
})