import { firestore } from "@/libs/firebase";
import { deleteDoc, collection, doc, getDocs, query, where } from "firebase/firestore";
import { FirestoreMember } from "../types.ts/member";

export async function removeMember(
    team: string,
    userId: string
) {
    const teamRef = doc(firestore, `teams/${team}`);
    const membersRef = collection(teamRef, `members`);
    const memberQuery = query(membersRef, where("id", "==", userId));
    
    const querySnapshot = await getDocs(memberQuery);
    
    const deletePromises = querySnapshot.docs.map(docSnapshot => deleteDoc(docSnapshot.ref));
    
    return Promise.all(deletePromises);
}
