// helpers
import { useTranslatePosition, translateDuty, translateShotHand } from "@/libs/translatePosition";

// imports

import { firebaseUserState } from "@/state/user/useSetFirebaseAuth";
import { firestore } from "@/libs/firebase";
import { useRecoilValue, useRecoilState, useResetRecoilState, RecoilState } from "recoil";
import { NavigateOptions, To, useLocation, useNavigate } from "react-router-dom";
import { useDeviceState } from "@/state/device/useDeviceState";
import { NavigationItem } from "@/components/pure/NavButton";
import { MouseEvent, useCallback, useEffect } from "react";
import { useEventsState } from "@/state/events/useEventsStats";
import { AppStateInterface } from "./app.interface";
import { AppUiLocations, appState } from "./app.state";
import { User } from "firebase/auth";
import { useConfig } from "@/config/useConfig";
import { useNestedApp } from "./useNestedApp";
import { useResetState } from "./useResetState";
import { teamsDataState } from "../data/useTeams";
import { useUnleashContext } from "@unleash/proxy-client-react";
import { MemberClaim } from "@/services/useMemberService/types.ts/member";

export function useAppState(): AppStateInterface {

    // reset the app state
    const reset = useResetState()
    const translatePosition = useTranslatePosition();
    const [ state, setAppState ] = useRecoilState(appState)
    const updateContext = useUnleashContext();
    
    const prototeams = useRecoilValue(teamsDataState)
    useEffect(() => {
        
        setAppState(state => ({
            ...state,
            teams: {
                ...state.teams,
                count: prototeams.data.length,
                teamData: prototeams.data,
                unmigratedTeams: prototeams.unmigratedTeams,
            },
            selectedTeamClaim: prototeams.data.find((team: any) => team.id === state.selectedTeam)?.claim
        }))
    }, [ prototeams.lastUpdated, state.selectedTeam ])
    
    const _navigate = useNavigate()
    const location = useLocation()
    const device = useDeviceState()
    const events = useEventsState()
    const config = useConfig()
    const firebaseUser = useRecoilValue(firebaseUserState)

    // determine if the menu if open or not
    const menu = {
        ...state.menu,
        open: () => setAppState(state => ({...state, menu: {isOpen: true}})),
        close: () => setAppState(state => ({...state, menu: {isOpen: false}})),
    }

    // events
    useEffect(() => events.add("user:signout", (user:User) => reset()), [])
    useEffect(() => events.add("close-more-menu", menu.close), [ menu ])

    // close the menus when we navigate
    const navigate = useCallback((to:To, options?:NavigateOptions) => {
        
        events.trigger('close-more-menu')
        _navigate(to, options)
        setAppState(state => ({...state, lastChange: Date.now()}))

    }, [events])

    const goBack = useCallback(() => {
            _navigate(-1);
        }, [_navigate]);

    const navigateOnClick = useCallback((path:string, ...callbacks:Array<() => void>) => {
        return (event:MouseEvent<HTMLElement>) => {
            event.preventDefault()
            navigate(path)
            callbacks.forEach(callback => callback())
        }
    }, [ navigate ])

    // loading controls
    const loading = {
        unload: (key: string) => setAppState(state => ({
            ...state, 
            loaded: state.loaded.filter(k => k !== key)
        })),
        complete: (key: string) => {
            setAppState(state => {
                if (state.loaded.includes(key)) return state;
                return {...state, loaded: [...state.loaded, key]};
            });
        },
        isComplete: (...keys: string[]) => keys.every(key => state.loaded.includes(key)),
    };
    

    // helpers list
    const helpers = {
        translatePosition,
        translateDuty,
        translateShotHand,
    }

    // navigation controls
    const navigation = {
        set( items:NavigationItem[] ){
            setAppState(state => ({...state, navigation: items}))
        },
        add( item:NavigationItem ){
            setAppState(state => ({...state, navigation: [...state.navigation, item]}))
        },
        remove( item:NavigationItem ){
            setAppState(state => ({...state, navigation: state.navigation.filter(i => i !== item)}))
        },
        items(){
            return state.navigation
        },
        leftMobileItems(){
            return state.navigation.filter(item => item.mobile === 'left')
        },
        rightMobileItems(){
            return state.navigation.filter(item => item.mobile === 'right')
        },
        iconMobileItem(){
            return state.navigation.find(item => item.mobile === 'icon')
        },
        desktopItems(){
            return state.navigation.filter(item => item.desktop)
        }
    }

    const teams = {
        ...state.teams,
        setCheckedOnLogin: (checkedOnLogin:boolean) => setAppState(state => ({...state, teams: {...state.teams, checkedOnLogin}})),
        get isUnderThreshold(){
            return state.teams.count == 0 && state.teams.unmigratedTeams.length == 0
        }
    }

    // ui controls
    const ui = (name:AppUiLocations) => ({
        set: (content:JSX.Element, on:boolean=true) => {
            on && setAppState(state => ({...state, ui: {...state.ui, [name]: content}}))
            return () => on && setAppState(state => ({...state, ui: {...state.ui, [name]: undefined}}))
        },
        get: () => state.ui[name],
        clear: () => setAppState(state => ({...state, ui: {...state.ui, [name]: undefined}})), 
    })

    // setters
    const setAcceptedInvite = useCallback((accepted: boolean) => {
        setAppState(state => ({...state, acceptedFirstInvite: accepted, inviteAccepted: accepted}))
    }, [setAppState])

    const setUpdateAvailable = useCallback((available: boolean) => {
        setAppState(state => ({...state, updateAvailable: available}))
    }, [setAppState])

    const setUnleashContext = async (key: string, value: string, user: boolean) => {
        setAppState(state => ({ ...state,unleashContext: { ...state.unleashContext, [key]: value }}));
    };

    const setTempSelectedTeam = (title:string, logo:string) => {
        setAppState(state => ({...state, tempSelectedTeam: {title, logo}}))
    } ;
    

    return {
        
        ...state,
        location,
        isLoggedin: !!firebaseUser,
        hasSelectedTeam: !!state.selectedTeam,
        selectedTeam: state.selectedTeam || "",
        selectedTeamClaim: state.selectedTeamClaim,

        layout: {
            showDesktop: device.isDesktop,
            showTablet: device.isTablet,
            showMobile: device.isMobile,
        },

        chat: {
            unreadMessages: state.unreadMessages,
            setUnreadMessages: (unreadMessages:number) => {
                console.log('unreadMessages', unreadMessages)
                setAppState(state => ({...state, unreadMessages}))
            },
        },

        config,
        activeUser: firebaseUser,
        connections: { firestore },
        loading,
        menu,
        helpers,
        navigation,

        events,
        device,

        registerNestedApp: (name:string, app:ReturnType<typeof useNestedApp>) => setAppState(state => ({...state, nestedApps: {...state.nestedApps, [name]: app}})),
        getApp: (name:string) => state.nestedApps[name],
        setUnleashContext,
        setSelectedTeam: (teamId:string) => {
            const team = state.teams.teamData.find((team: any) => team.id === teamId);
            setAppState(state => ({...state, selectedTeam: teamId, unleashContext: { ...state.unleashContext, 'sportId': team?.sport }}))
            setTempSelectedTeam("", "");
            const pathnameRegex = /^\/games\/\d+\/\d+$/;
            if ((window.location.pathname !== '/invitation') && !(pathnameRegex.test(window.location.pathname)) && !(window.location.pathname.startsWith('/teams/') && window.location.pathname.endsWith('/lineups' )) && !window.location.pathname.startsWith('/invite-user' )  && !window.location.pathname.startsWith('/migrate' ) && !window.location.pathname.startsWith('/invitation' )) {
                navigate("/games")
            }
        },
        setTempSelectedTeam,
        setAcceptedInvite,
        setEventsChanged: (deleted: boolean) => {
            setTimeout(() => {
                setAppState(state => ({...state, eventsChanged: deleted}));
            }, 3000);
        },
        setUpdateAvailable,
        teams,
        navigate,
        navigateOnClick,
        goBack,
        ui,
        
    }

}