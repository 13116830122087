import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime';
import calendar from 'dayjs/plugin/calendar';
import advanced from 'dayjs/plugin/advancedFormat';


// utc
dayjs.extend(utc)

// timezone support
dayjs.extend(timezone)
dayjs.tz.setDefault('UTC')
dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(advanced)

export default dayjs